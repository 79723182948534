<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" :title="projName"/>

    <div v-if="project !== null" class="mainBox mainBox55 border shadow-sm" >
        <div class="row">

            <div class="col-12">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>
                                <div class="row">
                                    <div class="col-12 col-lg-7">Document</div>
                                    <div class="col-12 col-lg-5">Progress</div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="doc in project.documents" :key="doc">
                            <!-- <td v-if="doc.signees.length > 0"> -->
                            <td> <!-- avoid draft not assigned signee yet-->
                                    <div v-if="doc.signees.length === 0" class="row">
                                        <div class="col-12 col-lg-7">
                                            <DocStatus :status="doc.status" class="" />
                                            <span>
                                                <Popper class="popperDark" arrow hover :content="doc.signeeStatus">
                                                    <fa icon="signature" size="lg" class="me-2" :class="doc.signeeStatus === 'SIGNED' ? 'text-primary' : 'text-grey'" />
                                                </Popper>    
                                            </span>
                                            <span :class="doc.status === 'REMOVED' ? 'text-grey' : ''">{{ doc.name.replace('.pdf','') }}</span>
                                        </div>

                                        <div class="col-12 col-lg-5 pt-1">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped" :style="'width: ' + doc.progress + '%'">{{doc.progress}}% </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div v-else class="row isLink" @click="$router.push('/d/' + doc.documentId + '/' + route.params.projectId)"> -->
                                    <div v-else class="row isLink" @click="$router.push({ path: '/d/' + doc.documentId, query: route.query})">
                                        <div class="col-12 col-lg-7">
                                            <DocStatus :status="doc.status" class="" />
                                            <span>
                                                <Popper class="popperDark" arrow hover :content="doc.signeeStatus">
                                                    <fa icon="signature" size="lg" class="me-2" :class="doc.signeeStatus === 'SIGNED' ? 'text-primary' : 'text-grey'" />
                                                </Popper>    
                                            </span>
                                            <span :class="doc.status === 'REMOVED' ? 'text-grey' : ''">{{ doc.name.replace('.pdf', '') }}</span>
                                        </div>

                                        <div class="col-12 col-lg-5 pt-1">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped" :style="'width: ' + doc.progress + '%'">{{doc.progress}}% </div>
                                            </div>
                                        </div>
                                    </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="col-12">{{ project }}</div> -->
        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRoute, useRouter } from 'vue-router'
import Popper from 'vue3-popper'
import DocStatus from '@/components/DocStatus.vue'
import funcs from '@/functions/function'

export default {
    name: 'ViewProjMain',
    components: { TopNavigation, Alert, Popper, DocStatus },
    setup() {
        const axios = inject('axios')
        const alert = ref([])
        
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const project = ref(null)
        const projName = ref(null)
        const totalDoc = ref(0)
        const docId = ref(null)

        const redirectQuery = ref({})

        
        const getProject = async () => {

            try {
                const res = await axios.get('/signon/signee/' + route.params.projectId)
                if (res.data.status === 1) {
                    console.info('getProject', JSON.stringify(res.data.data))
                    project.value = res.data.data
                    projName.value = res.data.data.title
                    totalDoc.value = project.value.documents.length
                    if (totalDoc.value === 1) {
                        docId.value = project.value.documents[0].documentId
                    }
                    

                    // Add project.documents.progress to display in progress bar
                    res.data.data.documents.forEach((item, idx) => {
                        // console.info('d', JSON.stringify(item))
                        if (item.signees.length === 0) {
                            project.value.documents[idx].progress = 0

                        } else {
                            let totalSignee = 0
                            let totalSigned = 0

                            item.signees.forEach((sItem, sIdx) => {

                                if (sItem.privileges && sItem.privileges.includes('signee')) {
                                    totalSignee++
                                }

                                if (sItem.status === 'SIGNED') {
                                    totalSigned++
                                }
                            })

                            setTimeout(() => {
                                if (totalSigned === 0) {
                                    project.value.documents[idx].progress = 0
                                } else {
                                    project.value.documents[idx].progress = ((totalSigned/totalSignee) * 100).toFixed(0) * 1
                                }
                            })
                        }

                    })
                } 
            } catch (error) {
                console.log('getProj | FAILURE', JSON.stringify(error))

                let errMsg = error.message + '. ' + error.code + ': ' + error.status
                if (error.status === 'project_not_found') errMsg = 'Project not found, please contact https://signon.my'

                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
                
            }
        }
        getProject()

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        onMounted(() => {
            // console.info('onMounted', route.params.projectId, totalDoc.value, docId.value)
            func.clearPrevLocalStorage()

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }
            
            if (totalDoc.value === 1 && docId.value !== null) {
                router.push('/d/' + docId.value)
            }

            if (route.query.p === null || route.query.p === undefined ) {
                route.query.redirect = route.name
                route.query.p = route.params.projectId
            } else {
                route.params.projectId = route.query.p
            }
            
        })

        return {
            axios, route, router, alert, closeAlert,
            project, projName, totalDoc, docId, redirectQuery

        }
    }
}
</script>

<style>
</style>