<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Folder Overview" icon="chart-line" />

    <div class="mainBox mainBox58 shadow-sm border">
        <div v-if="project !== null" class="row">
            <div class="col-12 col-md-9 mb-2">

              <!-- Mode -->
              <span v-if="project.mode === 'SIMPLE'" class="me-1">
                <Popper class="popperDark" arrow hover content="SIMPLE">
                  <fa icon="square" size="lg" class="text-grey"/>
                </Popper>
              </span>
              <span v-if="project.mode === 'KYC'" class="me-1">
                <Popper class="popperDark" arrow hover content="KYC">
                  <fa icon="cube" size="lg" class="text-grey"/>
                </Popper>
              </span>
              <span v-if="project.mode === 'STRICT'" class="me-1">
                <Popper class="popperDark" arrow hover content="STRICT">
                  <fa icon="cubes" size="lg" class="text-grey"/>
                </Popper>
              </span>

              <!-- Sign Mode -->
              <span v-if="project.signMode !== null" class="me-1">
                <span v-if="project.signMode.toUpperCase() === 'PROJECT'" class="isTooltipsLink">
                  <Popper class="popperDark" arrow hover content="Full Access">
                    <fa icon="eye" size="lg" class="text-grey"/>
                  </Popper>
                </span>
                <span v-else class="isTooltipsLink">
                  <Popper class="popperDark" arrow hover content="Restrict">
                    <fa icon="eye-slash" size="lg" class="text-grey"/>
                  </Popper>
                </span>
              </span>

              <span class="fw-bold me-1">{{ project.title }}</span> 
              <Popper class="popperDark" arrow hover content="Total Document">
                <span class="badge bg-grey me-1 isTooltipsLabel">{{ project.total }}</span>
              </Popper>

              <ProjStatus :status="project.status" />
              <ProjPrivacy :value="project.privacy" /> 

              <span class="ms-1">
                <div v-if="!showDropdown" class="badge tag" @click.prevent="testStar" v-for="(t, tIdx) in project.tags" :key="t">
                    <fa icon="hashtag" class="pe-1"/>{{ t }}
                  <!-- Small screen -->
                  <span class="d-lg-none">
                    <a href="#" class="ms-1" @click="delTag(t, tIdx)"><fa icon="xmark" /></a>
                  </span>
                  <!-- Big screen -->
                  <span class="tagClose" @click="delTag(t, tIdx)"><fa icon="xmark" /></span>
                </div>
                
                <div class="badge btnTag isLink text-white" @click="showDropdown = !showDropdown">
                    <span v-if="showDropdown">
                        <Popper class="popperDark" arrow hover content="Close add tag">
                          <fa icon="minus" />
                        </Popper>
                    </span>
                    <span v-else>
                        <Popper class="popperDark" arrow hover content="Add tag">
                          <fa icon="plus" />
                        </Popper>
                    </span>
                </div>

                <!-- Tag -->
                <div v-if="showDropdown" class="custom-dropdown">
                    <Tag :tags="project.tags" @getTags="getTags" @delTag="delTag" />
                    <input type="text" v-model="projTag" ref="projTagRef" class="inputTag my-2" autocomplete="off" 
                      @keyup.enter="enterProjTag()"  autofocus />
                      <!-- @keyup.enter="enterProjTag()" @click="setProjTagFocus"  autofocus />
                       <ul class="dropdown-menu" ref="projTagOptRef">
                          <li class="dropdown-item" v-for="(ptag, idx) in project.tags" :key="ptag">
                              <span @click="selectProjTag(ptag)" class="pe-3 text-decoration-none" style="cursor: pointer">{{ptag}}</span>
                          </li>
                      </ul> -->
                </div>
                <!-- Tag End -->

              </span>
            </div>

            <div class="col-12 col-lg-3">
                <span class="float-end">
                  <!-- <Popper class="popperDark" arrow hover content="Modify"> -->
                    <!-- <button class="btn btn-secondary" @click="router.push('/uploadDoc/' + route.params.projectId)" >
                      <fa icon="pencil" class="me-2"/>Modify
                    </button> -->
                    <span class="text-primary isLink" @click="router.push('/uploadDoc/' + route.params.projectId)">
                        <fa icon="pencil" class="me-2"/>Modify
                    </span>
                  <!-- </Popper> -->
              </span>
            </div>

            <div class="col-12 mb-2">
              <table class="table table-hover">
                  <thead>
                      <tr>
                          <th>
                            <div class="row">
                                <div class="col-12 col-lg-6">Document</div>
                                <div class="col-12 col-lg-6">
                                    <div class="row">
                                        <div class="col-12 col-lg-9"></div>
                                        <div class="col-12 col-lg-3">Signee</div>
                                    </div>
                                </div>
                            </div>
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="doc in project.documents" :key="doc">
                          <td>
                              <div class="row">
                                  <!-- <div v-if="doc.status === 'REMOVED'" class="col-12 col-lg-6 isLink text-grey" @click.prevent="router.push('/pdf/view/' + project.projectId + '/'+doc.documentId)">
                                    <DocStatus :status="doc.status" class="" />
                                    <span class="ms-1">{{doc.name}}</span>
                                  </div> -->
                                  <div class="col-12 col-lg-6 isLink" @click.prevent="router.push('/pdf/view/' + project.projectId + '/'+doc.documentId)">
                                    <DocStatus :status="doc.status" class="" />
                                    <span class="ms-1" :class="doc.status === 'REMOVED' ? 'text-grey' : ''">{{ doc.name.replace('.pdf', '') }}</span>
                                    <span v-if="doc.remark || doc.note" class="ms-2" size="lg">
                                        <Popper class="popperDark" arrow hover content="Note">
                                            <fa icon="map-pin" />
                                        </Popper>
                                    </span>
                                  </div>

                                  <div class="col-12 col-lg-6">
                                    <div class="row">
                                        <div class="col-12 col-lg-9 pt-1">
                                            <div class="progress">
                                                <div class="progress-bar progress-bar-striped" :style="{width: doc.progress + '%'}">{{ doc.progress }}% </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-lg-3">
                                          <table width="100%">
                                            <tr><td>
                                                    <span v-if="doc.signees.length > 0">
                                                      <Popper class="popperDark" arrow hover>
                                                          <span class="badge bg-grey isTooltipsLabel"><fa icon="user" /> {{doc.signees.length}}</span>
                                                          <template #content >
                                                              <div v-for="s in doc.signees" :key="s" class="text-start">
                                                                  <span v-if="s.privileges && s.privileges.includes('viewer')">
                                                                      <fa icon="eye" size="lg" />
                                                                      {{ s.name }} <span v-if="s.email !== ''">({{s.email}})</span>
                                                                  </span>
                                                                  <span v-else>
                                                                      <span v-if="s.seq > 0" class="badge bg-grey me-1">{{s.seq}}</span>
                                                                      {{ s.name }} <span v-if="s.email !== ''">({{s.email}})</span> - {{s.status}}
                                                                  </span>
                                                              </div>
                                                          </template>
                                                      </Popper>
                                                    </span>
                                                    <span v-else class="badge bg-grey"><fa icon="user" /> 0</span>
                                                </td>
                                                <td class="text-end">
                                                  <div class="dropdown dropstart">
                                                    <!-- <button type="button" class="btn btn-sm btn-link" data-bs-toggle="dropdown">
                                                        <fa icon="ellipsis-h" class="text-secondary"/>
                                                    </button> -->
                                                    <a href="#" class="isLinkHover" data-bs-toggle="dropdown">
                                                        <fa icon="ellipsis-h" class="text-secondary"/>
                                                    </a>
                                                    <ul class="dropdown-menu dropdown-menu-secondary">
                                                        <li v-if="doc.remark || doc.note">
                                                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlAddNote" @click="docId = doc.documentId; docDetail = doc">Update note <span class="float-end"><fa icon="map-pin" /></span></a>
                                                        </li>
                                                        <li v-else>
                                                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlAddNote" @click="docId = doc.documentId; docDetail = doc">Add note <span class="float-end"><fa icon="map-pin" /></span></a>
                                                        </li>
                                                        <li v-if="doc.status === 'DRAFT'">
                                                            <a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlDelDoc" @click="docId = doc.documentId; docName = doc.name">Delete <span class="float-end"><fa icon="trash" /></span></a>
                                                        </li>
                                                        <li v-if="doc.status === 'ALLSIGNED'">
                                                            <a class="dropdown-item" href="#" @click="storeInVault(doc.documentId)">Store in vault <span class="float-end"><fa icon="database" /></span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                </td></tr>
                                          </table>
                                        </div>
                                    </div>
                                      
                                  </div>
                              </div><!-- row -->
                          </td>
                      </tr>
                  </tbody>
              </table>
            </div>


            <div class="col-12 small text-grey">Modified
              <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(project.dateModify, 'datetime')">
                <span class="isTooltipsLabel">{{ func.getDateTimeDiff(func.convDateTimeFormat(project.dateModify), new Date()) }}</span>
              </Popper>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-12">
                <Legend />
            </div>
        </div>
    </div>

    <!-- <Legend /> -->
    
    <!-- Modal --> 
    <div
      class="modal fade"
      id="mdlAddNote"
      tabindex="-1"
      aria-labelledby="AddNote"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered text-dark">
          <div class="modal-content">
              <div class="modal-header border-0">
                  <h5 class="modal-title" id="exampleModalLabel"> Add Note</h5>
                  <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                  <div class="row">
                      <div class="col-12 mb-3">
                          <label class="form-label text-secondary">Public: Appear to all signees within the same doc</label>
                          <textarea v-model="publicNote" rows="5" class="form-control"></textarea>
                      </div>
                      <div class="col-12">
                          <label class="form-label text-secondary">Private: Appear to myself</label>
                          <textarea v-model="selfNote" rows="2" class="form-control"></textarea>
                      </div>
                  </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    <fa icon="xmark" class="me-2" />Cancel
                </button>
                <button class="btn btn-secondary" data-bs-dismiss="modal" @click="addNote">
                    <fa icon="floppy-disk" size="lg" class="me-2" />Save
                </button>
              </div>
          </div><!-- modal-content -->
      </div><!-- modal-dialog -->
    </div>
    <!-- Modal End--> 

    <!-- Modal --> 
    <div
      class="modal fade"
      id="mdlDelDoc"
      tabindex="-1"
      aria-labelledby="DelDoc"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered text-dark">
          <div class="modal-content">
              <div class="modal-header border-0">
                  <h5 class="modal-title" id="exampleModalLabel"> Delete document</h5>
                  <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                  <div>Confirm to delete document: {{docName}}?</div>
              </div>
              <div class="modal-footer">
                  <button class="btn btn-secondary" data-bs-dismiss="modal" @click="delDoc">Delete</button>
              </div>
          </div><!-- modal-content -->
      </div><!-- modal-dialog -->
    </div>
    <!-- Modal End-->
</template>

<script>
import { ref, inject, onMounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import Tag from '@/components/ProjTag.vue'
import ProjStatus from '@/components/ProjStatus.vue'
import DocStatus from '@/components/DocStatus.vue'
import ProjPrivacy from '@/components/ProjPrivacy.vue'
import Legend from '@/components/Legend.vue'

export default {
  name: 'ProjectOverview',
  components: {
    TopNavigation, Alert, Popper, Tag, ProjStatus, DocStatus, Legend, ProjPrivacy
  },
  setup () {
    const alert = ref([])

    const axios = inject('axios')
    const route = useRoute()
    const router = useRouter()
    const func = funcs

    const project = ref(null)
    const docId = ref(null)   // DocumentId to add note / delete
    const docName = ref(null)   // Document name to delete
    const docDetail = ref(null)   // Document detail to add note

    const showDropdown = ref(false)
    const projTag = ref(null)
    const projTagRef = ref(null)
    const projTagOptRef = ref(null)

    const publicNote = ref(null)
    const selfNote = ref(null)

    /* watch([showDropdown], () => {
        // console.info('watch:: showDropdown', showDropdown.value)
        if (showDropdown.value === true) {
          console.info('true')
            projTagRef.value.focus()
        }
    }) */

    watch([docDetail], () => {
        publicNote.value = docDetail.value.remark
        selfNote.value = docDetail.value.note
    })

    const addNote = () => {
      const  p = {
          remark: publicNote.value,
          note: selfNote.value
      }

      axios.put( '/signon/' + route.params.projectId + '/' + docId.value, p
        ).then((res) => {
            if (res.data.status === 1) {
                
                docDetail.value = {}
                getProject()

                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Note is added."
                })
            } 
        })
        .catch((error) => {

            console.log('addNote | FAILURE', JSON.stringify(error))
            alert.value.push({
                class: "danger",
                title: "ERROR",
                message: func.convError(error)
            })
        })
    }

    const enterProjTag = () => {
        console.info('-- enterProjTag')
        if (projTag.value.length > 0) {
            console.info('enterProjTag2', projTag.value)

            if (project.value.tags.includes(projTag.value)) {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Tag already exists.'
                })
            } else {
                updTag('add', projTag.value)
            }
        }
    }

    const setProjTagFocus = () => {
      // No need drop down menu since not allow duplicate tag?
        if (project.value.tags.length > 0) {
            // projTagRef.value.focus() // Not working
            if (projTagOptRef.value.classList.value.includes('show')) {
                projTagOptRef.value.classList.remove('show')
            } else {
                projTagOptRef.value.classList.add('show')
            }
        }
    }

    const selectProjTag = (val) => {

        if (project.value.tags.includes(val)) {
            alert.value.push({
                class: "danger",
                title: "ERROR",
                message: 'The tag already exists.'
            })
        } else {
            updTag('add', val)
        }
    }

    const delTag = (val, idx) => {
        updTag('del', val, idx)
    }

    const updTag = async (opt, val, idx=0) => {
      const tmpTag = project.value.tags

      if (opt === 'add') {
          tmpTag.push(val)
          
      } else {
         const tmpTag = project.value.tags
         tmpTag.splice(idx, 1)
      }

      const  p = {
          tags: tmpTag
      }

      axios.put( '/signon/' + route.params.projectId, p
        ).then((res) => {
            if (res.data.status === 1) {
                projTag.value = null
                // projTagOptRef.value.classList.remove('show') For tag dropdown menu purpose

                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: opt === 'add' ? "Tag is added." : "Tag is removed."
                })
            } 
        })
        .catch((error) => {

            console.log('updTag | FAILURE', JSON.stringify(error))
            alert.value.push({
                class: "danger",
                title: "ERROR",
                message: func.convError(error)
            })
        })
    }

    const getProject = () => {
      // console.info('PARAM projectId', route.params.projectId)

      axios.get('/signon/' + route.params.projectId)
        .then((res) => {
            if (res.data.status === 1) {
                project.value = res.data.data

                // Add project.documents.progress to display in progress bar
                res.data.data.documents.forEach((item, idx) => {
                    if (item.signees.length === 0) {
                        project.value.documents[idx].progress = 0
                        project.value.documents[idx].totalSignee = 0
                    } else {

                        let totalSignee = 0
                        let totalSigned = 0

                        item.signees.forEach((sItem, sIdx) => {
                          if (sItem.privileges && sItem.privileges.includes('signee')) {
                            totalSignee++
                          }

                          if (sItem.status === 'SIGNED') {
                              totalSigned++
                          }
                        })


                        if (totalSigned === 0) {
                            project.value.documents[idx].progress = 0
                        } else {
                            project.value.documents[idx].progress = ((totalSigned/totalSignee) * 100).toFixed(0) * 1
                        }

                        project.value.documents[idx].totalSignee = totalSignee
                    }
                    

                })

               /*  let objDD = document.querySelector('#ddmTag')
                objDD.addEventListener("show.bs.dropdown", function (event) {
                        console.info('------ Trigger dropdown')
                    }) */

                /* if (project.value !== null) {
                  let obj = document.getElementsByClassName('custom-dropdown2')
                  console.info('obj', obj)
                } */

            }
        })
        .catch((error) => {
            console.log('getProject | ERR', JSON.stringify(error))
            /* alert.value.push({
                class: "danger",
                title: "ERROR",
                message: error[0] + '. ' + error[1] + ': ' + error[2]
            }) */
        })
    }

    const delDoc = () => {
      // console.info('docId', docId.value)
      axios.delete( '/signon/' + route.params.projectId + '/' + docId.value
        ).then((res) => {
            if (res.data.status === 1) {

                getProject()
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Document: " + docName.value + " is deleted."
                })
            } 
        })
        .catch((error) => {

            console.log('delDoc | FAILURE', JSON.stringify(error))
            alert.value.push({
                class: "danger",
                title: "ERROR",
                message: func.convError(error)
            })
        })
    }

    const storeInVault = (id) => {

        axios.put( '/signon/' + route.params.projectId + '/' + id + '/store', {}
        ).then((res) => {
            if (res.data.status === 1) {

                getProject()
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Document is stored."
                })
            } 
        })
        .catch((error) => {

            console.log('storeInVault | FAILURE', JSON.stringify(error))
            alert.value.push({
                class: "danger",
                title: "ERROR",
                message: func.convError(error)
            })
        })
    }

    const closeAlert = (index) => {
        alert.value.splice(index, 1)
    }

    onMounted(() => {
        // console.info('onMounted')
        func.clearPrevLocalStorage()

        // First time login
        if (route.query.fl === true || route.query.fl === 'true') {
            alert.value.push({
                class: "success",
                title: "SUCCESS",
                message: "Welcome to SignOn."
            })
        }

        // Not first time login
        if (route.query.fl === false || route.query.fl === 'false') {
            if (route.query.l) { 
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                })
            }
        }

        getProject()
      
    })

    return { 
      route, router, alert, closeAlert, func, project, docId, docName, 
      showDropdown, projTag, projTagRef, projTagOptRef, 
      enterProjTag, setProjTagFocus, selectProjTag, delTag, delDoc, storeInVault, 
      docDetail, addNote, publicNote, selfNote
    }
  }
  /* mounted() {
    const el = this.$refs["ddmTag"]
    // let obj = document.getElementById('ddmTag')
    el.addEventListener("show.bs.dropdown", function (event) {
        console.info('------ Trigger dropdown')
    })
  } */

}
</script>

<style>
.custom-dropdown {
    text-align: left;
    display: block;
    width: 100%;
    padding: 4px 5px;
    font-size: 1rem;
    font-weight: 400;
    height: auto;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-top: 5px;
    margin-bottom: 5px;
}
.inputTag {
    width: 80px;
    height: 30px;
    font-size: 12px;
    border: 1px dashed #ced4da;
}

.inputTag:focus { 
    outline: none; 
    border: 1px dashed grey;
}
</style>