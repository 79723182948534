<template>
  <div 
    @dragenter.prevent="toggleActive" 
    @dragleave.prevent="toggleActive" 
    @dragover.prevent
    @drop.prevent="toggleActive"
    :class="{'active-dropzone': active}"
    class="dropzone"
  >
      <span>Drag-n-drop file</span>
      <span>OR</span>
      <label for="dropzoneFile" class="btn btn-secondary">Select file</label>
      <input type="file" id="dropzoneFile" class="dropzoneFile" style="display: none" multiple />
      <span class="small text-grey text-center">Multiple files <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> 10MB limit <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> PDF only</span>
  </div>
</template>

<script>
import {ref} from 'vue'
export default {
    name: 'DropZone',
    setup() {
        const active = ref(false)

        const toggleActive = () => {
            active.value = !active.value
        }

        return { active, toggleActive }
    }
}
</script>

<style>
.dropzone {
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 14px;
    border: 2px dashed var(--accent-color);
    border-radius: 10px;
    background-color: var(--bg-body--color);
    transition: .3s ease all;
}

.active-dropzone {
    color: #6E777E;
    border-color: #6E777E;
    background-color: lightgray;
}
</style>