<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Workflow Document" icon="square-share-nodes" />

    <div class="mainBoxBorderless mainBox65">
        <div class="row mb-3">
            <div class="col-lg-7 d-none d-md-block">
                <span>
                    <Popper class="popperDark" arrow hover content="Back to Workflow">
                        <fa icon="angle-left" size="lg" class="me-3 isLink" @click="$router.push({ path: '/workflow' })" />
                    </Popper>
                </span>

                <span class="filter-pill-border">
                    <span class="filter-item-pill" :class="filterStatus === '' ? 'active' : ''" @click="filterStatus = ''">ALL</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'ONGOING' ? 'active' : ''" @click="filterStatus = 'ONGOING'">ONGOING</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'APPROVED' ? 'active' : ''" @click="filterStatus = 'APPROVED'">APPROVED</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'REJECTED' ? 'active' : ''" @click="filterStatus = 'REJECTED'">REJECTED</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'COMPLETED' ? 'active' : ''" @click="filterStatus = 'COMPLETED'">COMPLETED</span>
                </span>
            </div>

            <div class="col-lg-7 mb-4 d-md-none">
                <span>
                    <Popper class="popperDark" arrow hover content="Back to Workflow">
                        <fa icon="angle-left" size="lg" class="me-3 isLink" @click="$router.push({ path: '/workflow' })" />
                    </Popper>
                </span>

                <span class="filter-pill-border">
                    <span class="filter-item-pill" :class="filterStatus === '' ? 'active' : ''" @click="filterStatus = ''">ALL</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'ONGOING' ? 'active' : ''" @click="filterStatus = 'ONGOING'">
                        <Popper class="popperDark" arrow hover content="ONGOING"><fa icon="hourglass-half" /></Popper>
                    </span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'APPROVED' ? 'active' : ''" @click="filterStatus = 'APPROVED'">
                        <Popper class="popperDark" arrow hover content="APPROVED"><fa icon="check" /></Popper>
                    </span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'REJECTED' ? 'active' : ''" @click="filterStatus = 'REJECTED'">
                        <Popper class="popperDark" arrow hover content="REJECTED"><fa icon="xmark" /></Popper>
                    </span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'COMPLETED' ? 'active' : ''" @click="filterStatus = 'COMPLETED'">
                        <Popper class="popperDark" arrow hover content="COMPLETED"><fa icon="hourglass-end" /></Popper>
                    </span>
                </span>
            </div>

            <div class="col-lg-5">
                <table align="right" class="p-0">
                        <tr>
                            <td class="pe-2">
                                <span class="filter-border">
                                    <span class="filter-item pe-2" :class="filterInitiated ? 'active' : ''" @click="filterInitiated = !filterInitiated">INITIATED</span>
                                    <div class="vr"></div>
                                    <span class="filter-item px-2" :class="filterPending ? 'active' : ''" @click="filterPending = !filterPending">PENDING</span>
                                </span>
                            </td>
                            <td>By me</td>
                        </tr>
                    </table>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-5">
                <table>
                    <tr>
                        <td>
                            <span class="filter-border border-0 p-0">
                                <div class="input-group pe-3">
                                    <span class="input-group-text"><fa icon="search" /></span>
                                    <input type="text" v-model="filterName" class="form-control" placeholder="Document Name" style="width: 180px;" >
                                    <span v-if="filterName && filterName.length > 0" class="input-group-text clearSearch" @click="delfilterName">
                                        <fa icon="xmark" />
                                    </span>
                                </div>
                            </span>
                        </td>
                        <td>
                            <span class="filter-pill-border m-0" >
                                <span class="filter-item-pill" @click="resetSearch">
                                    <fa v-if="totalFilter === 0" icon="times" class="me-2" />
                                    <span v-else class="badge bg-primary rounded-pill me-1">{{totalFilter}}</span>
                                    Clear
                                </span>
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="nextBox nextBox65 border shadow-sm" style="margin-top: 30px;">
        <div class="row">
            <div class="col-12">
                <div v-if="isLoading" class="mb-3 text-center">
                    <div class="spinner-border spinner-border-sm me-1"></div> Loading...
                </div>  

                <div v-if="!isLoading" class="mb-3 text-end small fst-italic">{{totalShown}} of {{total}}</div>

                <div v-if="arrItem && arrItem.length > 0" ref="scrollComponent">
                    <table class="table table-hover" border="0">
                        <thead>
                            <tr>
                                <th>
                                    <div class="row">
                                        <!-- <div class="col-lg-4 fw-bold">Doc</div>
                                        <div class="col-lg-2">Creator</div>
                                        <div class="col-lg-3">Workflow</div>
                                        <div class="col-7 col-lg-2">Modified</div>
                                        <div class="col-5 col-lg-1 text-end">Status</div>
                                        <div class="col-12 col-lg-6">
                                            Progress
                                        </div> -->
                                        <div class="col-lg-7">
                                            <div class="row">
                                                <div class="col-lg-6">Doc</div>
                                                <div class="col-lg-6">Workflow</div>
                                                <div class="col-lg-12">Progress</div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3">
                                            <div>Creator</div>
                                            <div>Creator Email</div>
                                        </div>
                                        <div class="col-lg-2 text-end">
                                            <div>Status</div>
                                            <div>Modified</div>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, itemIdx) in arrItem" :key="item">
                                <td>
                                    <router-link :to="'/workflow/version/' + item.documentId">
                                        <div class="row">
                                            <div class="col-lg-7">
                                                <div class="row">
                                                    <div class="col-lg-6 fw-bold">{{item.name.replace('.pdf', '')}}</div>
                                                    <div class="col-lg-6 text-grey small">{{item.workflow.workflowName}}</div>
                                                    <div class="col-8 text-grey small">
                                                        <div class="progress mt-2"> 
                                                            <div class="progress-bar progress-bar-striped" :style="'width: ' + Math.ceil(item.workflow.totalStepCompleted / item.workflow.totalStep * 100) + '%'">{{ Math.ceil(item.workflow.totalStepCompleted / item.workflow.totalStep * 100) }}% </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 text-grey small pt-1">
                                                        {{item.workflow.totalStepCompleted}} / {{item.workflow.totalStep}} steps
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 text-grey small">
                                                <div>{{item.creator.displayName}}</div>
                                                <div>{{item.creator.userId}}</div>
                                            </div>
                                            <div class="col-lg-2 text-end text-grey small fst-italic">
                                                <div>{{item.workflow.workflowStatus}}</div>
                                                <div>
                                                    <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(item.dateModify, 'datetime')">
                                                        <span class="isTooltipsLabel">{{ func.getDateTimeDiff(func.convDateTimeFormat(item.dateModify), new Date()) }}</span>
                                                    </Popper>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> <!-- scrollComponent End -->

                <div v-else>
                    Record not found
                </div>

                <div v-if="isLoadingMore" class="mt-3 text-center">
                    <div class="spinner-border spinner-border-sm me-1"></div> Loading...
                </div>

            </div>
        </div>
    </div>

</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'

export default {
    name: 'WorkFlowVersionList',
    components: { Alert, TopNavigation, Popper },
    setup () {

        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const scrollComponent = ref(null)
        const total = ref(0) // total record
        const totalShown = ref(0) // total records has been displayed
        const rcdFr = ref(0) // record index from 
        const rcdDisplay = ref(15) // total record to display, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const totalFilter = ref(0)
        const filterName = ref('')
        const filterStatus = ref('')
        const filterInitiated = ref(false)
        const filterPending = ref(false)

        const arrItem = ref([])

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        watch([filterName, filterStatus, filterInitiated, filterPending], () => {
            rcdFr.value = 0
            arrItem.value = []

            console.info('WATCH', 'filterName:', filterName.value, 'filterStatus:', filterStatus.value)
            totalFilter.value = 0

            if (filterName.value !== '' && filterName.value.length > 2) { 
                totalFilter.value++
            }
            if (filterStatus.value !== '') {
                totalFilter.value++
            }
            if (filterInitiated.value === true) {
                totalFilter.value++
            }

            if (filterPending.value === true) {
                totalFilter.value++
            }

            getList(false)
        })

        const getList = async (loadMore) => {

            if (loadMore) {
                console.info('load more here')
                isLoadingMore.value = false
            } else {

                console.info('x load more here')
                isLoading.value = false
            }

            total.value = 3
            totalShown.value = 3

            arrItem.value = [
                {
                    documentId: 'doc001',
                    name: "D_202218.pdf",
                    dateCreate: '2022-07-10T15:37:03.000Z',
                    dateModify: '2022-07-12T06:34:04.000Z',
                    status: 'SIGNING',
                    privacy: 'PUBLIC',
                    creator: {
                        displayName: 'Lim Yen Nee',
                        userId: 'yennee.lim@genusis.com',
                        isVerified: false
                    },
                    workflow: {
                        workflowId: '12345',
                        workflowName: 'Deployment Flow',
                        workflowStatus: 'REJECTED',
                        totalStep: 5,
                        totalStepCompleted: 3,
                    }
                },
                {
                    documentId: 'doc002',
                    name: "CR_202250.pdf",
                    dateCreate: '2022-07-10T15:37:03.000Z',
                    dateModify: '2022-07-12T06:34:04.000Z',
                    status: 'SIGNING',
                    privacy: 'PUBLIC',
                    creator: {
                        displayName: 'YH Lee',
                        userId: 'yunghee.lee@genusis.com', 
                        isVerified: false
                    },
                    workflow: {
                        workflowId: '12345',
                        workflowName: 'Change Request Flow',
                        workflowStatus: 'ONGOING',
                        totalStep: 8,
                        totalStepCompleted: 7,
                    }
                },
                {
                    documentId: 'doc003',
                    name: "D_202219.pdf",
                    dateCreate: '2022-07-10T15:37:03.000Z',
                    dateModify: '2022-07-12T06:34:04.000Z',
                    status: 'SIGNING',
                    privacy: 'PUBLIC',
                    creator: {
                        displayName: 'YNLim',
                        userId: 'ynlim82@yahoo.com',
                        isVerified: false
                    },
                    workflow: {
                        workflowId: '12345',
                        workflowName: 'Deployment Flow',
                        workflowStatus: 'ONGOING',
                        totalStep: 3,
                        totalStepCompleted: 0,
                    }
                }
            ]
        }

        const loadMore = () => {

            if (rcdFr.value < total.value) {
                rcdFr.value = rcdFr.value + rcdDisplay.value
                getList(true)

            } else {
                isLoadingMore.value = false
            }
        }

        const getTotal = () => {
            total.value = 0
        }

        const delfilterName = () => {
            arrItem.value = []
            filterName.value = ''
            getList(null)
        }

        const resetSearch = () => {

            totalFilter.value = 0
            
            filterName.value = ''
            filterStatus.value = ''
            filterInitiated.value = false
            filterPending.value = false

            rcdFr.value = 0
            arrItem.value = []
        }

        onMounted(() => {
            window.addEventListener("scroll", handleScroll)
            func.clearPrevLocalStorage()

            totalShown.value = 0
            rcdFr.value = 0

            getTotal()
            getList(false)
        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
        })

        const handleScroll = (e) => {
            
            let element = scrollComponent.value
            if (element !== null) {
                // console.info('handleScroll', element.getBoundingClientRect().bottom.toFixed(0), window.innerHeight)
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    isLoadingMore.value = true
                    loadMore()
                }
            }
        }

        return { 
            alert, closeAlert, router, func, store, 
            scrollComponent, rcdFr, rcdDisplay, isLoading, isLoadingMore, arrItem, 
            totalFilter, filterName, filterStatus, filterInitiated, filterPending, total, totalShown, 
            delfilterName, resetSearch, 
        }
    }
}
</script>

<style>
body {
  overflow: scroll;
}

.filter-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 14px;
    padding: 7px 15px;
    margin-bottom: 10px;
}

.filter-item {
    cursor: pointer;
}

.filter-item:hover {
    color: var(--bs-primary);
}

.filter-item:active, .filter-item.active {
    color: #53BDEB;
    cursor: pointer;
}

.filter-item:disabled, .filter-item.disabled {
    color: var(--toolbar-hr);
    cursor: default;
}

.input-group-text {
    background-color: var(--bs-primary);
    color: var(--bgcolor-body-color);
}

</style>