<template>
    <TopNavigation title="" hideSecondNavbar="true" />

    <div class="supportContainer">
        <div class="row">
            <div class="col-12 text-center secTitle">Get Support</div>
            <div class="col-12">
                <div class="supportBox border shadow-sm">
                    <div class="row">
                        <div class="col-lg-2 col-xl-3"></div>
                        <div class="col-lg-8 col-xl-6">
                            <div class="form-floating mb-3 mt-3">
                                <input type="text" class="form-control" v-model="reqName" placeholder="Name" name="name">
                                <label for="name">Name *</label>
                            </div>
                        </div>
                        <div class="col-lg-2 col-xl-3"></div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 col-xl-3"></div>
                        <div class="col-lg-8 col-xl-6">
                            <div class="form-floating mb-3 mt-3">
                                <input type="text" class="form-control" v-model="reqEmail" placeholder="Email" name="email">
                                <label for="email">Email *</label>
                            </div>
                        </div>
                        <div class="col-lg-2 col-xl-3"></div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 col-xl-3"></div>
                        <div class="col-lg-8 col-xl-6">
                            <div class="form-floating mb-3 mt-3">
                                <textarea class="form-control" v-model="reqContent" placeholder="Request" name="content" style="height: 200px"></textarea>
                                <label for="content">Request *</label>
                            </div>
                        </div>
                        <div class="col-lg-2 col-xl-3"></div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-lg-2 col-xl-3"></div>
                        <div class="col-lg-8 col-xl-6 fst-italic fw-light">
                            * Compulsory fields
                        </div>
                        <div class="col-lg-2 col-xl-3"></div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 col-xl-3"></div>
                        <div class="col-lg-8 col-xl-6 text-center pt-2">
                            <button @click="backToHome" class="btn btn-outline-info btn-lg btn-block mb-5 me-2">Back to Home</button>
                            <button class="btn btn-info text-white btn-lg btn-block mb-5">Submit</button>
                        </div>
                        <div class="col-lg-2 col-xl-3"></div>
                    </div>


                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 footer">
            <Footer />
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from '@/functions/axios-instance'
import TopNavigation from '@/components/TopNavigation.vue'
import Footer from '@/components/Footer.vue'

export default {
    name: 'Support',
    components: { TopNavigation, Footer },
    setup () {
        const reqName = ref(null)
        const reqEmail = ref(null)
        const reqContent = ref(null)

        const backToHome = () => {
            window.location.href = window.location.origin + '/#home'
        }

        return { reqName, reqEmail, reqContent, backToHome }
    }
}
</script>

<style>
.supportContainer {
    width: 75%; 
    margin: 120px auto 0px auto;
}

.supportBox {
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 25px;
    margin-left: 2px;
    margin-right: 2px;
    color: #6E777E;
}
</style>