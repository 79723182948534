<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Dashboard :: SSO" icon="gauge" />

    <div class="mainBoxBorderless mainBox60 mb-3">
        <div class="row">
            <div class="col-lg-4 pb-2">
                <table width="100%">
                    <tr>
                        <td>
                            <Popper class="popperDark" arrow hover content="Back">
                                <fa icon="angle-left" v-if="!showEditMode" size="lg" class="me-3 isLink" @click="$router.push({ path: '/dashboard' })" />
                            </Popper>
                        </td>
                        <td>
                            <div class="input-group">
                                <span class="input-group-text"><fa icon="search" /></span>
                                <input type="text" v-model="filterName" class="form-control" placeholder="App Name" >
                                <span v-if="filterName && filterName.length > 0" class="input-group-text clearSearch" @click="delfilterName">
                                    <fa icon="xmark" />
                                </span>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="col-lg-2 pt-2 pb-3">
                <span class="filter-border">
                    <span class="filter-item pe-2" :class="filterMethod === '' ? 'active' : ''" @click="filterMethod = ''">
                        All
                    </span>
                    <div class="vr"></div>
                    <span class="filter-item px-2" :class="filterMethod === 'qrcode' ? 'active' : ''" @click="filterMethod = 'qrcode'">
                        <Popper class="popperDark" arrow hover content="Login via QR Code"><fa icon="qrcode" /></Popper>
                    </span>
                    <div class="vr"></div>
                    <span class="filter-item ps-2" :class="filterMethod === 'password' ? 'active' : ''" @click="filterMethod = 'password'">
                        <Popper class="popperDark" arrow hover content="Login via password"><fa icon="key" /></Popper>
                    </span>
                </span>
            </div>

            <div class="col-lg-3 pb-2">
                <table border="0">
                    <tr>
                        <td class="pe-2">From</td>
                        <td><Datepicker v-model="dateFr" :enableTimePicker="false" position="left" format="yyyy-MM-dd" autoApply></Datepicker></td>
                    </tr>
                </table>
            </div>
            
            <div class="col-lg-3 pb-2">
                <table border="0">
                    <tr>
                        <td class="pe-2">To</td>
                        <td><Datepicker v-model="dateTo" :enableTimePicker="false" position="left" format="yyyy-MM-dd" autoApply></Datepicker></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="nextBox nextBox60 border shadow-sm mt-0">
        <table class="table table-hover">
            <tbody>
                <tr v-for="item in arrItem" :key="item">
                    <td>
                        <div class="row">
                            <div class="col-lg-2">
                                <span v-if="item.app === 'signon'">
                                    <img alt="Vue logo" src="../../assets/signon_logo7.svg" class="align-top" style="height: 20px" />
                                </span>
                                <span v-if="item.app === 'online delivery'">
                                    <img alt="Vue logo" src="../../assets/logo_online_delivery.png" class="align-top" style="height: 20px" />
                                </span>
                                <span v-if="item.app === 'notis'">
                                    <img alt="Vue logo" src="../../assets/logo_notis.png" class="align-top" style="height: 20px" />
                                </span>
                                <span v-if="item.method === 'qrcode'">
                                    <fa icon="qrcode" class="text-primary" style="z-index: 1000; position: absolute; font-size: 10px; margin-top: 12px; margin-left: -5px"/>
                                </span>
                                <span v-if="item.method === 'password'">
                                    <fa icon="key" class="text-primary" style="z-index: 1000; position: absolute; font-size: 10px; margin-top: 12px; margin-left: -5px"/>
                                </span>
                            </div>
                            <div class="col-lg-2 text-grey small">{{item.displayName}}</div>
                            <div class="col-lg-3 text-grey small">{{item.userId}}</div>
                            <div class="col-lg-3 text-grey small">{{item.location}}, {{item.country}}</div>
                            <div class="col-lg-2 text-end">
                                <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(item.dateCreate, 'datetime')">
                                    <span class="isTooltipsLabel text-grey small">{{ func.getDateTimeDiff(func.convDateTimeFormat(item.dateCreate), new Date()) }}</span>
                                </Popper>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</template>

<script>
import { ref, inject, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import Avatar from '@/components/Avatar.vue'

import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'

export default {
    name: 'SSO',
    components: { Alert, TopNavigation, Popper, Avatar, Datepicker },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const filterMethod = ref('')
        const dateFr = ref(new Date())
        const dateTo = ref(new Date())

        const arrItem = ref([])

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        onMounted(() => {
            arrItem.value = [
                {
                    app: 'signon',
                    method: 'qrcode',
                    dateCreate: '2022-09-20T03:04:44.000Z',
                    displayName: 'Lim Yen Nee',
                    userId: 'yennee.lim@genusis.com',
                    location: 'Kuala Lumpur',
                    country: 'Malaysia'
                },
                {
                    app: 'signon',
                    method: 'password',
                    dateCreate: '2022-09-19T03:04:44.000Z',
                    displayName: 'Greeny Yeoh',
                    userId: 'greeny.yeoh@genusis.com',
                    location: 'Kuala Lumpur',
                    country: 'Malaysia'
                },
                {
                    app: 'online delivery',
                    method: 'qrcode',
                    dateCreate: '2022-09-19T01:04:44.000Z',
                    displayName: 'Lim Yen Nee',
                    userId: 'yennee.lim@genusis.com',
                    location: 'Seattle',
                    country: 'United States'
                },
                {
                    app: 'notis',
                    method: 'password',
                    dateCreate: '2022-09-18T03:04:44.000Z',
                    displayName: 'YH Lee',
                    userId: 'yunghee.lee@genusis.com',
                    location: 'Kuala Lumpur',
                    country: 'Malaysia'
                },
                {
                    app: 'signon',
                    method: 'password',
                    dateCreate: '2022-08-20T03:04:44.000Z',
                    displayName: 'Enerson Yap',
                    userId: 'enerson.yap@genusis.com',
                    location: 'New York',
                    country: 'United States'
                },
            ]
        })

        return {
            alert, closeAlert, router, func, store, 
            filterMethod, dateFr, dateTo, arrItem, 
        }
    }
}
</script>

<style>
.filter-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 14px;
    padding: 7px 15px;
    margin-bottom: 10px;
}

.filter-item {
    cursor: pointer;
}

.filter-item:hover {
    color: var(--bs-primary);
}

.filter-item:active, .filter-item.active {
    color: #53BDEB;
    cursor: pointer;
}

.filter-item:disabled, .filter-item.disabled {
    color: var(--toolbar-hr);
    cursor: default;
}

.input-group-text {
    background-color: var(--bs-primary);
    color: var(--bgcolor-body-color);
}

.filter-pill-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.filter-item-pill {
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
}

.filter-item-pill:hover {
    color: var(--bs-primary);
}

.filter-item-pill:active, .filter-item-pill.active {
    color: var(--bgcolor-body-color);
    background-color: #53BDEB;
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.filter-item-pill:disabled, .filter-item-pill.disabled {
    color: var(--toolbar-hr);
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.clearSearch {
  cursor: pointer; 
  color: #53BDEB;
  background-color: var(--bgcolor-body-color); 
  border-color: var(--accent-color)
}
</style>