<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="4"/>

    <div class="mainBox mainBox55 border shadow-sm">
        <div class="row">
            <div v-if="getProjectId" class="col-12 mb-3 fw-bold" data-bs-toggle="modal" data-bs-target="#mdlEdit">
                <Popper class="popperDark" arrow hover content="Edit folder">
                    <span v-if="getProjectName=== ''" class="isLink">{ Undefined folder name }</span>
                    <span v-else class="isLink">{{ getProjectName }}</span>
                </Popper>
                <Popper class="popperDark" arrow hover content="Total Document">
                    <span class="badge bg-grey mx-1 isTooltipsLabel">
                        {{totalDoc}}
                    </span>
                </Popper>

                <ProjStatus :status="projectStatus" />
                <ProjPrivacy :value="projPrivacy" />
            </div>

            <div v-if="totalDoc > 0" class="col-12">
                <div class="table-responsive pb-5">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Document</th>
                                <th width="80px">Signee</th>
                                <th width="1px"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(doc) in project.documents" :key="doc">
                                <td>
                                    <DocStatus :status="doc.status" />
                                    <span :class="doc.status === 'REMOVED' ? 'text-grey' : ''">{{ doc.name.replace('.pdf', '') }}</span>
                                    <span v-if="doc.remark || doc.note" class="ms-2" size="lg">
                                        <Popper class="popperDark" arrow hover content="Note">
                                            <fa icon="map-pin" />
                                        </Popper>
                                    </span>
                                </td>
                                <td width="40px">
                                    <span v-if="doc.signees.length > 0">
                                        <Popper class="popperDark" arrow hover>
                                            <span class="badge bg-grey isTooltipsLabel"><fa icon="user" /> {{ doc.signees.length }}</span>
                                            <template #content >
                                                <div v-for="s in doc.signees" :key="s" class="text-start">
                                                    <span v-if="s.privileges && s.privileges.includes('viewer')">
                                                        <fa icon="eye" size="lg" />
                                                        {{ s.name }} <span v-if="s.email !== ''">({{s.email}})</span>
                                                    </span>
                                                    <span v-else>
                                                        <span v-if="s.seq > 0" class="badge bg-grey me-1">{{s.seq}}</span>
                                                        {{ s.name }} <span v-if="s.email !== ''">({{s.email}})</span> - {{s.status}}
                                                    </span>
                                                </div>
                                            </template>
                                        </Popper>
                                    </span>
                                    <span v-else class="badge bg-grey isLabel"><fa icon="user" /> 0</span>
                                </td>
                                <td class="text-end" width="50px">
                                    <div class="dropdown dropstart" >
                                        <a href="#" class="isLinkHover" data-bs-toggle="dropdown">
                                            <fa icon="ellipsis-h" class="text-secondary" />
                                        </a>
                                        
                                        <ul class="dropdown-menu dropdown-menu-secondary">
                                            <li v-if="doc.remark || doc.note"><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlAddNote" @click="docDetail = doc">Update note</a></li>
                                            <li v-else><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlAddNote" @click="docDetail = doc">Add note</a></li>
                                            <li v-if="doc.status === 'DRAFT'"><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#mdlDelDoc" @click="docDetail = doc">Delete</a></li>
                                        </ul>
                                    </div>
                                </td>
                                <!-- <td></td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <div class="nextBox nextBox55 border shadow-sm">
        <div class="row">
            <div class="col-12 mb-3">
                <label class="fw-bold">Email to Signee</label>
            </div>

            <div class="col-12 mb-3">
                <input type="text" class="form-control" v-model="subject"  />
            </div>

            <div class="col-12 mb-4 ">
                <!-- <div class="border p-3 form-control bg-light"> -->
                <div class="border p-3 form-control">
                    <div class="mb-2" v-html="msgHeaderHtml"></div>
                    <textarea rows="4" v-model="msg"></textarea>
                    <div class="mb-2" v-html="msgFooterHtml"></div>
                </div>
            </div>

            <div v-if="projectStatus !== 'DONE'" class="col-12 text-center">
                <button v-if="getUserStatus === 'ACTIVE'" class="btn btn-secondary" @click="sendInvitation">
                    <fa icon="envelope" size="lg" class="me-2" />Invite to Sign
                </button>
                <button v-else class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlInvite" >
                    <fa icon="envelope" size="lg" class="me-2" />Invite to Sign
                </button>
            </div>
        </div>
    </div>

    <!-- Modal --> 
    <div class="modal fade" id="mdlAddNote" tabindex="-1" aria-labelledby="AddNote" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Add Note</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <label class="form-label text-secondary">Public: Appear to all signees within the same doc</label>
                            <textarea v-model="publicNote" rows="5" class="form-control"></textarea>
                        </div>
                        <div class="col-12">
                            <label class="form-label text-secondary">Private: Appear to myself</label>
                            <textarea v-model="selfNote" rows="2" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button class="btn btn-secondary" data-bs-dismiss="modal" @click="addNote">
                        <fa icon="floppy-disk" size="lg" class="me-2" />Save
                    </button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div><!-- Modal End-->

    <!-- Modal --> 
    <div class="modal fade" id="mdlDelDoc" tabindex="-1" aria-labelledby="DelDoc" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Delete document</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="docDetail">Confirm to delete document: {{docDetail.name}}?</div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button class="btn btn-secondary" data-bs-dismiss="modal" @click="delDoc">
                        <fa icon="trash" size="lg" class="me-2" />Delete
                    </button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- Modal End-->

    <!-- Modal --> 
    <div class="modal fade" id="mdlEdit" tabindex="-1" aria-labelledby="EditProj" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Edit folder</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-2">Folder Name</div>
                        <div class="col-8"><input type="text" v-model="newProjName" class="form-control"  @focus="true" /></div>
                        <div class="col-4 text-end mb-3">
                            <button class="btn btn-secondary" @click="updProjName" data-bs-dismiss="modal">
                                <fa icon="rotate" size="lg" class="me-2" />Update
                            </button>
                        </div>

                        <div class="col-12"><hr style="color: var(--accent-color)"/></div>

                        <div class="col-12 mt-2 mb-2">Folder Privacy</div>
                        <div class="col-9 mb-2">
                            <table>
                                <tr>
                                    <td class="pe-1"><input type="radio" v-model="projPrivacy" value="PUBLIC" /></td>
                                    <td><ProjPrivacy value="PUBLIC" /> View all information</td>
                                </tr>
                                <tr>
                                    <td><input type="radio" v-model="projPrivacy" value="PRIVATE" /></td>
                                    <td><ProjPrivacy value="PRIVATE" /> View basic information only</td>
                                </tr>
                                <tr>
                                    <td><input type="radio" v-model="projPrivacy" value="CONFIDENTIAL" /></td>
                                    <td class="pe-1"><ProjPrivacy value="CONFIDENTIAL" /> View limited information only</td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-3 position-relative px-1">
                            <div class="position-absolute bottom-0 end-0">
                                <button class="btn btn-secondary" style="margin-right: 10px; margin-bottom: 10px;" @click="updProjPrivacy" data-bs-dismiss="modal">
                                    <fa icon="rotate" size="lg" class="me-2" />Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End-->

    <!-- Modal --> 
    <div class="modal fade" id="mdlInvite" tabindex="-1" aria-labelledby="Invite" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Email Verification Required</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-4">In order to use this feature, you must first verify your email address. An email has been sent to {{getUserId}}. Please look for this email and click the activation link to continue.</div>
                    <div><hr /></div>
                    <div class="mb-2">If you entered your email address incorrectly, please update your primary email address below</div>
                    <div class="mb-2"><input type="email" v-model="email" :placeholder="getUserId" class="form-control" /></div>
                    <div class="d-grid">
                        <button class="btn btn-secondary btn-block" :disabled="email.length === 0" @click="resendActivation" data-bs-dismiss="modal">
                            <fa icon="envelope" size="lg" class="me-2" />Resend Activation Email
                        </button>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div>
    <!-- Modal End-->
    
</template>

<script>
import { ref, inject, computed, onMounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useStore } from '@/stores/store'
import Popper from 'vue3-popper'
import Alert from '@/components/Alert.vue'
import ProjStatus from '@/components/ProjStatus.vue'
import DocStatus from '@/components/DocStatus.vue'
import ProjPrivacy from '@/components/ProjPrivacy.vue'
import funcs from '@/functions/function'
import { useRoute } from 'vue-router'

export default {
    name: 'ReviewInvite',
    components: { TopNavigation, Popper, Alert, ProjStatus, DocStatus, ProjPrivacy },
    setup() {

        const axios = inject('axios')
        const alert = ref([])
        const store = useStore()
        const { getProjectId, getProjectName, getUserStatus, getUserId } = useStore()
        const func = funcs
        const route = useRoute()

        const sessionId = ref(null)
        const project = ref(null)
        const totalDoc = ref(0)
        const subject = ref(null)
        const email = ref('')
        const msg = ref(null)
        const msgHeader = ref(null)
        const msgFooter = ref(null)
        const msgHeaderHtml = ref(null)
        const msgFooterHtml = ref(null)
        const publicNote = ref(null)
        const selfNote = ref(null)
        const newProjName = ref(null)
        const projectStatus = ref(null)
        const isEditMode = ref(false) // Edit project name. (true/false) editable mode/readonly mode
        const docDetail = ref(null)
        
        const projPrivacy = ref('PUBLIC')

        sessionId.value = store.getSessionId.value
        msg.value = 'Editable text'

        watch([docDetail], () => {
            publicNote.value = docDetail.value.remark
            selfNote.value = docDetail.value.note
        })

        const addNote = () => {
            const p = {
                remark: publicNote.value,
                note: selfNote.value
            }

            axios.put( '/signon/' + getProjectId.value + '/' + docDetail.value.documentId, p)
            .then((res) => {

                if (res.data.status === 1) {
                    docDetail.value = {}
                    getProject()

                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Note is updated."
                    })
                } 
            })
            .catch((error) => {

                console.log('addNote | FAILURE', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            })
        }

        const getProject = () => {
            return axios.get( '/signon/' + getProjectId.value)
            .then((res) => {
                console.info('getProject | RES', JSON.stringify(res.data.data))
                if (res.data.status === 1) {
                    project.value = res.data.data
                    totalDoc.value = res.data.data.documents.length
                    newProjName.value = res.data.data.title
                    projectStatus.value = res.data.data.status
                    projPrivacy.value = res.data.data.privacy
                } 
            })
            .catch((error) => {
                console.log('getProject | FAILURE', JSON.stringify(error))
            })
        }

        const getEmailTemplate = () => {
            return axios.get( '/reference/emailTemplate/sInvite/' + getProjectId.value)
            .then((res) => {
                console.info('getEmailTemplate | RES', JSON.stringify(res.data.data))
                if (res.data.status === 1) {
                    subject.value = res.data.data.subject
                    msgHeader.value = res.data.data.header
                    msgFooter.value = res.data.data.footer
                    msgHeaderHtml.value = res.data.data.headerHtml
                    msgFooterHtml.value = res.data.data.footerHtml
                } 
            })
            .catch((error) => {
                console.log('getEmailTemplate | FAILURE', JSON.stringify(error))
            })
        }

        const sendInvitation = () => {

            const p = {
                subject: subject.value,
                body: msgHeader.value + '\n' + msg.value + '\n' + msgFooter.value,
                bodyHtml: msgHeaderHtml.value + '<br>' + msg.value.replace(/\n/g, "<br>") + '<br>' + msgFooterHtml.value
            }

            axios.put( '/signon/' + getProjectId.value + '/send', p)
            .then((res) => {
                // console.info('sendInvitation | RES', JSON.stringify(res.data.data))
                if (res.data.status === 1) {
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Invitation is sent."
                    })
                } 
            })
            .catch((error) => {

                // console.log('sendInvitation | FAILURE', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            })
        }

        const updProjName = () => {

            isEditMode.value = false

            if (project.value.title !== newProjName.value) {

                const p = {
                    title: newProjName.value
                }

                axios.put( '/signon/' + getProjectId.value, p)
                .then((res) => {
                    if (res.data.status === 1) {

                        getProject()

                        newProjName.value = computed({
                            get: () => store.getProjectName.value,
                            set: store.updProjectName(newProjName.value),
                        })
                        newProjName.value = store.getProjectName.value 

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Folder name is updated."
                        })
                    } 
                })
                .catch((error) => {

                    // console.log('updProjName | FAILURE', JSON.stringify(error))
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.convError(error)
                    })
                })

                console.info('p', JSON.stringify(p))
            }
        }

        const updProjPrivacy = () => {
            if (project.value.privacy !== projPrivacy.value) {

                const p = {
                    privacy: projPrivacy.value
                }

                axios.put( '/signon/' + getProjectId.value, p)
                .then((res) => {
                    if (res.data.status === 1) {

                        getProject()

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Folder privacy is updated."
                        })
                    } 
                })
                .catch((error) => {

                    // console.log('updProjPrivacy | FAILURE', JSON.stringify(error))
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.convError(error)
                    })
                })

                console.info('p', JSON.stringify(p))
            }
        }

        const delDoc = () => {
            console.info('del docId', docDetail.value.documentId, getProjectId.value)
            axios.delete( '/signon/' + getProjectId.value + '/' + docDetail.value.documentId
                ).then((res) => {
                    if (res.data.status === 1) {

                        getProject()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document: " + docDetail.value.name + " is deleted."
                        })
                    } 
                })
                .catch((error) => {

                    console.log('delDoc | FAILURE', JSON.stringify(error))
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.convError(error)
                    })
                })
        }

        const resendActivation = () => {
            const p = {
                username: email.value,
                frontendUrl: 'https://signon.my/dashboard'
            }

            console.info('resendActivation', JSON.stringify(p))

            axios.post('/auth/signup/resend', p)
                .then((res) => {
                    if (res.data.status === 1) {
                        alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "An activation email has sent to you, please click the link to activate it."
                            })
                    }
                })
                .catch((error) => {
                    console.log('resendActivation | ERR', JSON.stringify(error))
                })
        }

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        onMounted(() => {
            func.clearPrevLocalStorage()

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }

            getProject()
            getEmailTemplate()
        })

        return { 
            store, alert, closeAlert, sessionId, project, totalDoc, subject, publicNote, selfNote,
            msg, msgHeader, msgFooter, msgHeaderHtml, msgFooterHtml,
            getProjectId, getProjectName, getUserStatus, getUserId, getEmailTemplate, sendInvitation,
            newProjName, updProjName, projectStatus, isEditMode, updProjPrivacy,
            docDetail, addNote, delDoc, projPrivacy, func, email, resendActivation, 
         }
    }
}
</script>

<style>
body {
    color: #6E777E;
    font-size: 14px;
    overflow: scroll;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--form-control-text);
  background-color: var(--form-control-grey);
  background-clip: padding-box;
  border: 1px solid var(--accent-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: var(--form-control-text);
  background-color: var(--bgcolor-body-color);
  border-color: var(--bgcolor-body-color);
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--accent-color);
}

textarea {
  padding: 5px 10px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 4px;
  color: var(--form-control-text);
  background-color: var(--form-control-grey);
  font-size: 16px;
  resize: none;
}

textarea:focus {
    border-color: solid var(--accent-color);
    box-shadow: 0 0 0 0.25rem var(--accent-color);
    outline: 0;
}

</style>