<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)" />
    <!-- <TopNavigationView :title="docName" :backUrl="'/p/' + route.params.projectId" :docStatus="docStatus" :isDocSigned="isDocSigned" /> -->
    <TopNavigationView title="" :backUrl="'/p/' + route.params.projectId" hideSecondNavbar="true" />

    <!-- <div class="contentDiv">  -->
    <div class="contentDiv"> 
        <div class="row">

            <span v-if="!showToolbox" class="toolboxBtnClose" @click="toggleToolbox">
                <fa icon="chevron-right" class="text-white" />
            </span>

            <span v-if="showToolbox" class="toolboxBtnOpen" @click="toggleToolbox">
                <fa icon="chevron-left" class="text-white" style="padding-left: 2px;" />
            </span>

            <div v-if="showToolbox" class="bottom-0 p-0 vh-100 pe-1 toolbarFloat" style="z-index: 2">

                <div id="accordion" class="pt-1 ps-1">

                    <div class="card mb-2">

                        <div class="card-header">
                            <!-- <a class="collapsed btn" data-bs-toggle="collapse" href="#left1">Navigator</a> -->
                            <table align="center">
                                <tr>
                                    <td class="pe-1">
                                        <button class="btn btn-secondary" @click="router.push('/p/' + route.params.projectId)">
                                            <Popper class="popperDark" arrow hover content="Project"><fa icon="folder" /></Popper>
                                        </button>
                                    </td>
                                    <td class="float-end">
                                        <button v-if="totalDoc === 1" class="btn btn-secondary" @click="router.push('/d/' + route.params.documentId)">
                                            <Popper class="popperDark" arrow hover content="Document">
                                                <fa icon="file-pdf" class="me-2"/><span v-if="totalDoc" class="badge rounded-pill bg-grey">{{totalDoc}}</span>
                                            </Popper>
                                        </button>
                                        <button v-if="totalDoc > 1" class="btn btn-secondary" @click="router.push('/p/' + route.params.projectId)">
                                            <Popper class="popperDark" arrow hover content="Document">
                                                <fa icon="file-pdf" class="me-2" /><span v-if="totalDoc" class="badge rounded-pill bg-grey">{{totalDoc}}</span>
                                            </Popper>
                                        </button>
                                    </td>
                                </tr>
                            </table>

                            <!-- <span v-if="isToolbarFloat" @click="toggleToolbarFloat" class="float-end pt-2 pe-2 isLink">
                                <Popper class="popperDark" arrow hover content="Floating toolbar">
                                    <fa icon="life-ring" color="#53BDEB" />
                                </Popper>
                            </span>
                            <span v-else @click="toggleToolbarFloat" class="float-end pt-2 pe-2 isLink">
                                <Popper class="popperDark" arrow hover content="Fixed toolbar">
                                    <fa icon="life-ring"/>
                                </Popper>
                            </span> -->
                        </div>

                        <div id="left1" class="collapse show" >
                            <div class="card-body">
                                
                                <div class="pb-2 border-bottom">
                                    <table align="center" border="0">
                                        <tr> 
                                        <td  width="50px" class="px-2 text-end isLink" @click="zoomOut"><fa icon="minus"></fa></td>
                                        <td v-if="scale === 1"  width="70px" class="pe-2 text-center" @click="zoomDefault">{{Math.floor(scale*100)}}%</td>
                                        <td v-else  width="70px" class="pe-2 isLink text-center" @click="zoomDefault">{{Math.floor(scale*100)}}%</td>
                                        <td  width="50px" class="ps-2 isLink pe-1" @click="zoomIn"><fa icon="plus"></fa></td>
                                        </tr>
                                    </table>
                                </div>
                                <!-- Big screen -->
                                <div class="py-2 d-none d-md-block">
                                    <table border="0" align="center">
                                        <tr>
                                        <td width="20px">&nbsp; </td>
                                        <td v-if="page === 1" class="pe-2 text-secondary"><fa icon="angle-double-left" :disabled="page === 1"></fa></td>
                                        <td v-if="page !== 1" class="pe-2 isLink" @click="navigatePage('first'); updPageNum(page)" ><fa icon="angle-double-left"></fa></td>
                                        <td v-if="page <= 1" class="px-2 text-secondary"><fa icon="angle-left"></fa></td>
                                        <td v-if="page > 1" class="px-2 isLink" @click="navigatePage('prev'); updPageNum(page)"><fa icon="angle-left"></fa></td>
                                        <td><input type="text" v-model="goToPage" class="form-control form-control-sm" @input="navigatePage(); updPageNum(page)" style="width: 40px" ></td>
                                        <td class="px-2">/ {{ pageCount }}</td>
                                        <td v-if="page >= pageCount" class="px-2 text-secondary"><fa icon="angle-right"></fa></td>
                                        <td v-if="page < pageCount" class="px-2 isLink" @click="navigatePage('next'); updPageNum(page)"><fa icon="angle-right"></fa></td>
                                        <td v-if="page === pageCount" class="ps-2 text-secondary"><fa icon="angle-double-right" ></fa></td>
                                        <td v-if="page !== pageCount" class="ps-2 isLink" @click="navigatePage('last'); updPageNum(page)"><fa icon="angle-double-right"></fa></td>
                                        </tr>
                                    </table>
                                </div>
                                <!-- Big screen End-->
                                <!-- Small screen -->
                                <div class="d-block d-md-none">
                                    <div class="row mb-2 mt-2 pb-2 border-bottom" style="margin-left: 1px; margin-right: 1px;">
                                        <div v-if="page === 1" class="col-3 text-secondary">
                                            <fa icon="angle-double-left" :disabled="page === 1"/>
                                        </div>
                                        <div v-if="page !== 1" class="col-3 isLink" @click="navigatePage('first'); updPageNum(page)">
                                            <fa icon="angle-double-left" />
                                        </div>

                                        <div v-if="page <= 1" class="col-3 text-secondary">
                                            <fa icon="angle-left" />
                                        </div>
                                        <div v-if="page > 1" class="col-3 isLink" @click="navigatePage('prev'); updPageNum(page)">
                                            <fa icon="angle-left"/>
                                        </div>

                                        <div v-if="page >= pageCount" class="col-3 text-secondary">
                                            <fa icon="angle-right" />
                                        </div>
                                        <div v-if="page < pageCount" class="col-3 isLink" @click="navigatePage('next'); updPageNum(page)">
                                            <fa icon="angle-right" />
                                        </div>

                                        <div v-if="page === pageCount" class="col-3 text-secondary">
                                            <fa icon="angle-double-right" />
                                        </div>
                                        <div v-if="page !== pageCount" class="col-3 isLink" @click="navigatePage('last'); updPageNum(page)">
                                            <fa icon="angle-double-right" />
                                        </div>
                                    </div>

                                    <div class="row mb-2">
                                        <div class="col-6 text-end"><input type="text" v-model="goToPage" class="form-control form-control-sm float-end" @input="navigatePage(); updPageNum(page)" style="width: 40px" ></div>
                                        <div class="col-6 pt-2 ps-0">/ {{ pageCount }}</div>
                                    </div>
                                </div>
                                <!-- Small screen End -->
                            </div>
                        </div>
                    </div>

                    <div class="card mb-2">
                        <div class="card-header">
                            <DocStatus :status="docStatus" class="me-2" />
                            <span v-if="isDocSigned" >
                                <Popper class="popperDark" arrow hover content="Signed">
                                    <fa icon="signature" size="lg" class="text-primary" />
                                </Popper>    
                            </span>
                            <span v-if="!isDocSigned">
                                <Popper class="popperDark" arrow hover content="Pending for sign">
                                    <fa icon="signature" size="lg" class="text-grey" />
                                </Popper>
                            </span>

                            <a class="collapsed btn text-start" data-bs-toggle="collapse" href="#left3">
                                <span v-if="docName">{{docName}}</span>
                                <span v-else>Untitled document</span>
                            </a>
                            

                        </div>
                        <div id="left3" class="collapse show" >
                            <div class="card-body pb-0">

                                <div class="row">

                                    <div v-if="doc.dateModify" class="col-12 pb-2 text-end">
                                        <span class="lblTiny">Modified </span>
                                        <Popper class="popperDark" arrow hover :content="'Created ' + func.getDateTimeDiff(func.convDateTimeFormat(doc.dateCreate), new Date())">
                                            <span class="isTooltipsLabel lblTiny">{{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateModify), new Date()) }}</span>
                                        </Popper>
                                    </div>

                                    <div v-if="doc.signees" class="col-12 pt-0 mb-0 lblTiny">Signee(s)</div>
                                    <div v-if="doc.signees" class="col-12 px-1 pb-2 small">
                                        <div v-for="(s, sIdx) in docSignee" :key="s">
                                            <table v-if="sIdx < 5" border="0" width="100%">
                                            <tr>
                                                <td rowspan="2" width="38px">
                                                    <Avatar :isUser="s.isUser" :avatar="s.url" :isVerified="s.isVerified" displayName="" width="35" height="35" class="ps-2"/>
                                                </td>
                                                <td style="font-size: 14px;">{{s.name}}</td>
                                            </tr>
                                            <tr>
                                                <td v-if="sIdx === 0" class="text-end align-bottom lblTiny">Creator</td>
                                                <td v-else class="text-end align-bottom lblTiny">{{s.role}}</td>
                                            </tr>
                                            </table>
                                        </div>

                                        <div v-if="showMoreSignee" v-for="(s1, sIdx1) in docSignee" :key="s1">
                                            <table v-if="sIdx1 >= 5" border="0" width="100%">
                                            <tr>
                                                <td rowspan="2" width="38px">
                                                    <Avatar :isUser="s1.isUser" :avatar="s1.url" :isVerified="s1.isVerified" displayName="" width="35" height="35" class="ps-2"/>
                                                </td>
                                                <td style="font-size: 14px;">{{s1.name}}</td>
                                            </tr>
                                            <tr>
                                                <td class="text-end align-bottom">{{s1.role}}</td>
                                            </tr>
                                            </table>
                                        </div>

                                        <div v-if="docSignee.length > 5 && !showMoreSignee" class="text-end isLink" @click="showMoreSignee = !showMoreSignee">+ {{docSignee.length - 5}} more</div>
                                        <div v-if="docSignee.length > 5 && showMoreSignee" class="text-end isLink" @click="showMoreSignee = !showMoreSignee">- {{docSignee.length - 5}} less</div>
                                    </div>

                                    <div v-if="doc.remark" class="col-12 mb-0 lblTiny">
                                        <Popper class="popperDark" arrow hover content="Appear to all signees within the same doc" placement="top">
                                            Public Remark
                                        </Popper>
                                    </div>
                                    <div v-if="doc.remark" class="col-12 pb-2">{{doc.remark}}</div>

                                    <div v-if="doc.note" class="col-12 mb-0 lblTiny">
                                        <Popper class="popperDark" arrow hover content="Appear to myself" placement="top">
                                            Private Note
                                        </Popper>
                                    </div>
                                    <div v-if="doc.note" class="col-12 pb-2 ">{{doc.note}}</div>
                                </div>

                                <!-- Method-1 -->
                                <!-- <table class="table table-sm table-borderless">
                                    <tr v-if="docName">
                                        <td class="pb-1">Name</td>
                                        <td class="ps-1">{{docName}}</td>
                                    </tr>
                                    <tr>
                                        <td class="pb-1">Status</td>
                                        <td>
                                            <span v-if="docStatus !== ''">
                                                <DocStatus :status="docStatus" class="me-2" />
                                            </span>

                                            <span v-if="isDocSigned" >
                                                <Popper class="popperDark" arrow hover content="Signed">
                                                    <fa icon="signature" size="lg" class="me-2 text-primary" />
                                                </Popper>    
                                            </span>
                                            <span v-if="!isDocSigned">
                                                <Popper class="popperDark" arrow hover content="Pending for sign">
                                                    <fa icon="signature" size="lg" class="me-2 text-grey" />
                                                </Popper>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="doc.creator">
                                        <td class="pb-1">Creator</td>
                                        <td><img v-if="doc.creator.avatar" :src="doc.creator.avatar" class="rounded-circle" width="35" /><span class="ps-1">{{doc.creator.displayName}}</span></td>
                                    </tr>
                                    <tr v-if="doc.dateCreate">
                                        <td class="pb-1">Created</td>
                                        <td>
                                            <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(doc.dateCreate, 'datetime')">
                                                <span class="isTooltipsLabel">{{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateCreate), new Date()) }}</span>
                                            </Popper>
                                        </td>
                                    </tr>
                                    <tr v-if="doc.dateModify">
                                        <td class="pb-1">Modified</td>
                                        <td>
                                            <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(doc.dateModify, 'datetime')">
                                                <span class="isTooltipsLabel">{{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateModify), new Date()) }}</span>
                                            </Popper>
                                        </td>
                                    </tr>
                                    <tr v-if="doc.signees">
                                        <td class="pb-2">Signee</td>
                                        <td class="ps-1">{{doc.signees.length}}</td>
                                    </tr>
                                    <tr v-if="doc.remark">
                                        <td class="pb-2 align-top">Remark</td>
                                        <td class="ps-1">{{doc.remark}}</td>
                                    </tr>
                                    <tr v-if="doc.note">
                                        <td class="pb-0 align-top">Note</td>
                                        <td class="ps-1">{{doc.note}}</td>
                                    </tr>
                                </table> -->
                                <!-- <hr /> -->

                                <!-- Method-2 -->

                                <!-- <div v-if="doc.creator" class="mb-1">
                                    <span class="text-grey" style="font-size: 12px">By</span> <span>{{doc.creator.displayName}}</span>
                                </div>
                                
                                <div v-if="doc.signees" class="mb-1">
                                    {{doc.signees.length}} <span class="text-grey" style="font-size: 12px">signees/viewers</span>
                                </div>

                                <div v-if="doc.dateCreate" class="mb-1">
                                    <span class="text-grey" style="font-size: 12px">Created </span>
                                    <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(doc.dateCreate, 'datetime')">
                                        <span class="isTooltipsLabel">{{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateCreate), new Date()) }}</span>
                                    </Popper>
                                </div>

                                <div v-if="doc.dateModify" class="mb-1" >
                                    <span class="text-grey" style="font-size: 12px">Last updated </span>
                                    <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(doc.dateModify, 'datetime')">
                                        <span class="isTooltipsLabel">{{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateModify), new Date()) }}</span>
                                    </Popper>
                                </div>

                                <div class="mb-1">{{doc.remark}}</div>
                                <div class="mb-1"><i>{{doc.note}}</i></div> -->

                                
                                <!-- <hr />  -->

                                <!-- Method-3 -->
                                <!-- <div>
                                    <table>
                                        <tr v-if="doc.creator">
                                            <td class="pb-2" width="25px">
                                                <Popper class="popperDark" arrow hover content="Creator">
                                                    <fa icon="user-pen" size="lg" />
                                                </Popper>
                                            </td>
                                            <td>{{doc.creator.displayName}}</td>
                                        </tr>
                                        <tr v-if="doc.signees">
                                            <td class="pb-2">
                                                <Popper class="popperDark" arrow hover content="Sinee/Viewer">
                                                    <fa icon="user" size="lg" />
                                                </Popper>
                                            </td>
                                            <td>{{doc.signees.length}}</td>
                                        </tr>
                                        <tr v-if="doc.dateCreate">
                                            <td class="pb-2">
                                                <Popper class="popperDark" arrow hover content="Created">
                                                    <fa icon="calendar" size="lg" />
                                                </Popper>
                                            </td>
                                            <td class="pb-2">
                                                <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(doc.dateCreate, 'datetime')">
                                                    <span class="isTooltipsLabel">{{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateCreate), new Date()) }}</span>
                                                </Popper>
                                            </td>
                                        </tr>
                                        <tr v-if="doc.dateModify">
                                            <td class="pb-2">
                                                <Popper class="popperDark" arrow hover content="Updated">
                                                    <fa icon="calendar-plus" size="lg" />
                                                </Popper>
                                            </td>
                                            <td>
                                                <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(doc.dateModify, 'datetime')">
                                                    <span class="isTooltipsLabel">{{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateModify), new Date()) }}</span>
                                                </Popper>
                                            </td>
                                        </tr>
                                        <tr v-if="doc.remark">
                                            <td class="pb-2">
                                                <Popper class="popperDark" arrow hover content="Remark">
                                                    <fa icon="note-sticky" size="lg" />
                                                </Popper>
                                            </td>
                                            <td>{{doc.remark}}</td>
                                        </tr>
                                        <tr v-if="doc.note">
                                            <td class="align-top">
                                                <Popper class="popperDark" arrow hover content="Note">
                                                    <fa icon="map-pin" size="lg" />
                                                </Popper>
                                            </td>
                                            <td class="pb-2">{{doc.note}}</td>
                                        </tr>
                                    </table>

                                </div> -->

                            </div>
                        </div>
                    </div>

                    <div class="card" v-if="objList && objList.length > 0">
                        <div class="card-header">
                            <a class="collapsed btn" data-bs-toggle="collapse" href="#left2">Signee Reference</a>
                        </div>
                        <div id="left2" class="collapse show" >
                            <div class="card-body">
                                <div v-for="o in objList" :key="o" class="color0 mb-2">
                                    <span v-if="o.type === 'fullSignature'" class="me-1">
                                        <fa icon="signature" size="lg" class="me-1" /> Full Signature
                                    </span>
                                    <span v-if="o.type === 'initial'" class="me-1">
                                    <fa icon="pen-nib" size="lg" class="me-1" /> Initial
                                    </span>
                                    <span v-if="o.type === 'dateSigned'" class="me-1">
                                        <fa icon="calendar-alt" size="lg" class="me-1" /> Date Signed
                                    </span>
                                    <span v-if="o.type === 'name'" class="me-1">
                                        <fa icon="user-circle" size="lg" class="me-1" /> Name
                                    </span>
                                    <span v-if="o.type === 'email'" class="me-1">
                                        <fa icon="at" size="lg" class="me-1" /> Email
                                    </span>
                                    <span v-if="o.type === 'idPassport'" class="me-1">
                                        <fa icon="id-card" size="lg" class="me-1" /> ID/Passport
                                    </span>
                                    <span v-if="o.type === 'textbox'" class="me-1">
                                        <fa icon="font" size="lg" class="me-1" /> Textbox
                                    </span>
                                    <span v-if="o.type === 'textarea'" class="me-1">
                                        <fa icon="bars" size="lg" class="me-1" /> Textarea
                                    </span>
                                    <span v-if="o.type === 'checkbox'" class="me-1">
                                        <fa icon="check-square" size="lg" class="me-1" /> Checkbox
                                    </span>
                                    
                                    <span class="badge bg-secondary me-1 isLink" v-for="p in o.pages" :key="p" @click="navigatePage(p); updPageNum(p)">{{p}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 

            </div>

            <div :class="contentClass">
                <div class="vh-100 gx-0">
                    <!-- <div class="tet-primary" style="font-size: 8px">signature: {{signatureSrc}} </div> -->
                    <!-- <div class="tet-primary" style="font-size: 6px">initial: <span v-if="initialSrc !== null">{{initialSrc.substr(1, 100)}} </span></div> -->
                    <!-- <div class="tet-primary">signature: {{signatureW}} X {{signatureH}}, Initial: {{initialW}} x {{initialH}}</div> -->
                    <div v-if="showDebug" class="border border-primary">
                        <!-- <div class="text-success" style="font-size: 10px">mdlCurStep: {{mdlCurStep}}, mdlNextStep: {{mdlNextStep}}, mdlFirstStep: {{mdlFirstStep}} | {{viewWidth}}/{{pdfWidth}}x{{pdfHeight}} (view-Width/pdf-Width) | uiRatio: {{uiRatio}} | objList: {{objList.length}} (unique Obj) | Signee status: {{signeeStatus}}</div> -->
                        <!-- <div class="text-warning fw-bold" style="font-size: 10px">objSign ({{objSign.length}}):</div> -->
                        <!-- <div class="text-warning" style="font-size: 10px" v-for="(s, si) in objSign" :key="s">{{si}} - {{s}}</div> -->
                        <div class="text-info fw-bold" style="font-size: 10px">objList: {{objList.length}}</div>
                        <div class="text-info" style="font-size: 10px" v-for="l in objList" :key="l"> - {{l}}</div>
                        <!-- <div class="text-secondary" style="font-size: 10px">curPage: {{curPage}} | {{mdlCurStep}} / {{mdlTotalStep}} 1st: {{mdlFirstStep}} Next: {{mdlNextStep}}</div> -->
                        
                        <!-- <div class="text-success" style="font-size: 10px"><b>docBoxes ({{docBoxes.length}}) - curSignee in doc (all pages) | totalCompulsoryField: {{totalCompulsoryField}}</b></div> -->
                        <!-- <div class="text-success" style="font-size: 10px" v-for="(d, idx) in docBoxes" :key="d">[{{idx}}] - {{ d }}</div> -->
                        
                        <!-- <div class="text-success" style="font-size: 10px"><b>docBoxes ({{docBoxes.length}}) - curSignee in doc (all pages) =</b> {{ docBoxes }}</div> -->
                        <!-- <div class="text-primary fw-bold" style="font-size: 10px" >pgBoxes: {{ pgBoxes.length }}</div> -->
                        <!-- <div class="text-primary" style="font-size: 10px" v-for="p in pgBoxes" :key="p">{{ p }}</div> -->
                        <!-- <div v-if="doc" class="text-info" style="font-size: 10px">{{ doc.file.viewUrl }}</div> -->
                        <!-- <div class="text-info" style="font-size: 10px">objSign [{{ objSign.length }}]: {{ objSign }}</div> -->
                        <!-- <div class="text-info" style="font-size: 10px">hasMdlAutoFill: {{ hasMdlAutoFill }} | hasMdlStandard: {{hasMdlStandard}}</div> -->

                        <!-- <div class="text-warning" style="font-size: 10px"><b>AutoFill [{{ hasMdlAutoFill }}] - {{ mdlFieldAuto.length }} :</b> {{ mdlFieldAuto }}</div>
                        <div class="text-success" style="font-size: 10px"><b>Standard [{{hasMdlStandard}}] - {{mdlFieldStd.length}} :</b> {{mdlFieldStd}}</div> -->
                    </div>
                    
                    <div v-if="!showUIBox" class="p-2">
                        <div class="spinner-border spinner-border-sm"></div> Loading...
                    </div>


                    <div style="position: relative; width: auto; height: 100%; overflow: auto; top: 0px" class="pdfBg">

                        <div class="parent text-start" id="pdfContainer" ref="pdfContainer" :style="'margin-top: 2px; height: ' + Math.ceil(pdfHeight*uiRatio) + 'px'">
                            <component v-if="signeeStatus === 'INVITED'" v-for="b in pgBoxes" :key="b.id" :is="newBox" :data="b" :boxClass="signeeSelectedClass" :signee="signeeId" 
                            :mdl="mdlFirstStep" :signSrc="signatureSrc" :iniSrc="initialSrc" :signRatio="signRatio" :iniRatio="iniRatio" 
                            :sWidth="signatureW" :sHeight="signatureH" :iWidth="initialW" :iHeight="initialH" :viewWidth="viewWidth"
                                @getResize="getResize" /> 
                                
                                <!-- data-bs-toggle="modal" :data-bs-target="'#' + mdlFirstStep" /> LIVE -->
        
                                <vue-pdf-embed v-if="url !== null" 
                                    id="pdfSign"
                                    ref="pdfSign"
                                    :source="url"
                                    :page="page"
                                    @rendered="handleDocumentRender"
                                />
                        </div>
                    </div>
                </div>
            </div>

            <Modal :title="mdlCurStep +' of '+ mdlTotalStep" id="mdlSignature" :nextStep="mdlNextStep">
                <slot>
                <div class="modal-body">
                    <div>
                        * change to vertical tab pill
                        <!-- <div class="mb-2 fw-bold">Create signature from upload/webcam/mouse (history)</div> -->
                        <div class="mb-2 fw-bold">Create signature from upload/mouse</div>

                        <ul class="nav nav-tabs">
                            <li class="nav-item text-center" style="width: 50%">
                                <a class="nav-link active" data-bs-toggle="tab" href="#uploadSignature"><fa icon="upload" class="me-2"/>Upload</a>
                            </li>
                            <li class="nav-item text-center" style="width: 50%">
                                <a class="nav-link" data-bs-toggle="tab" href="#drawSignature"><fa icon="pen" class="me-2"/>Draw</a>
                            </li>
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane container active my-2" id="uploadSignature">
                                
                                <file-pond
                                    name="file"
                                    ref="pondS"
                                    label-idle="Drag-n-drop image"
                                    v-bind:allow-multiple="true"
                                    accepted-file-types="image/jpeg, image/png, image/gif"
                                    :server="signatureConfig"
                                    :files="mySignatures"
                                    storeAsFile="true"
                                    @init="handleSignaturePondInit"
                                    @error="uplSignatureError"
                                    @processfile="onprocessSignature"
                                    @addfile="getSignature"
                                    allowRevert="true"
                                    allowRemove="false"
                                />
                                <div class="text-center small text-grey">Multiple images <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> 1MB/image limit <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG or GIF only</div>
                            </div>
                            <div class="tab-pane container my-2" id="drawSignature">
                                ...draw...
                            </div>
                            
                            <hr v-if="signatureHistList && signatureHistList.length > 0" class="mt-4 mb-3" />
                            <div v-if="signatureHistList && signatureHistList.length > 0" class="px-2">
                                <div class="mb-2 fw-bold">Select signature to add into document</div>
                                <div>
                                    <div v-for="(s, sIdx) in signatureHistList" :key="s.signatureId">
                                        <!-- <table class="table table-borderless isLink" @click="mdlSignature = s.signatureId; signatureW = 2880; signatureH = 1080; signatureSrc = s.imgBase64; " :class="mdlSignature === s.signatureId ? 'bg-light border' : ''"> -->
                                        <table class="table table-borderless isLink" @click="selectSignature(s)" :class="mdlSignature === s.signatureId ? 'bg-light border' : ''">
                                            <tr>
                                                <td>
                                                    <img :src="'data:image/png;base64, ' + s.imgBase64" style="width: auto; maxHeight: 120px"/>
                                                </td>
                                                <td class="text-end" width="40px">
                                                    <Popper class="popperDark" arrow hover content="Delete signature">
                                                        <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(s.signatureId, 'signature')"/>
                                                    </Popper>
                                                </td>
                                            </tr>
                                        </table>

                                        <hr v-if="sIdx < signatureHistList.length-1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div><!-- End modal-body -->
                <div class="modal-footer">
                    <button v-if="mdlNextStep === null" type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlConfirm">
                        <fa icon="stamp" size="lg" class="me-2" />Confirm and Sign
                    </button>
                    <button v-if="mdlNextStep !== null && mdlSignature !== null" type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#' + mdlNextStep" data-bs-dismiss="modal">
                        <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
                    </button>
                </div>
                </slot>    
            </Modal>

            <Modal :title="mdlCurStep +' of '+ mdlTotalStep" id="mdlInitial" :nextStep="mdlNextStep">
                <slot>
                <div class="modal-body">
                    <div>
                        <!-- <div class="mb-2 fw-bold">Select initial from upload/webcam/mouse (history)</div> -->
                        <div class="mb-2 fw-bold">Create initial from upload/mouse</div>

                        <ul class="nav nav-tabs">
                            <li class="nav-item text-center" style="width: 50%">
                                <a class="nav-link active" data-bs-toggle="tab" href="#uploadInitial"><fa icon="upload" class="me-2"/>Upload</a>
                            </li>
                            <li class="nav-item text-center" style="width: 50%">
                                <a class="nav-link" data-bs-toggle="tab" href="#drawInitial"><fa icon="pen" class="me-2"/>Draw</a>
                            </li>
                        </ul>
                        
                        <div class="tab-content">
                            <div class="tab-pane container active" id="uploadInitial">
                                <file-pond
                                    name="file"
                                    ref="pondI"
                                    label-idle="Drag-n-drop image"
                                    v-bind:allow-multiple="true"
                                    accepted-file-types="image/jpeg, image/png, image/gif,"
                                    :server="initialConfig" 
                                    :files="myInitials"
                                    storeAsFile="true"
                                    @init="handleInitialPondInit"
                                    @error="uplInitialError"
                                    @processfile="onprocessInitial"
                                    allowRevert="true"
                                    allowRemove="false"
                                />
                                <div class="text-center small text-grey">Multiple images <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> 1MB/image limit <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG or GIF only</div>
                            </div>
                            <div class="tab-pane container" id="drawInitial">
                                ...draw...
                            </div>

                            <hr v-if="initialHistList && initialHistList.length > 0" class="mt-4 mb-3" />
                            <div v-if="initialHistList && initialHistList.length > 0" class="px-2">
                                <div class="mb-2 fw-bold">Select initial to add into document</div>
                                <div>
                                    <div v-for="(i, iIdx) in initialHistList" :key="i.signatureId">
                                        <!-- <table class="table table-borderless isLink" @click="mdlInitial = i.signatureId; initialSrc = i.imgBase64" :class="mdlInitial === i.signatureId ? 'bg-light border' : ''"> -->
                                        <table class="table table-borderless isLink" @click="selectInitial(i)" :class="mdlInitial === i.signatureId ? 'bg-light border' : ''">
                                            <tr>
                                                <td>
                                                    <img :src="'data:image/png;base64, ' + i.imgBase64" style="width: auto; maxHeight: 120px"/>
                                                </td>
                                                <td class="text-end" width="40px">
                                                    <Popper class="popperDark" arrow hover content="Delete initial">
                                                        <fa icon="trash" size="lg" class="isLink" @click.prevent="delSignature(i.signatureId, 'initial')"/>
                                                    </Popper>
                                                </td>
                                            </tr>
                                        </table>

                                        <hr v-if="iIdx < initialHistList.length-1" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> 
                </div><!-- End modal-body -->
                <div class="modal-footer">
                    <button v-if="mdlNextStep === null" type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlConfirm">
                        <fa icon="stamp" size="lg" class="me-2" />Confirm and Sign
                    </button>
                    <button v-if="mdlNextStep !== null && mdlInitial !== null" type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#' + mdlNextStep" data-bs-dismiss="modal">
                        <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
                    </button>
                </div>
                </slot>    
            </Modal>

            <Modal :title="mdlCurStep +' of '+ mdlTotalStep" id="mdlAutoFill" :nextStep="mdlNextStep">
                <slot>
                <div class="modal-body">
                    <div>
                        <div class="mb-2 fw-bold">Modify your auto-filled field{{ (mdlFieldAuto.length > 1 ? 's' : '') }} below if incorrect</div>
                        <table width="100%" border="0">
                            <tr v-for="f in mdlFieldAuto" :key="f.id">
                                <td class="align-top pb-2 pe-2">
                                    <div :class="'box color0Bg'" :style="'width: 200px'">
                                        <div v-if="f.type === 'dateSigned'" class="p-1">
                                            <fa icon="calendar-alt" size="lg" class="me-1" />
                                            <span>Date Signed <span v-if="f.isMandatory">*</span></span>
                                        </div>

                                        <div v-if="f.type === 'name'" class="p-1" >
                                            <fa icon="user-circle" size="lg" class="me-1" /> 
                                            <span>Name <span v-if="f.isMandatory">*</span></span>
                                        </div>

                                        <div v-if="f.type === 'email'" class="p-1" >
                                            <fa icon="at" size="lg" class="me-1" /> 
                                            <span>Email <span v-if="f.isMandatory">*</span></span>
                                        </div>

                                        <div v-if="f.type === 'idPassport'" class="p-1" >
                                            <fa icon="id-card" size="lg" class="me-1" /> 
                                            <span>ID/Passport <span v-if="f.isMandatory">*</span></span>
                                        </div>
                                    </div>
                                </td>
                                <td style="padding-bottom: 10px">
                                    <div v-if="f.type === 'name'">
                                        <table width="100%">
                                            <tr>
                                                <td>
                                                    <input v-model="mdlName" type="text" class="form-control form-control-sm" @input="updObjSign(f.id, f.type, mdlName)" />
                                                </td>
                                                <td v-if="mdlNameOpt.length > 1" class="ps-1">
                                                    <div class="dropdown dropstart">
                                                        <button type="button" class="btn btn-sm btn-link" data-bs-toggle="dropdown">
                                                            <fa icon="ellipsis-h" class="text-secondary"/>
                                                        </button>
                                                        <ul class="dropdown-menu">
                                                            <li><a class="dropdown-item" href="#" v-for="na in mdlNameOpt" :key="na" @click="mdlName = na">{{na}}</a></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                        
                                    </div>
                                    <div v-if="f.type === 'email'">
                                        <input v-model="mdlEmail" type="text" class="form-control form-control-sm" @input="updObjSign(f.id, f.type, mdlEmail)" />
                                    </div>
                                    <div v-if="f.type === 'idPassport'">
                                        <input v-model="mdlIdPassport" type="text" class="form-control form-control-sm" @input="updObjSign(f.id, f.type, mdlIdPassport)" />
                                    </div>
                                        
                                    <div v-if="f.type === 'dateSigned'">
                                        Auto record date & time in your timezone....<br>eg. {{ curDateTime }}
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="mdlNextStep === null" type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlConfirm">
                        <fa icon="stamp" size="lg" class="me-2" />Confirm and Sign
                    </button>
                    <button v-else type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#' + mdlNextStep" data-bs-dismiss="modal">
                        <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
                    </button>
                </div>
                </slot>    
            </Modal>

            <Modal :title="mdlCurStep +' of '+ mdlTotalStep" id="mdlStandard" :nextStep="null" >
                <slot>
                <div class="modal-body">
                    <div>
                    <div class="mb-2 fw-bold">Fill in the field{{ (mdlFieldStd.length > 1 ? 's' : '') }} below</div>

                    <table width="100%" border="0">
                        <tr v-for="s in mdlFieldStd" :key="s.id">
                        <td style="padding-bottom: 15px">
                            <div v-if="s.type === 'textbox'">
                                <label>Textbox: {{ s.label }} <span v-if="s.isMandatory">*</span></label>
                                <input type="text" v-model="mdlTextbox[s.id]" @input="updObjSign(s.id, s.type, mdlTextbox[s.id])" class="form-control form-control-sm" />
                                <span class="text-secondary small">{{s.placeholder}}</span>
                            </div>
                            <div v-if="s.type === 'textarea'">
                                <label>Textarea: {{ s.label }} <span v-if="s.isMandatory">*</span></label>
                                <textarea class="form-control form-control-sm" rows="5" v-model="mdlTextarea[s.id]" @input="updObjSign(s.id, s.type, mdlTextarea[s.id])"></textarea>
                                <span class="text-secondary small">{{s.placeholder}}</span>
                            </div>
                            <div v-if="s.type === 'checkbox'">
                                <input type="checkbox" v-model="mdlCheckbox[s.id]" value="1" @change="updObjSign(s.id, s.type, mdlCheckbox[s.id])" class="form-check-input" />
                                <label class="form-check-label ms-1">Checkbox: {{ s.label }} <span v-if="s.isMandatory">*</span></label>
                                <div class="ms-3 text-secondary small">{{s.placeholder}}</div>
                            </div>
                        </td>
                        </tr>
                    </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button v-if="mdlNextStep === null" type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlConfirm" data-bs-dismiss="modal">
                        <fa icon="stamp" size="lg" class="me-2" />Confirm and Sign
                    </button>
                    <button v-else type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#' + mdlNextStep" data-bs-dismiss="modal">
                        <fa icon="circle-arrow-right" size="lg" class="me-2" />Next
                    </button>
                </div>
                </slot>    
            </Modal>

            <Modal title="Confirmation" id="mdlConfirm">
                <slot>
                <div class="modal-body">
                    
                    <div class="mb-2 fw-bold">Confirm to sign and submit?</div>
                    <div>You are not allowed to modify after submission.</div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-secondary" @click="confirmSign" data-bs-dismiss="modal">Submit</button>
                </div>
                </slot>    
            </Modal>

        </div><!-- row -->
    </div>
    
</template>

<script>
import { ref, inject, computed, onMounted } from 'vue'
// import TopNavigationView from '@/components/TopNavigationView.vue'
import TopNavigationView from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRoute, useRouter } from 'vue-router'
import VuePdfEmbed from 'vue-pdf-embed'
import DragBox from '@/components/DragBoxSign.vue'
import Modal from '@/components/ModalSign.vue'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import DocStatus from '@/components/DocStatus.vue'
import Avatar from '@/components/Avatar.vue'
// import { useStore } from '@/stores/store'

// Image Uploader for signature & initial
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"


// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

export default {
    name: 'DocView',
    components: { TopNavigationView, Alert, VuePdfEmbed, DragBox, Modal, FilePond, Popper, DocStatus, Avatar },
    setup() {

        const showDebug = ref(false)

        const axios = inject('axios')
        const alert = ref([])
        
        const func = funcs
        const route = useRoute()
        const router = useRouter()
        /* const store = useStore()
        const { getUserId } = useStore() */

        const url = ref(null)
        const showUIBox = ref(false)
        const showToolbox = ref(true)
        const showMoreSignee = ref(false)
        const toolboxBtnIcon = ref(null)
        const contentClass = ref('col-12 p-0')
        // const isToolbarFloat = ref(false)
        const curPage = ref(1) // update tally to page
        const signeeSelectedClass = ref('color0')

        const docBoxes = ref([])  // cur signee boxes in doc (all pages)
        const pgBoxes = ref([])   // cur signee boxes in current page

        const doc = ref([])
        const docName = ref(null)
        const docStatus = ref(null)
        const docSignee = ref([])
        const isDocSigned = ref(null)
        const signeeId = ref(null)
        const signeeStatus = ref('')
        const signeePrivileges = ref([])
        const objList = ref([])  // unique object type to display in signing modal
        const objSign = ref([]) // signed object to update in backend
        const totalCompulsoryField = ref(0)
        const totalDoc = ref(0)

        /* const signatureW = ref(200)
        const signatureH = ref(32) */
        const signatureW = ref(0)
        const signatureH = ref(0)
        const initialW = ref(0)
        const initialH = ref(0)
        const signatureSrc = ref(null)
        const initialSrc = ref(null)

        // Max heigh & width for signature/initial
        const signIniMaxW = ref(250) // 200
        const signIniMaxH = ref(150)  //80
        const signRatio = ref(0)
        const iniRatio = ref(0)

        const pdfContainer = ref(null)
        const pdfWidth = ref(0)
        const pdfHeight = ref(0)
        const uiRatio = ref(0) // ratio between UI width and PDF actual width. 
        // In UI, obj x & y from backend need times uiRatio
        // PDF -> UI: PDF xy * uiRatio
        // UI -> PDF: UI xy / uiRatio

        const signatureHistList = ref([])
        const initialHistList = ref([])

        const hasMdlSignature = ref(false)
        const hasMdlInitial = ref(false)
        const hasMdlAutoFill = ref(false)
        const hasMdlStandard = ref(false)

        const mdlFirstStep = ref(null)
        const mdlNextStep = ref(null)
        const mdlCurStep = ref(0)
        const mdlTotalStep = ref(0)
        const mdlTextbox = ref([])
        const mdlTextarea = ref([])
        const mdlCheckbox = ref([])
        const mdlSignature = ref(null)
        const mdlInitial = ref(null)

        const mdlFieldAuto = ref([]) // auto-filled object in modal content
        const mdlFieldStd = ref([])  // standard object in modal content

        const mdlName = ref(null)
        const mdlEmail = ref(null)
        const mdlIdPassport = ref(null)
        const mdlDateSigned = ref(null)

        const mdlNameOpt = ref([])
        const curDateTime = ref(null)

        const signatureConfig = ref({ 
            "url": "https://signon.my:3002/user/signature/signature", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            } 
        })

        const initialConfig = ref({ 
            "url": "https://signon.my:3002/user/signature/initial", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            } 
        })

        const getSignature = (error, file) => {
            /* console.info('addfile: err', error)
            console.info('addfile: file', file)
            console.info('addfile: file.filename', file.filename)
            console.info('addfile: file.fileSize', file.fileSize)
            console.info('addfile: file.file', file.file)
            console.info('addfile: file.source', file.source)
            console.info('addfile: file.status', file.status)
            console.info('addfile: file.transferId', file.transferId) */
        }

        const selectSignature = async (s) => {

            // console.info('select Signature', JSON.stringify(s))

            //temp hardcoded
            let origW = s.width // 2880
            let origH = s.height // 1080
            signRatio.value = origW/origH

            // console.info('-- orig Signature', origW, ' x ', origH, ' ratio: ', signRatio.value)

            mdlSignature.value = s.signatureId
            signatureSrc.value = s.imgBase64

            // console.info('max', signIniMaxW.value, signIniMaxH.value)

            // Both Width and Height exceed max
            if (origW > signIniMaxW.value && origH > signIniMaxH.value) {
                // console.info('EXCEED BOTH')
                signatureW.value = signIniMaxW.value
                signatureH.value = signIniMaxW.value/signRatio.value

            } else if (origW > signIniMaxW.value) {
                // console.info('EXCEED W')
                signatureH.value = origH
                signatureW.value = origH*signRatio.value

            } else if (origH > signIniMaxH.value) {
                // console.info('EXCEED H')
                signatureW.value = origW
                signatureH.value = origW/signRatio.value

            } else {
                // console.info('X EXCEED')
                // Both not exceed limit
                signatureW.value = origW
                signatureH.value = origH
            }

            // If resized signature still exceed
            if (initialH.value > signIniMaxH.value) {
                // console.info('**** H', signatureH.value, ' > ', signIniMaxH.value)
                signatureH.value = signIniMaxH.value
                signatureW.value = signatureH.value*signRatio.value
            }

            if (signatureW.value > signIniMaxW.value) {
                // console.info('**** W', signatureW.value, ' > ', signIniMaxW.value)
                signatureW.value = signIniMaxW.value
                signatureH.value = signatureW.value/signRatio.value
            }

            console.info('Final Signature:', signatureW.value, ' x ', signatureH.value)
        }

        const selectInitial = async (i) => {

            // console.info('select initial', JSON.stringify(i))

            //temp hardcoded
            let origW = i.width // 190
            let origH = i.height // 143
            iniRatio.value = origW/origH

            // console.info('-- orig Signature', origW, ' x ', origH, ' ratio: ', iniRatio.value)

            mdlInitial.value = i.signatureId
            initialSrc.value = i.imgBase64

            // console.info('max', signIniMaxW.value, signIniMaxH.value)

            // Both Width and Height exceed max
            if (origW > signIniMaxW.value && origH > signIniMaxH.value) {
                // console.info('EXCEED BOTH')
                initialW.value = signIniMaxW.value
                initialH.value = signIniMaxW.value/iniRatio.value

            } else if (origW > signIniMaxW.value) {
                // console.info('EXCEED W')
                initialH.value = origH
                initialW.value = origH*iniRatio.value

            } else if (origH > signIniMaxH.value) {
                // console.info('EXCEED H')
                initialW.value = origW
                initialH.value = origW/iniRatio.value

            } else {
                // console.info('X EXCEED')
                // Both not exceed limit
                initialW.value = origW
                initialH.value = origH
            }

            // If resized initial still exceed
            if (initialH.value > signIniMaxH.value) {
                // console.info('**** H', initialH.value, ' > ', signIniMaxH.value)
                initialH.value = signIniMaxH.value
                initialW.value = initialH.value*iniRatio.value
            }

            if (initialW.value > signIniMaxW.value) {
                // console.info('**** W', initialW.value, ' > ', signIniMaxW.value)
                initialW.value = signIniMaxW.value
                initialH.value = initialW.value/iniRatio.value
            }

            console.info('Final Initial:', initialW.value, ' x ', initialH.value)
        }
        
        const uplSignatureError = (error) => {
            console.info('uplSignatureError', error)
        }

        const uplInitialError = (error) => {
            console.info('uplInitialError', error)
        }

        const onprocessSignature = (err, file) => {
            if (err === null) {
                getHistoryList('signature')
            }
        }
        const onprocessInitial = (err, file) => {
            // console.info('processfile: err', err)
            // console.info('processfile: file', file)
            if (err === null) {
                getHistoryList('initial')
            }
        }

        const delSignature = (id, opt) => {
            axios.delete( '/user/signature/' + id)
                .then((res) => {
                    if (res.data.status === 1) {

                        getHistoryList(opt)
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Signature is removed."
                        })
                    } 
                })
                .catch((error) => {
                    console.log('delSignature | FAILURE', JSON.stringify(error))
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.convError(error)
                    })
                })
        }

        const toggleToolbox = async () => {
            showToolbox.value = !showToolbox.value
            localStorage.leftSignDrawer = showToolbox.value

            localStorage.setItem('leftSignDrawer', showToolbox.value)

            // location.reload() // Need detect window size then recalculate signee object position

            /* if (localStorage.leftSignDrawer === undefined || localStorage.leftSignDrawer === 'false') {
                showToolbox.value = false
                toolboxBtnIcon.value = "chevron-right"
            } else { 
                showToolbox.value = true
                toolboxBtnIcon.value = "chevron-left"
            }
            getContentClass() */
        }

        /* const toggleToolbarFloat = async () => {
            isToolbarFloat.value = !isToolbarFloat.value
            localStorage.setItem('leftFloatDrawer', isToolbarFloat.value)

            getContentClass()
        }

        const getContentClass = () => {
            if (isToolbarFloat.value) {
                contentClass.value = 'col-12 p-0'

            } else {
                if (showToolbox.value === true) {
                    contentClass.value = 'col-7 col-md-9 col-lg-10 p-0'
                } else {
                    contentClass.value = 'col-12 p-0'
                }
            }
        } */

        const getResize = (t, id, w, h) => {
            console.info('***** ', t, w, h, typeof w)
            if (t === 'fullSignature') {
                signatureW.value = w
                signatureH.value  = h
            }

            if (t === 'initial') {
                console.info('inside initial')
                initialW.value = w
                initialH.value  = h
            }
        }

        const convObjToUi = (val) => {

            let tmpObj = val
            if (tmpObj.length === 0) {
                return tmpObj

            } else {
                console.info('---- tmpObj', ' -- uiRatio: ', uiRatio.value)
                tmpObj.forEach((item, idx) => {
                    
                    tmpObj[idx].position.x = item.position.x * uiRatio.value
                    tmpObj[idx].position.y = item.position.y * uiRatio.value
                })
                return tmpObj
            } 
        }

        const newBox = computed(() => {
            return DragBox
        })

        const updPageNum = (page1) => {
            curPage.value = page1
            getDocBoxesToPgBoxes()
        }

        const updObjSign = (id, type, val) => {
            // console.info('updObjSign', id, type, val)

            if (type === 'name') mdlName.value = val
            if (type === 'email') mdlEmail.value = val
            if (type === 'idPassport') mdlIdPassport.value = val
            
            if (type === 'textbox') mdlTextbox.value[id] = val
            if (type === 'textarea') mdlTextarea.value[id] = val
            if (type === 'checkbox') mdlCheckbox.value[id] = val

            // Change textbox, textarea and checkbox value
            if (type === 'textbox' || type === 'textarea' || type === 'checkbox') {
                objSign.value.forEach((item, idx) => {
                    if (item.id === id) {
                        objSign.value[idx].value = val //replace value
                    }
                })
            }
        }

        const confirmSign = async () => {
 
            // console.info('---- func: confirmSign docBoxes', docBoxes.value.length, JSON.stringify(docBoxes.value))
            // console.info('---- func: confirmSign objSign', objSign.value.length, JSON.stringify(objSign.value))
            // console.info('---- func: confirmSign', 'uiRatio: ', uiRatio.value, )

            if (docBoxes.value.length > 0) {
                let curDate = new Date()

                docBoxes.value.forEach((item, idx) => {

                    // fullSignature
                    if (item.type === 'fullSignature') {
                        objSign.value.push({id: item.id, value: mdlSignature.value, type: item.type, width: signatureW.value, height: signatureH.value})
                    }

                    // initial
                    if (item.type === 'initial') {
                        objSign.value.push({id: item.id, value: mdlInitial.value, type: item.type, width: initialW.value, height: initialH.value})
                    }

                    // Handle Date Signed
                    if (item.type === 'dateSigned') {
                        objSign.value.push({id: item.id, value: curDate, type: item.type})
                    }
                    
                    if (item.type === 'name' ) {
                       objSign.value.push({id: item.id, value: mdlName.value, type: item.type})
                    }

                    if (item.type === 'email' ) {
                       objSign.value.push({id: item.id, value: mdlEmail.value, type: item.type})
                    }

                    if (item.type === 'idPassport' ) {
                       objSign.value.push({id: item.id, value: mdlIdPassport.value, type: item.type})
                    }
                }) //docBoxes

                // finalObjSign do not have type param
                let finalObjSign = []
                objSign.value.forEach((item) => {
                    if (item.type === 'fullSignature' || item.type === 'initial') {
                        // Convert width and height based on ratio
                        finalObjSign.push({id: item.id, value: item.value, width: item.width/uiRatio.value, height: item.height/uiRatio.value})
                    } else {
                        finalObjSign.push({id: item.id, value: item.value})
                    }
                })

                const p = {
                    objects: finalObjSign
                }

                console.info('confirm & sign', JSON.stringify(p))
                axios.put( '/signon/signee/' + route.params.projectId + '/' + route.params.documentId, p)
                    .then((res) => {
                        if (res.data.status === 1) {

                            iniLoad()
                            alert.value.push({
                                class: "success",
                                title: "SUCCESS",
                                message: "Document is signed."
                            })
                        } 
                    })
                    .catch((error) => {
                        console.log('confirmSign | FAILURE', JSON.stringify(error))
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.convError(error)
                        })
                    })
            
            }
        }

        const getDoc = async () => {
            console.info('---- getDoc', uiRatio)
            mdlNameOpt.value = []

            try {
                const res = await axios.get('/signon/signee/document/' + route.params.documentId)
                console.info('getDoc signee', JSON.stringify(res.data.data.signee))
                if (res.data.status === 1) {

                    pdfWidth.value = res.data.data.file.pageWidth
                    pdfHeight.value = res.data.data.file.pageHeight

                    if (uiRatio.value === 0) {
                        // Cater for cannot capture uiRatio in handleDocument function
                        uiRatio.value = pdfContainer.value.clientWidth/pdfWidth.value
                        console.info('---- SET uiRatio (Composition API)', uiRatio.value, pdfContainer.value.clientWidth,' / ', pdfWidth.value)
                    }

                    doc.value = res.data.data
                    docName.value = res.data.data.file.name.replace('.pdf', '')
                    docStatus.value = res.data.data.status
                    isDocSigned.value = res.data.data.signee.status === 'SIGNED'
                    url.value = res.data.data.file.viewUrl

                    signeeStatus.value = res.data.data.signee.status
                    signeePrivileges.value = res.data.data.signee.privileges

                    if (doc.value.signees) {
                        // Get creator first
                        doc.value.signees.forEach((item, idx) => {
                            if (item.name === doc.value.creator.displayName ) {
                                docSignee.value.push(item)
                            }
                        })
                    }

                    if (doc.value.signees) {
                        // Get non-creator
                        doc.value.signees.forEach((item, idx) => {
                            if (item.name !== doc.value.creator.displayName ) {
                                docSignee.value.push(item)
                            }
                        })
                    }

                    if (res.data.data.signee.displayName && res.data.data.signee.displayName !== '' && res.data.data.signee.displayName !== null) {
                        mdlNameOpt.value.push(res.data.data.signee.displayName)
                    }

                    if (res.data.data.signee.firstName || res.data.data.signee.lastName) {
                        mdlNameOpt.value.push(res.data.data.signee.firstName + ' ' + res.data.data.signee.lastName)
                    }

                    mdlName.value = res.data.data.signee.firstName + ' ' + res.data.data.signee.lastName
                    mdlEmail.value = res.data.data.signee.email
                    mdlIdPassport.value = res.data.data.signee.id

                    console.info('---- getDoc status', res.data.data.signee.status)

                    // Show object only if the signee not sign doc yet
                    if (res.data.data.signee.status === 'INVITED') {
                        console.info('---- getDoc', uiRatio.value)

                        docBoxes.value = convObjToUi(res.data.data.signee.objects)
                        console.info('---- getDoc docBoxes', docBoxes.value)
                        await getDocBoxesToPgBoxes()
                        await getObjectList(true)
                    }
                } 
            } catch (error) {
                console.log('getDoc | FAILURE', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            }
            
        }

        const getProj = async () => {
            try {
                const res = await axios.get('/signon/signee/' + route.params.projectId)
                // console.info('getProj', JSON.stringify(res.data.data))
                if (res.data.status === 1) {
                    totalDoc.value = res.data.data.documents.length
                } 
            } catch (error) {
                console.log('getProj | FAILURE', JSON.stringify(error))
            }
        }

        const getObjectList = async (setObjList) => {
            let tmpList = []
            let objSignTmp = objSign.value // Get back previous value entered in mdl

            objSign.value = []
            
            docBoxes.value.forEach((item, idx) => {
                // Insert checkbox, textbox and textarea into objSign (allow duplicate as each field different value)
                if (item.type === 'checkbox') {
                    
                    let match = false
                    objSignTmp.forEach((oItem, oIdx) => {
                        if (oItem.id === item.id) {
                            match = true
                            objSign.value.push({id: item.id, value: oItem.value, type: item.type})
                        }
                    })

                    if (!match) {
                        objSign.value.push({id: item.id, value: false, type: item.type})
                    }
                }

                if (item.type === 'textbox' || item.type === 'textarea') {

                    let match = false
                    objSignTmp.forEach((oItem, oIdx) => {
                        if (oItem.id === item.id) {
                            match = true
                            objSign.value.push({id: item.id, value: oItem.value, type: item.type})
                        }
                    })

                    if (!match) {
                        objSign.value.push({id: item.id, value: '', type: item.type})
                    }
                }

                if (!tmpList.includes(item.type)) {
                    tmpList.push(item.type)
                }
            })

            if (setObjList) {
                tmpList.forEach((obj, objIdx) => {
                    objList.value.push({ type: obj, pages: []})
                    docBoxes.value.forEach((item, idx) => {
                        if (obj === item.type) {
                        
                            if(!objList.value[objIdx].pages.includes(item.position.page)) {
                                objList.value[objIdx].pages.push(item.position.page)
                            }
                        }
                    })
                })
            }
        }

        const getHistoryList = (listOpt) => {
            axios.get( '/user/signature/list/' + listOpt)
            .then((res) => {
                // console.info('getHistoryList: ' + listOpt, JSON.stringify(res.data.data))
                if (res.data.status === 1) {
                    if (listOpt === 'initial') {
                        initialHistList.value = res.data.data
                    } else {
                        signatureHistList.value = res.data.data
                    }
                } 
            })
            .catch((error) => {
                console.log('getHistoryList | FAILURE', JSON.stringify(error))
            })
        }

        const getDocBoxesToPgBoxes = async () => {
            // Get pgBoxes from docBoxes
            // console.info('-- func: getDocBoxesToPgBoxes')

            pgBoxes.value = []
            docBoxes.value.forEach((item, idx) => {
                if (item.isMandatory) {
                    totalCompulsoryField.value++
                }

                if (item.position.page === curPage.value) {
                    pgBoxes.value.push(item)
                }
            })
        }

        const iniMdl = () => {
            console.info('---- func: iniMdl, docBoxes len', docBoxes.value.length)
            mdlTotalStep.value = 0

            docBoxes.value.forEach((item) => {
                console.info('-------------', item)

                if (item.type === 'fullSignature') {
                    hasMdlSignature.value = true
                }

                if (item.type === 'initial') {
                    hasMdlInitial.value = true
                }

                if (item.type === 'dateSigned' || item.type === 'name' || item.type === 'email' || item.type === 'idPassport') {
                    hasMdlAutoFill.value = true

                    getModalField('mdlAutoFill')
                }

                if (item.type === 'textbox' || item.type === 'textarea' || item.type === 'checkbox') {
                    hasMdlStandard.value = true

                    getModalField('mdlStandard')
                }
            })

            if (hasMdlSignature.value) mdlTotalStep.value ++
            if (hasMdlInitial.value) mdlTotalStep.value ++
            if (hasMdlAutoFill.value) mdlTotalStep.value ++
            if (hasMdlStandard.value) mdlTotalStep.value ++

            if (hasMdlSignature.value) {
                mdlFirstStep.value = 'mdlSignature'
                if (hasMdlInitial.value) {
                    mdlNextStep.value = 'mdlInitial'
                } else if (hasMdlAutoFill.value) {
                    mdlNextStep.value = 'mdlAutoFill'
                } else if (hasMdlStandard.value) {
                    mdlNextStep.value = 'mdlStandard'
                }
            } else if (hasMdlInitial.value) {
                mdlFirstStep.value = 'mdlInitial'
                if (hasMdlAutoFill.value) {
                    mdlNextStep.value = 'mdlAutoFill'
                } else if (hasMdlStandard.value) {
                    mdlNextStep.value = 'mdlStandard'
                }
            } else if (hasMdlAutoFill.value) {
                mdlFirstStep.value = 'mdlAutoFill'
                if (hasMdlStandard.value) {
                    mdlNextStep.value = 'mdlStandard'
                }
            } else if (hasMdlStandard.value) {
                mdlFirstStep.value = 'mdlStandard'
                mdlNextStep.value = null
            }
        }

        const getModalField = (mdlId) => {
            let arrDuplicate = []

            if (mdlId === 'mdlAutoFill') {
                mdlFieldAuto.value = []
            } else {
                mdlFieldStd.value = []
            }

            docBoxes.value.forEach((item, index) => {
                if (mdlId === 'mdlAutoFill') {
                    // Unique field only
                    if (item.type === 'dateSigned' || item.type === 'email' || item.type === 'name' || item.type === 'idPassport') {

                        if (arrDuplicate.includes(item.type) === false) {
                            arrDuplicate.push(item.type)
                            mdlFieldAuto.value.push(item)
                        }
                    }
                }

                if (mdlId === 'mdlStandard') {
                    // All standard fields (included duplicate)
                    if (item.type === 'textbox' || item.type === 'textarea' || item.type === 'checkbox') {
                        mdlFieldStd.value.push(item)
                    }
                }
            })
        }

        const getCurDateTime = (format) => {
            // Either YYYY-MM-DD or DD-MMM-YYYY

            const curDate = new Date()
            const strCurDate = String(curDate)
            const curMonth = ref(null)
            curMonth.value = curDate.getMonth() + 1

            if (format === 'DD-MMM-YYYY') {
                // console.info('curMonth ' +typeof curMonth.value, curMonth.value)
                if (curMonth.value === 1) curMonth.value = 'JAN'
                if (curMonth.value === 2) curMonth.value = 'FEB'
                if (curMonth.value === 3) curMonth.value = 'MAR'
                if (curMonth.value === 4) curMonth.value = 'APR'
                if (curMonth.value === 5) curMonth.value = 'MAY'
                if (curMonth.value === 6) curMonth.value = 'JUN'
                if (curMonth.value === 7) curMonth.value = 'JUL'
                if (curMonth.value === 8) curMonth.value = 'AUG'
                if (curMonth.value === 9) curMonth.value = 'SEP'
                if (curMonth.value === 10) curMonth.value = 'OCT'
                if (curMonth.value === 11) curMonth.value = 'NOV'
                if (curMonth.value === 12) curMonth.value = 'DEC'

                const gmt = ref(null)
                const tmpDate = ref([]) 
                tmpDate.value = strCurDate.split('GMT')
                tmpDate.value = tmpDate.value[1].split(' (')
                gmt.value = tmpDate.value[0]
                
                return curDate.getDate() + '-' + curMonth.value + '-' + curDate.getFullYear() + ' (' + gmt.value + ')'
            
            } else {
                return curDate.getFullYear() + '-' + (curMonth.value < 10 ? '0' + curMonth.value : curMonth.value) + '-' + curDate.getDate() + ' (' + gmt.value + ')'
            }
        }

        const iniLoad = async () => {
            console.info('---- func: iniLoad', 'localStorage.leftSignDrawer:', localStorage.leftSignDrawer)

            if (localStorage.getItem('leftSignDrawer') === undefined || localStorage.getItem('leftSignDrawer') === null) {

                // Default - show toolbar for user to close
                showToolbox.value = true
                localStorage.setItem('leftSignDrawer', true)

                /* isToolbarFloat.value = false
                localStorage.setItem('leftFloatDrawer', false)
                
                toolboxBtnIcon.value = "chevron-left" */

            } else {
                showToolbox.value = localStorage.getItem('leftSignDrawer') === 'true'
                /* isToolbarFloat.value = localStorage.getItem('leftFloatDrawer') === 'true'

                if (showToolbox.value) {
                    toolboxBtnIcon.value = "chevron-left"

                } else {
                    toolboxBtnIcon.value = "chevron-right"
                } */
            }

            // getContentClass()
            await getDoc()
            await getProj()
            await iniMdl()

            console.info('pid', route.params.projectId)
            showUIBox.value = true
            
        }

        iniLoad()

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        onMounted(() => {
            // console.info('---- onMounted ' + new Date().getMinutes() + ':' + new Date().getSeconds())

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }

            curDateTime.value = getCurDateTime('DD-MMM-YYYY')

            getHistoryList('initial')
            getHistoryList('signature')

            let objMdl1 = document.querySelector('#mdlSignature')
            objMdl1.addEventListener("shown.bs.modal", function (event) {
                mdlCurStep.value = 1
                getObjectList(false) // Everytime first click modal

                if (hasMdlInitial.value)
                    mdlNextStep.value = 'mdlInitial'
                else if (hasMdlAutoFill.value)
                    mdlNextStep.value = 'mdlAutoFill'
                else if (hasMdlStandard.value)
                    mdlNextStep.value = 'mdlStandard'
                else 
                    mdlNextStep.value = null
            })

            let objMdl2 = document.querySelector('#mdlInitial')
            objMdl2.addEventListener("shown.bs.modal", function (event) {

                if (hasMdlSignature.value) {
                    mdlCurStep.value ++
                } else {
                    mdlCurStep.value = 1
                }

                if (hasMdlAutoFill.value)
                    mdlNextStep.value = 'mdlAutoFill'
                else if (hasMdlStandard.value)
                    mdlNextStep.value = 'mdlStandard'
                else 
                    mdlNextStep.value = null
            })
            
            let objMdl3 = document.querySelector('#mdlAutoFill')
            objMdl3.addEventListener("shown.bs.modal", function (event) {

                if(hasMdlSignature.value === false && hasMdlInitial.value === false) {
                    mdlCurStep.value = 1
                } else {
                    mdlCurStep.value ++
                }
                
                if (hasMdlStandard.value)
                    mdlNextStep.value = 'mdlStandard'
                else 
                    mdlNextStep.value = null
            })

            let objMdl4 = document.querySelector('#mdlStandard')
            objMdl4.addEventListener("shown.bs.modal", function (event) {

                if (hasMdlSignature.value === false && hasMdlInitial.value === false && hasMdlAutoFill.value === false) {
                    mdlCurStep.value = 1
                } else {
                    mdlCurStep.value ++
                }
                
                mdlNextStep.value = null
            })
        })


        return {
            showDebug, axios, func, route, router, contentClass, alert, closeAlert, newBox, signeeSelectedClass, showUIBox, 
            hasMdlSignature, hasMdlInitial, hasMdlAutoFill, hasMdlStandard, 
            mdlFirstStep, mdlNextStep, mdlCurStep, mdlTotalStep, mdlTextbox, mdlTextarea, mdlCheckbox, mdlName, mdlEmail, mdlIdPassport, mdlDateSigned, 
            doc, docName, docStatus, isDocSigned, url, curPage, showToolbox, toolboxBtnIcon, toggleToolbox, docBoxes, pgBoxes, signeeId, getCurDateTime,
            updPageNum, getDoc, objList, objSign, updObjSign, confirmSign, totalCompulsoryField,
            pdfWidth, pdfHeight, uiRatio, signatureHistList, initialHistList,
            delSignature, initialConfig, onprocessInitial, uplInitialError, signatureConfig, onprocessSignature, uplSignatureError, 
            mdlSignature, mdlInitial, getSignature, mdlNameOpt,
            mdlFieldAuto, mdlFieldStd, curDateTime, signeeStatus, signeePrivileges, pdfContainer,
            getResize, signatureW, signatureH, initialW, initialH, signatureSrc, initialSrc, 
            signIniMaxW, signIniMaxH, selectSignature, selectInitial, signRatio, iniRatio, totalDoc, showMoreSignee, docSignee
            /* isToolbarFloat, toggleToolbarFloat */
        }
    },
    data () {
        return {
            isLoading: false,
            page: 1,
            pageCount: 1,
            goToPage: 1,
            showAllPages: false,
            scale: 0.8,
            transX: 0,
            transY: 0,
            winWidth: 0,
            viewWidth: 0,
            getWindowWidth: 0,
            getScreenWidth: 0,
            mySignatures: [],  
            myInitials: [] 
        }
    },
    watch: {
        showAllPages() {
            this.page = this.showAllPages ? null : 1
            localStorage.setItem('page', this.page)
        },
        showWidth() {
            // console.info('showWidth', this.$refs.pdfContainer.clientWidth)
            return this.$refs.pdfContainer.clientWidth
        },
        curPage(value) {
            // Change to first page if detected change document 
            if (value === 1 && value !== this.page) {
            this.navigatePage('first')
            }
        }
    },
    mounted () {
        // Initiate tooltip
        // Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))
        // Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode))

        // console.info('---- mounted -- handleRize ' + new Date().getMinutes() + ':' + new Date().getSeconds())
        
        window.addEventListener('resize', this.handleResize)
        this.handleResize()

        setTimeout(() => {
            this.zoomDefault()
        }, 1000)
    },
    methods: {
        handleResize() {
            // console.info('---- func: handleResize ')
            this.winWidth = window.innerWidth
            if (this.$refs.pdfContainer !== null) {
                this.viewWidth = this.$refs.pdfContainer.clientWidth // mounted only can use
                
                // Cater for dragbox cannot detect height
                setTimeout(() => {
                    // this.uiRatio = parseFloat(this.viewWidth/this.pdfWidth).toFixed(2) * 1 // rounded to 2 decimals
                    this.uiRatio = this.viewWidth/this.pdfWidth
                    // console.info('---- func: handleResize ', this.viewWidth, this.pdfWidth, 'uiRatio: ', this.uiRatio, this.viewWidth + ' / '+ this.pdfWidth)
                }, 2000)    
            }
        },
        handleDocumentRender() {
            console.info('---- func: handleDocumentRender', 'uiRatio: ', this.uiRatio)
            if (this.uiRatio === Infinity) {
                console.info('yn infi')
            }
            // console.info('---- handleDocumentRender '+ new Date().getMinutes() + ':' + new Date().getSeconds())

            this.isLoading = false
            this.pageCount = this.$refs.pdfSign.pageCount

            localStorage.setItem('page', this.page)
            localStorage.setItem('totalPage', this.pageCount)
            // console.info('---- handleDocumentRender END '+ new Date().getMinutes() + ':' + new Date().getSeconds())
        },
        navigatePage (opt) {
            if (opt === 'prev')
            {
                this.page--
                this.goToPage = this.page
            } else if (opt === 'next') {
                this.page++
                this.goToPage = this.page
            } else if (opt === 'first') {
                this.page = 1
                this.goToPage = this.page
            } else if (opt === 'last') {
                this.page = this.pageCount
                this.goToPage = this.page
            } else if (opt !== '' && opt > 0) {
                this.page = opt * 1
                this.goToPage = opt * 1       
            } else {
                this.goToPage = this.goToPage * 1
                if (this.goToPage > 0 && this.goToPage <= this.pageCount) {
                    this.page = this.goToPage
                } else { 
                    this.page = 1
                    this.goToPage = this.page
                }
            }
            localStorage.setItem('page', this.page)
        },
        zoomIn () {
            this.scale = this.scale + 0.1
            /* this.transX = this.transX + 44 - (this.scale * 4)
            this.transY = this.transY + 53 - (this.scale * 3)  */

            document.getElementById('pdfContainer').style.transform = 'scale(' + this.scale + ', ' + this.scale + ') translate(' + this.transX + 'px, ' + this.transY + 'px)'
            document.getElementById('pdfContainer').style.transformOrigin = 'right top'
            // console.info('scale = ' + this.scale, this.transX + ' ' + this.transY)
            // console.info('win', window.innerWidth + ' ' + window.innerHeight)
        },
        zoomOut () {
            
            this.scale = this.scale - 0.1
            /* this.transX = this.transX - 40
            this.transY = this.transY - 50 */

            document.getElementById('pdfContainer').style.transform = 'scale(' + this.scale + ', ' + this.scale + ') translate(' + this.transX + 'px, ' + this.transY + 'px)'
            document.getElementById('pdfContainer').style.transformOrigin = 'right top'
        },
        zoomDefault () {
            console.info('-------default')
            this.scale = 0.8
            this.transX = 0
            this.transY = 0

            document.getElementById('pdfContainer').style.transform = 'scale(' + this.scale + ', ' + this.scale + ') translate(' + this.transX + 'px, ' + this.transY + 'px)'
            document.getElementById('pdfContainer').style.transformOrigin = 'right top'
        },
        handleSignaturePondInit (){
            // console.info('FilePond has initialized')
            // example of instance method call on pond reference
            // let a = this.$refs.pondS.getFiles()
            // console.info('handleSignaturePondInit', a)
            this.$refs.pondS.getFiles()
        },
        handleInitialPondInit (){
            // console.info('FilePond has initialized')
            // example of instance method call on pond reference
            this.$refs.pondI.getFiles()
        }
    }
}
</script>

<style>
body {
    color: #6E777E;
    font-size: 14px;
    overflow: hidden;
}

.pdfBg {
  background-image: url("../../assets/bgTrans1.png");
  width: 100%;
}

.parent {
    width: 100%;
    /* position: absolute; */
    top: 0px;
    left: 0px;
    user-select: none;

    position: relative;
}
.box {
    border-style: dashed;
    border-width: 3px;
    cursor: pointer;
    border-radius: 5px;
}
.box:hover {
    border-style: dashed;
    border-width: 3px;
    cursor: pointer;
}
.box:active {
    cursor: pointer;
    border-style: dashed;
    border-width: 3px;
}

.boxSignInitial {
  border-style: double;
  border-width: 3px;
  cursor: nw-resize;
  border-radius: 5px;
  /* max-width: 200px;
  max-height: 80px; */
  max-width: 250px;
  max-height: 150px;
}
.boxSignInitial:hover {
  border-style: double;
  border-width: 3px;
  /* max-width: 200px;
  max-height: 80px; */
  max-width: 250px;
  max-height: 150px;
}
.boxSignInitial:active {
  border-style: double;
  border-width: 3px;
  /* max-width: 200px;
  max-height: 80px; */
  max-width: 250px;
  max-height: 150px;
}

.boxSignInitialDef {
  border-style: double;
  border-width: 3px;
  cursor: default;
  border-radius: 5px;
  max-width: 250px;
  max-height: 150px;
}

@media (max-width: 575.98px) {
    .boxSignInitial > div > div> svg, .boxSignInitial > div > div> span {
        font-size: 7px;
    }

    .boxSignInitialDef > div > div > span {
        font-size: 7px;
    }

    .boxSignInitialDef > div > div > svg {
        font-size: 7px;
        float: top;
    } 
}

.boxDisabled {
  border-style: dashed;
  border-width: 3px;
  cursor: pointer;
  border-radius: 5px;
}
.boxDisabled:hover {
  border-style: dashed;
  border-width: 3px;
}
.boxDisabled:active {
  border-style: dashed;
  border-width: 3px;
}

@media (max-width: 575.98px) {
    .boxDisabled   {
        font-size: 7px;
    }
    .boxDisabled > div > svg, .boxSignInitial > div > svg, .boxSignInitialDef > div > svg {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 7px;
    }
    .boxDisabled > div > span, .boxSignInitial > div > span, .boxSignInitialDef > div > span {
        padding-top: 0px;
        padding-bottom: 0px;
        font-size: 7px;
    }
}

.boxSelected {
    border-width: 4px;
    border-style: solid;
}
.boxCal {
    padding-left: 8px;
    padding-right: 8px;
    width: 200px;
}

.color0 {
    color: #1141AF;
    border-color: #1141AF;
}
.color0Bg {
    color: #1141AF;
    border-color: #1141AF;
    background-color: rgba(17, 65, 175, 0.2);
}
.color1 {
    color: #CD0275;
    border-color: #CD0275;
}
.color1Bg {
    color: #CD0275;
    border-color: #CD0275;
    background-color: rgba(205, 2, 117, 0.2);
}
.color2 {
    color: #00999D;
    border-color: #00999D;
}
.color2Bg {
    color: #00999D;
    border-color: #00999D;
    background-color: rgba(0, 153, 157, 0.2);
}
.color3 {
    color: #FF7302;
    border-color:  #FF7302;
}
.color3Bg {
    color: #FF7302;
    border-color:  #FF7302;
    background-color: rgba(255, 115, 2, 0.2);
}
.color4 {
    color: #AC6BC6;
    border-color:  #AC6BC6;
}
.color4Bg {
    color: #AC6BC6;
    border-color:  #AC6BC6;
    background-color: rgba(172, 107, 198, 0.2);
}
.color5 {
    color: #967218;
    border-color:  #967218;
}
.color5Bg {
    color: #967218;
    border-color:  #967218;
    background-color: rgba(243, 200, 91, 0.2);
}
.color6 {
    color: #7F7F7F;
    border-color:  #7F7F7F;
}
.color6Bg {
    color: #7F7F7F;
    border-color:  #7F7F7F;
    background-color: rgba(191, 191, 191, 0.2);
}
.color7 {
    color: #DF0808;
    border-color:  #DF0808;
}
.color7Bg {
    color: #DF0808;
    border-color:  #DF0808;
    background-color: rgba(241, 137, 137, 0.2);
}
.color8 {
    color: #1E90FF;
    border-color:  #1E90FF;
}
.color8Bg {
    color: #1E90FF;
    border-color:  #1E90FF;
    background-color: rgba(173, 216, 230, 0.2);
}
.color9 {
    color: #008000;
    border-color:  #008000;
}
.color9Bg {
    color: #008000;
    border-color:  #008000;
    background-color: rgba(142, 249, 142, 0.2);
}
.color10 {
    color: #9B497E;
    border-color:  #9B497E;
}
.color10Bg {
    color: #9B497E;
    border-color:  #9B497E;
    background-color: rgba(237, 162, 211, 0.2);
}


.toolboxBtnClose {
  position: fixed; 
  margin-top: 6px;
  width: 20px; 
  height: 45px; 
  padding: 11px 2px; 
  background-color: grey;
  border-top-right-radius: 8px; 
  border-bottom-right-radius: 8px; 
  z-index: 9; 
  opacity: 0.8; 
  cursor: pointer
}

.toolboxBtnOpen {
  position: fixed;
  margin-top: 6px;
  left: 251px; 
  width: 20px; 
  height: 45px; 
  padding: 11px 2px; 
  background-color: grey;
  border-top-right-radius: 8px; 
  border-bottom-right-radius: 8px; 
  z-index: 9; 
  opacity: 0.8; 
  cursor: pointer
}

/* .toolboxBtn {
    position: fixed; 
    width: 20px; 
    height: 45px; 
    padding: 10px 2px; 
    background-color: grey;
    border-top-right-radius: 8px; 
    border-bottom-right-radius: 8px; 
    z-index: 9; 
    opacity: 0.8; 
    cursor: pointer;
} */
.handle {
    cursor: nw-resize;
}

.card {
    background-color: var(--toolbar-bg);
    border: 1px solid var(--toolbar-border);
}

.card-header {
    padding: 0px;
    border-bottom: 0px;
    background-color: var(--toolbar-bg);
}

.card-header .btn {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 600;
}

.card-header .btn:focus {
    box-shadow: 0 0 0 0;
}

.card-body {
    padding: 5px 12px;
}

.border-bottom {
  border-bottom: 1px solid var(--toolbar-hr) !important;
}

.toolbarFloat {
  position: fixed; 
  z-index: 2; 
  top: 41px;
  width: 255px; 
  opacity: 0.8; 
}
</style>