<template>

    <span v-if="status === 'DRAFT'" class="isTooltipsLabel mx-1" :class="class">
        <Popper class="popperDark" arrow hover :content="status">
            <fa icon="file" size="lg" class="text-success me-1" />
        </Popper>
    </span>

    <span v-if="status === 'INVITED'" class="isTooltipsLabel me-2" :class="class">
        <Popper class="popperDark" arrow hover :content="status">
           <fa icon="envelope" size="lg" class="text-purple" />
        </Popper>
    </span>

    <span v-if="status === 'SIGNING'" class="isTooltipsLabel me-1" :class="class">
        <Popper class="popperDark" arrow hover :content="status">
            <fa icon="user-gear" size="lg" class="text-info" />
        </Popper>
    </span>

    <span v-if="status === 'ALLSIGNED'" class="isTooltipsLabel me-1" :class="class">
        <Popper class="popperDark" arrow hover :content="status">
            <fa icon="signature" size="lg" class="text-pink" />
        </Popper>
    </span>

    <span v-if="status === 'REMOVED'" class="isTooltipsLabel ms-1 me-1" :class="class">
        <Popper class="popperDark" arrow hover :content="status">
            <fa icon="circle-xmark" size="lg" class="text-orange" />
        </Popper>
    </span>

    <span v-if="status === 'STORED'" class="isTooltipsLabel ms-1 me-2" :class="class">
        <Popper class="popperDark" arrow hover :content="status">
            <fa icon="database" size="lg" class="text-brown" />
        </Popper>
    </span>

</template>

<script>
import { ref, onMounted } from 'vue'
import Popper from 'vue3-popper'

export default {
    name: 'DocStatus',
    components: { Popper },
    props: [ 'status', 'class' ],
    setup (props) {

        onMounted(() => {
            // console.info('Tag::onMounted', JSON.stringify(tags.value))
        })

        return {}
    }
}
</script>

<style>

</style>