<template>
    <!-- <img v-if="getAvatar" :src="getAvatar" class="rounded-circle me-2" :width="avatarWidth" :height="avatarHeight" /> -->
    <table>
        <tr>
            <td>
                <span v-if="isUser && avatarWidth === '30'" class="isLabel">
                    <fa v-if="avatarIsVerified === 'true'" icon="certificate" class="text-primary" style="z-index: 1000; position: absolute; font-size: 10px; margin-top: 18px; margin-left: 24px"/>
                    <img v-if="avatarUrl" :src="avatarUrl" class="rounded-circle" width="30" height="30" style="object-fit: cover"/>
                    <span v-else><fa icon="circle-user" style="width: 30px; height: 30px" class="navbar-display-name"/></span>
                </span>
                <span v-if="isUser && avatarWidth === '35'" class="isLabel">
                    <fa v-if="avatarIsVerified === 'true'" icon="certificate" class="text-primary" style="z-index: 1000; position: absolute; font-size: 10px; margin-top: 22px; margin-left: 28px"/>
                    <img v-if="avatarUrl" :src="avatarUrl" class="rounded-circle" width="35" height="35" style="object-fit: cover"/>
                    <span v-else><fa icon="circle-user" style="width: 35px; height: 35px" class="navbar-display-name"/></span>
                </span>
                <span v-if="isUser && avatarWidth === '50'" class="isLabel">
                    <fa v-if="avatarIsVerified" icon="certificate" class="text-primary" style="z-index: 1000; position: absolute; font-size: 10px; margin-top: 32px; margin-left: 40px"/>
                    <img v-if="avatarUrl" :src="avatarUrl" class="rounded-circle" width="50" height="50" style="object-fit: cover"/>
                    <span v-else><fa icon="circle-user" style="width: 50px; height: 50px" class="navbar-display-name"/></span>
                </span>
                <span v-if="isUser && avatarWidth === '60'" class="isLabel">
                    <fa v-if="avatarIsVerified" icon="certificate" class="text-primary" style="z-index: 1000; position: absolute; font-size: 10px; margin-top: 42px; margin-left: 50px"/>
                    <img v-if="avatarUrl" :src="avatarUrl" class="rounded-circle" width="60" height="60" style="object-fit: cover"/>
                    <span v-else><fa icon="circle-user" style="width: 60px; height: 60px" class="navbar-display-name"/></span>
                </span>

                <span v-if="isUser && avatarWidth === '120'" class="isLabel">
                    <fa v-if="avatarIsVerified" icon="certificate" class="text-primary" style="z-index: 1000; position: absolute; font-size: 25px; margin-top: 82px; margin-left: 95px"/>
                    <img v-if="avatarUrl" :src="avatarUrl" class="rounded-circle" width="120" height="120" style="object-fit: cover"/>
                    <span v-else><fa icon="circle-user" style="width: 120px; height: 120px" class="navbar-display-name"/></span>
                </span>

                <span v-if="!isUser" class="isLabel">
                    <span v-if="avatarWidth === '30'">
                        <Popper class="popperDark" arrow hover content="Non member">
                            <fa icon="user-xmark" style="width: 30px; height: 30px" class="navbar-display-name isTooltipsLabel"/>
                        </Popper>
                    </span>
                    <span v-if="avatarWidth === '35'">
                        <Popper class="popperDark" arrow hover content="Non member">
                            <fa icon="user-xmark" style="width: 35px; height: 35px" class="navbar-display-name isTooltipsLabel"/>
                        </Popper>
                    </span>
                    <span v-if="avatarWidth === '50'">
                        <Popper class="popperDark" arrow hover content="Non member">
                            <fa icon="user-xmark" style="width: 50px; height: 50px" class="navbar-display-name isTooltipsLabel"/>
                        </Popper>
                    </span>
                    <span v-if="avatarWidth === '60'">
                        <Popper class="popperDark" arrow hover content="Non member">
                            <fa icon="user-xmark" style="width: 60px; height: 60px" class="navbar-display-name isTooltipsLabel"/>
                        </Popper>
                    </span>

                    <span v-if="avatarWidth === '120'" class="border">
                        <Popper class="popperDark" arrow hover content="Non member">
                            <fa icon="user-xmark" style="width: 120px; height: 120px" class="navbar-display-name isTooltipsLabel"/>..
                        </Popper>
                    </span>

                </span>
            </td>
            <td class="align-middle ps-1" :class="avatarClass" style="font-size: 14px">
                {{avatarDisplayName}}
            </td>
        </tr>
    </table>

</template>

<script>
import {ref, onMounted} from 'vue'
import Popper from 'vue3-popper'

export default {
    name: 'Avatar',
    components: { Popper },
    props: [ 'url', 'firstName', 'lastName', 'displayName', 'class', 'width', 'height', 'isUser', 'avatar', 'isVerified' ],
    setup (props) {

        const avatarUrl = ref(props.avatar)
        const avatarDisplayName = ref(props.displayName)
        const avatarIsUser = ref(props.isUser)
        const avatarIsVerified = ref(props.isVerified)

        const avatarWidth = ref(props.width)
        const avatarHeight = ref(props.height)
        const avatarClass = ref(props.class)

        const marginTop = ref(22)
        const marginLeft = ref(28)

        onMounted(() => {
            if (avatarWidth.value === '' || avatarWidth.value === undefined) {
                avatarWidth.value = '35'
            }

            if (avatarHeight.value === '' || avatarHeight.value === undefined) {
                avatarHeight.value = '35'
            }

            /*
            if (avatarWidth.value === '50') {
                marginTop.value = 32
                marginLeft.value = 40
            }

            if (avatarWidth.value === '60') {
                marginTop.value = 42
                marginLeft.value = 50
            } */

            console.info('margin', marginTop.value, marginLeft.value)
        })

        return {
            avatarIsUser, avatarUrl, avatarDisplayName, avatarIsVerified, avatarClass, avatarWidth, avatarHeight,
            marginTop, marginLeft
        }
    }
}
</script>

<style>

</style>