<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="KYC Verification" icon="address-card" />

    <div class="mainBoxBorderless mainBox60 mb-0">
        <div class="row">
            <div class="col-12 col-lg-5 pb-3">
                <div class="input-group">
                    <span class="input-group-text"><fa icon="search" /></span>
                    <input type="text" v-model="filterName" class="form-control" placeholder="Name / Email">
                    <span v-if="filterName && filterName.length > 0" class="input-group-text clearSearch" @click="delfilterName">
                        <fa icon="xmark" />
                    </span>
                </div>
            </div>

            <!-- Large screen -->
            <div class="col-12 col-lg-7 text-primary text-end isLink mb-3 d-none d-md-block pt-2">
                <span class="filter-pill-border me-2">
                    <span class="filter-item-pill" :class="filterStatus === '' ? 'active' : ''" @click="filterStatus = ''">All</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'PENDING' ? 'active' : ''" @click="filterStatus = 'PENDING'">PENDING</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'PROCESS' ? 'active' : ''" @click="filterStatus = 'PROCESS'">PROCESS</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'VERIFIED' ? 'active' : ''" @click="filterStatus = 'VERIFIED'">VERIFIED</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'REJECTED' ? 'active' : ''" @click="filterStatus = 'REJECTED'">REJECTED</span>
                </span>
            </div>

            <!-- Small screen -->
            <div class="col-12 col-lg-7 text-primary isLink mb-3 d-md-none">
                <span class="filter-pill-border">
                    <span class="filter-item-pill" :class="filterStatus === '' ? 'active' : ''" @click="filterStatus = ''">All</span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'PENDING' ? 'active' : ''" @click="filterStatus = 'PENDING'"><fa icon="user-clock" /></span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'PROCESS' ? 'active' : ''" @click="filterStatus = 'PROCESS'"><fa icon="user-gear" /></span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'VERIFIED' ? 'active' : ''" @click="filterStatus = 'VERIFIED'"><fa icon="user-shield" /></span>
                    <div class="vr"></div>
                    <span class="filter-item-pill" :class="filterStatus === 'REJECTED' ? 'active' : ''" @click="filterStatus = 'REJECTED'"><fa icon="user-minus" /></span>
                </span>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-lg-4 mb-2">
                <table>
                    <tr>
                        <td class="pe-2">From</td>
                        <td><Datepicker v-model="dateFr" :enableTimePicker="false" position="left" format="yyyy-MM-dd" autoApply></Datepicker></td>
                    </tr>
                </table>
            </div>
            <div class="col-lg-4">
                <table>
                    <tr>
                        <td class="pe-2">To</td>
                        <td><Datepicker v-model="dateTo" :enableTimePicker="false" position="left" format="yyyy-MM-dd" autoApply></Datepicker></td>
                    </tr>
                </table>
            </div>
            <div class="col-lg-4 text-end pt-2 pe-3">
                <span class="text-primary isLink" @click="$router.push({ path: '/backoffice/kyc/report' })">
                    <fa icon="chart-line" class="me-2"/>KYC Report
                </span>
            </div>
            
        </div>

    </div>

    <div class="nextBox nextBox60 border shadow-sm mt-0">
        <div class="row">
            <div class="col-12 px-1" ref="scrollComponent">
                <div v-if="isLoading" class="mb-3 text-center">
                    <div class="spinner-border spinner-border-sm me-1"></div> Loading... 
                </div>
                <div v-if="!isLoading" class="mb-3 text-end small fst-italic">100 of 10,000</div>
 
                <div class="mb-3" v-if="checkedUser && checkedUser.length > 0">
                    <span class="mx-2 text-grey isLink">
                        <fa icon="user-shield" size="lg" style="padding-left: 9px" />
                    </span>
                    <span class="float-end text-grey fw-bold">{{checkedUser.length}} selected</span>
                </div>

                <table class="table table-hover" border="0">
                    <tbody>
                        <tr v-for="(item, itemIdx) in arrItem" :key="item">
                            
                            <td width="50px" class="text-center">
                                <Avatar :key="itemIdx" isUser="true" avatar="" displayName="" :isVerified="item.status === 'VERIFIED' ? 'true' : 'false'" width="30" height="30" />
                            </td>
                           
                            <td class="align-middle">
                                <div class="row">
                                    <div class="col-lg-4 fw-bold">
                                        <div class="isLink">
                                            <router-link :to="'/backoffice/kyc/' + item.userId">
                                                <span>{{item.lastName}} {{item.firstName}}</span>
                                            </router-link>
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-1 small text-grey">
                                        <span v-if="item.gender === 'M'">Male</span>
                                        <span v-if="item.gender === 'F'">Female</span>
                                    </div>
                                    <div class="col-lg-3 small text-grey">{{ item.userId }}</div>
                                    <div class="col-lg-2 small text-grey">{{ func.getDateTimeDiff(func.convDateTimeFormat(item.dateModify), new Date()) }}</div>
                                    <div class="col-lg-2 text-grey small text-end fst-italic">{{item.status}}</div>
                                    
                                </div>
                            </td>
                        </tr>

                        <tr v-if="isLoadingMore" class="mt-3 text-center">
                            <td colSpan="2">
                                <div class="spinner-border spinner-border-sm me-1"></div> Loading... 00
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import Avatar from '@/components/Avatar.vue'

import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'

export default {
    name: 'BOKycList',
    components: { Alert, TopNavigation, Popper, Avatar, Datepicker },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const scrollComponent = ref(null)
        const arrItem = ref([])
        const total = ref(0)
        const rcdFr = ref(0) // record index from
        const rcdDisplay = ref(15) // total record to display, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const dateFr = ref(new Date())
        const dateTo = ref(new Date())

        const clsAvatar = ref('d-block')
        const clsCheckbox = ref('d-none')
        const checkedUser = ref([])

        const filterName = ref('')
        const filterGender = ref('')
        const filterStatus = ref('')    // PENDING, PROCESS, VERIFIED, REJECTED

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const delfilterName = () => {
            arrItem.value = []
            filterName.value = ''
            getList(null)
        }

        const handleScroll = (e) => {
            
            let element = scrollComponent.value
            if (element !== null) {
                console.info('---- handleScroll', element.getBoundingClientRect().bottom.toFixed(0), window.innerHeight)
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    isLoadingMore.value = true
                    loadMore()
                }
            }
        }

        const getTotal = () => {

            total.value = 8
            /* axios.post('/report/dash/signon/signed', {})
            .then((res) => {

                if (res.data.status === 1) {
                    total.value = res.data.data.length
                } 
            })
            .catch((error) => {
                console.log('getTotal | ERR', JSON.stringify(error))
            }) */
        }

        const getList = (loadMore) => {

            isLoading.value = false
            isLoadingMore.value = false

            arrItem.value = [
                {
                    userId: 'alex.hang@yahoo.com',
                    firstName: 'Kar Meng',
                    lastName: 'Hang',
                    nickname: 'Alex.Hang',
                    avatar: '',
                    isVerified: false,
                    tags: ['colleague', 'development'],
                    dateModify: '2022-08-23T03:57:00.000Z',
                    gender: 'M',
                    status: 'PENDING'
                },
                {
                    userId: 'mohamad1234@yahoo.com',
                    firstName: 'Mohamad bin Abdullah',
                    lastName: '',
                    nickname: 'Mohamad',
                    avatar: '',
                    isVerified: true,
                    tags: ['colleague', 'cs'],
                    dateModify: '2022-08-22T09:57:01.000Z',
                    gender: 'M',
                    status: 'PENDING'
                },
                {
                    userId: 'grace@gmail.com',
                    firstName: 'Shu Min',
                    lastName: 'Tan',
                    nickname: 'Grace',
                    avatar: '',
                    isVerified: false,
                    tags: [],
                    dateModify: '2022-08-20T09:57:01.000Z',
                    gender: 'F',
                    status: 'SUBMITTED'
                },
                {
                    userId: 'kelly@gmail.com',
                    firstName: 'Hai Yan',
                    lastName: 'Kek',
                    nickname: 'Kelly Kek',
                    avatar: '',
                    isVerified: false,
                    tags: ['mcis'],
                    dateModify: '2022-08-18T09:57:01.000Z',
                    gender: 'F',
                    status: 'VERIFIED'
                },
                {
                    userId: 'jason.chan@yahoo.com',
                    firstName: 'Chun Kiat',
                    lastName: 'Lim',
                    nickname: 'Jason',
                    avatar: '',
                    isVerified: false,
                    tags: ['unifi'],
                    dateModify: '2022-08-12T09:57:01.000Z',
                    gender: 'M',
                    status: 'REJECTED'
                },
                {
                    userId: 'joey1980@gmail.com',
                    firstName: 'Joey',
                    lastName: 'Tan',
                    nickname: 'Joey80',
                    avatar: '',
                    isVerified: false,
                    tags: ['mcis', 'management'],
                    dateModify: '2022-08-05T09:57:01.000Z',
                    gender: 'F',
                    status: 'SUBMITTED'
                },
                {
                    userId: 'patrick@gmail.com',
                    firstName: 'Xin Zhan',
                    lastName: 'Teoh',
                    nickname: 'Patrick',
                    avatar: '',
                    isVerified: false,
                    tags: ['colleague', 'support'],
                    dateModify: '2022-07-28T02:00:01.000Z',
                    gender: 'M',
                    status: 'REJECTED'
                },
                {
                    userId: 'yennee.lim@genusis.com',
                    firstName: 'Yen Nee',
                    lastName: 'Lim',
                    nickname: 'YNLim',
                    avatar: '',
                    isVerified: true,
                    tags: ['colleague', 'development'],
                    dateModify: '2022-07-15T02:00:01.000Z',
                    gender: 'F',
                    status: 'SUBMITTED'
                },

            ]
            /* const p = {
                orderBy: [
                    {
                        field: 'dateModify',
                        order: 'desc'
                    }
                ],
                limit: rcdFr.value + ',' + rcdDisplay.value
            }

            console.info('getSig params', JSON.stringify(p))
            axios.post( '/report/dash/signon/signed', p
            ).then((res) => {

                if (loadMore) {
                    console.info('load more here')
                    isLoadingMore.value = false

                } else {
                    console.info('x load more here')
                    isLoading.value = false
                }

                if (res.data.status === 1) {
                   if (res.data.data.length > 0) {
                        arrItem.value.push(...res.data.data)
                    }
                } 
            })
            .catch((error) => {
                console.log('getList ERR', JSON.stringify(error))
            }) */
        }

        const loadMore = () => {
            if (rcdFr.value < total.value) {
                rcdFr.value = rcdFr.value + rcdDisplay.value
                getList(true)

            } else {
                isLoadingMore.value = false
            }
        }

        onMounted(() => {
            window.addEventListener("scroll", handleScroll)

            if (route.query.status) {
                filterStatus.value = route.query.status
            }

            if (route.query.fr) {
                if (route.query.fr.length === 4) {
                    dateFr.value = route.query.fr + '-01-01'

                } else if (route.query.fr.length === 7) {
                    dateFr.value = route.query.fr + '-01'

                } else {
                    dateFr.value = route.query.fr
                }

            }

            if (route.query.to) {
                if (route.query.to.length === 4) {
                    dateTo.value = route.query.to + '-12-31'

                } else if (route.query.to.length === 7) {
                    let tmpYr = route.query.to.toString().substr(0, 4)
                    let tmpMon = route.query.to.toString().substr(5, 2) * 1// 0 - jan, 1 - feb
                    let tmpLastDay = new Date(tmpYr, tmpMon, 0).getDate()  
                    if (tmpLastDay.length === 1) {
                        tmpLastDay = '0' + tmpLastDay
                    }
                    dateTo.value = tmpYr + '-' + tmpMon + '-' + tmpLastDay

                } else {
                    dateTo.value = route.query.to
                }
            }

            rcdFr.value = 0
            
            getTotal()
            getList(false)
        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
           
        })

        return {
            alert, closeAlert, route, router, func, store, 
            total, clsAvatar, clsCheckbox, checkedUser, 
            arrItem, rcdFr, rcdDisplay, isLoading, isLoadingMore, filterName, delfilterName, 
            filterGender, filterStatus, dateFr, dateTo, 
        }
    }
}
</script>

<style>

.input-group-text {
    background-color: var(--bs-primary);
    color: var(--bgcolor-body-color);
}

.filter-pill-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.filter-item-pill {
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
}

.filter-item-pill:hover {
    color: var(--bs-primary);
}

.filter-item-pill:active, .filter-item-pill.active {
    color: var(--bgcolor-body-color);
    background-color: #53BDEB;
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.filter-item-pill:disabled, .filter-item-pill.disabled {
    color: var(--toolbar-hr);
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.clearSearch {
  cursor: pointer; 
  color: #53BDEB;
  background-color: var(--bgcolor-body-color); 
  border-color: var(--accent-color)
}
</style>