<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Verify Doc" icon="check-double" />
    <div class="mainBox mainBox55 border shadow-sm">
        <div v-if="!showDetail" class="row">
            <div class="col-12">
                <div class="input-group mb-3">
                    <input type="text" v-model="docId" class="form-control" placeholder="Document ID"/>
                    <button class="btn btn-secondary" type="button" id="button-addon2" @click="searchDocById"><fa icon="search" /></button>
                </div>
            </div>

            <div class="col-12">
                <hr class="my-4"/>
            </div>

            <div class="col-12">
                <div>Use webcam to scan QR Code</div>
                <div class="pt-2 text-center">
                    <fa icon="qrcode" style="font-size: 100px" />
                </div>
            </div>

            <div class="col-12">
                <hr class="my-4"/>
            </div>

            <div class="col-12 d-grid">
                <div class="mb-2">Use mobile app to scan QR Code</div>
                <button class="btn btn-secondary btn-block" type="button">Scan</button>
            </div>
        </div>

        <div v-if="showDetail" class="row">

            <div class="col-12 col-lg-12 mb-2">
                <div>
                    
                    <table width="100%" border="0">
                        <tr>
                            <td width="50px" class="text-center"><div class="label">Creator</div></td>
                            <td></td></tr>
                        <tr>
                            <td rowSpan="2">
                                <Avatar :isUser="true" :avatar="doc.creator.avatar" displayName="" isVerified="true" class="fw-bold"/>
                            </td>
                            <td class="ps-2">{{doc.creator.displayName}}</td>
                        </tr>
                        <tr>
                            <td class="ps-2">{{ func.convDateTimeFormat(doc.dateCreate) }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="col-12">
                <hr />
            </div>

            <div class="col-12 col-lg-10 mt-2 mb-3">
                <div class="fw-bold"> 
                    <!-- <span class="isLink" @click.prevent="router.push('/pdf/view/' + doc.projectId + '/' + doc.documentId)">{{ doc.file.name }}</span> -->
                    <a :href="'/pdf/view/' + doc.projectId + '/' + doc.documentId" class="isLink" target="_blank" >{{ doc.file.name.replace('.pdf', '') }}</a>
                </div>
            </div>

            <div class="col-12 col-lg-2 mb-3 text-end">
                <!-- Small screen -->
                <div v-if="isCreator" class="text-end d-block d-md-none">
                    <button type="button" class="btn btn-secondary" v-if="isPrivate" @click="updIsPrivate(false)">
                        <fa icon="eye-slash" /> Private
                    </button>
                    <button type="button" class="btn btn-secondary active" v-if="!isPrivate" @click="updIsPrivate(true)">
                        <fa icon="eye" /> Public
                    </button>
                </div>

                <!-- Large screen -->
                <div v-if="isCreator" class="text-end d-none d-md-block">
                    <Popper class="popperDark" arrow hover content="Change to public">
                        <button type="button" class="btn btn-secondary" v-if="isPrivate" @click="updIsPrivate(false)">
                            <fa icon="eye-slash" /> Private
                        </button>
                    </Popper>
                    <Popper class="popperDark" arrow hover content="Change to private">
                        <button type="button" class="btn btn-secondary active" v-if="!isPrivate" @click="updIsPrivate(true)">
                            <fa icon="eye" /> Public
                        </button>
                    </Popper>
                </div>

                <div v-if="!isCreator" class="text-end">
                    <span v-if="isPrivate"><fa icon="eye-slash" size="lg"/> Private</span>
                    <span v-else><fa icon="eye" size="lg"/> Public</span>
                </div>
            </div>

            <div class="col-12 col-lg-6 mb-3">
                <div class="label">Number of pages</div> 
                <div>{{doc.file.totalPage}}</div>
            </div>

            <div class="col-12 col-lg-6 mb-3">
                <div class="label">Size</div> 
                <div>{{ func.convSize(doc.file.size) }}</div>
            </div>


            <div class="col-12 col-lg-6 mb-3">
                <div class="label">Completion date</div> 
                <div>
                    <Popper class="popperDark" arrow hover :content="doc.status">
                        <span v-if="doc.status === 'DRAFT'"><fa icon="file" size="lg" class="text-success"/> {{doc.status}}</span>
                    </Popper>
                    <Popper class="popperDark" arrow hover :content="doc.status">
                        <span v-if="doc.status === 'INVITED'"><fa icon="envelope" size="lg" class="text-purple"/> {{doc.status}}</span>
                    </Popper>
                    <Popper class="popperDark" arrow hover :content="doc.status">
                        <span v-if="doc.status === 'SIGNING'" ><fa icon="signature" size="lg" class="text-info"/> {{doc.status}}</span>
                    </Popper>
                    <Popper class="popperDark" arrow hover :content="doc.status">
                        <span v-if="doc.status === 'ALLSIGNED'" ><fa icon="signature" size="lg" class="text-pink"/> {{doc.status}}</span>
                    </Popper>
                    <Popper class="popperDark" arrow hover :content="doc.status">
                        <span v-if="doc.status === 'REMOVED'"><fa icon="times" size="lg" class="text-orange"/> {{doc.status}}</span>
                    </Popper>
                    <Popper class="popperDark" arrow hover :content="doc.status">
                        <span v-if="doc.status === 'STORED'"><fa icon="database" size="lg" class="text-brown"/> {{doc.status}}</span>
                    </Popper>
                    <span v-if="doc.status === 'STORED'" class="ms-1">
                        {{ func.convDateTimeFormat(doc.dateModify) }}
                    </span>
                </div>
            </div>

            <div class="col-12 col-lg-6 mb-3">
                <div class="label">Number of signee</div> 
                <div>
                    <!-- <span v-if="doc.isPrivate" >{{ signees.length }}</span>
                    <span v-else>
                        <Popper class="popperDark" arrow hover>
                            <span class="isLink">{{ signees.length }}</span>
                            <template #content >
                                <div v-for="s in signees" :key="s" class="text-start">{{ s.name }}</div>
                            </template>
                        </Popper>
                    </span> -->

                    <span>
                        <Popper class="popperDark" arrow hover>
                            <span class="isLink">{{ signees.length }}</span>
                            <template #content >
                                <div v-for="s in signees" :key="s" class="text-start">{{ isPrivate ? s.name.replaceAll(/[0-9a-zA-Z]/gi, '*') : s.name }}</div>
                            </template>
                        </Popper>
                    </span>
                </div>
            </div>

            <div class="col-12 col-lg-6 mb-3">
                <div class="label">Number of viewer</div> 
                <div>
                    <span>
                        <span v-if="viewers.length === 0" >0</span>
                        <Popper v-else class="popperDark" arrow hover>
                            <span class="isLink">{{ viewers.length }}</span>
                            <template #content >
                                <div v-for="v in viewers" :key="v" class="text-start">{{ isPrivate ? v.name.replaceAll(/[0-9a-zA-Z]/gi, '*') : v.name }}</div>
                            </template>
                        </Popper>
                    </span>
                </div>
            </div>

            <div class="col-12">
                <hr class="my-3"/>
            </div>

            <div class="col-12 text-center">
                <button type="button" class="btn btn-secondary active" @click="docId = ''; showDetail = false">
                    <fa icon="circle-arrow-left" size="lg" class="me-2" />Back
                </button>
            </div>


        </div>

    </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import Avatar from '@/components/Avatar.vue'

export default {
    name: 'DocVerify',
    components: {
        Alert, TopNavigation, Popper, Avatar
    },
    setup() {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const docId = ref(null)
        // const docId = ref('F8PsD8eGr7kHGaUG6Iams')
        const showDetail = ref(false) // false
        const doc = ref({})
        const isCreator = ref(true) // false
        const isPrivate = ref(true) // false
        const signees = ref([])
        const viewers = ref([])
        

        const searchDocById = async () => {
            if (docId.value === null || docId.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Please enter document ID.'
                })

            } else {
                await axios.get( '/signon/signee/document/' + docId.value)
                    .then((res) => {
                        // console.info('searchDocById ', JSON.stringify(res.data.data))
                        console.info('searchDocById signees', JSON.stringify(res.data.data.signees))
                        // console.info('searchDocById signee', JSON.stringify(res.data.data.signee))

                        if (res.data.status === 1) {
                        showDetail.value = true
                        doc.value = res.data.data
                        isPrivate.value = res.data.data.isPrivate

                        if (res.data.data.signee.privileges && res.data.data.signee.privileges.includes('creator') !== undefined) {
                            isCreator.value = res.data.data.signee.privileges.includes('creator')
                        }

                        if (res.data.data.signees) {
                            res.data.data.signees.forEach((item, idx) => {
                                //    console.info('item', JSON.stringify(item))
                                if (item.privileges) {
                                    console.info('-- privileges', JSON.stringify(item))
                                    //    console.info('item', item.privileges)
                                    if (item.privileges.includes('signee')) {
                                        signees.value.push(item)
                                    }
                                    if (item.privileges.includes('viewer')) {
                                        viewers.value.push(item)
                                    }
                                } else {
                                    console.info('x privileges', JSON.stringify(item))
                                    viewers.value.push(item)
                                }
                            })

                            // console.info('signees', JSON.stringify(signees.value))
                            // console.info('viewers', JSON.stringify(viewers.value))
                        }
                        } 
                    })
                    .catch((error) => {
                        if (error.status === 'record_not_found') {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: 'Record not found.'
                            })

                        } else {

                            console.log('FAILURE', error)
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.convError(error)
                            })
                        }
                    })
            }
        }

        const updIsPrivate = (val) => {
            
            const p = {
                isPrivate: val
            }

            axios.put( '/signon/' + doc.value.projectId + '/' + docId.value, p)
                .then((res) => {

                    if (res.data.status === 1) {
                        isPrivate.value = val // after update axios success

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document is updated to " + (val ? "private." : "public.")
                        })
                    } 
                })
                .catch((error) => {

                    console.log('FAILURE', error)
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.convError(error)
                    })
                })
        }

        onMounted(() => {
            func.clearPrevLocalStorage()

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }
        })

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        return { 
            alert, closeAlert, router, func, docId, searchDocById, showDetail, doc, 
            signees, viewers,
            isCreator, isPrivate, updIsPrivate 
        }
    }
}
</script>

<style>
.label {
    font-size: 12px;
    color: #A0A3A6;
    word-wrap: break-word;
}
</style>