<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Subscriber" icon="cart-plus" />

    <div class="mainBoxBorderless mainBox62">
        <div class="row">
            <div class="col-12 col-lg-4 pb-1">
                <div class="input-group">
                    <span class="input-group-text"><fa icon="search" /></span>
                    <input type="text" v-model="filterName" class="form-control" placeholder="Subscriber Name">
                    <span v-if="filterName && filterName.length > 0" class="input-group-text clearSearch" @click="delfilterName">
                        <fa icon="xmark" />
                    </span>
                </div>
            </div>

            <div class="col-12 col-lg-3 pb-1">
                <div class="input-group">
                    <span class="input-group-text"><fa icon="users" /></span>
                    <select v-model="filterTeam" class="form-select" @change="changeTeam">
                        <option value="">All Team</option>
                        <option :value="t.teamId" v-for="t in arrTeam" :key="t.teamId">{{ t.teamName }}</option>
                    </select>
                </div>
            </div>

            <div class="col-12 col-lg-5 text-end text-primary isLink">
                <div class="d-flex flex-wrap justify-content-between">
                <!-- <div class="d-flex justify-content-end bd-highlight"> -->
                    <span class="filter-pill-border me-2">
                        <span class="filter-item-pill" :class="filterStatus === '' ? 'active' : ''" @click="filterStatus = ''">All</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'active' ? 'active' : ''" @click="filterStatus = 'active'">Active</span>
                        <div class="vr"></div>
                        <span class="filter-item-pill" :class="filterStatus === 'inactive' ? 'active' : ''" @click="filterStatus = 'inactive'">Inactive</span>
                    </span>

                    <span class="filter-pill-border me-2">
                        <!-- <span v-if="filterStorageFull" class="filter-item-storage" @click="filterStorageFull = !filterStorageFull"> -->
                        <span :class="filterStorageFull ? 'active' : 'disabled'" class="filter-item-pill"  @click="filterStorageFull = !filterStorageFull">
                            <Popper class="popperDark" arrow hover content="High storage usage (> 80%)">
                                <fa icon="database"/>
                            </Popper>
                        </span>
                        
                        <!-- <span v-else class="filter-item-storage" @click="filterStorageFull = !filterStorageFull"> -->
                        <!-- <span v-else class="filter-item-pill" :class="filterStorageFull ? '' : 'active'" @click="filterStorageFull = !filterStorageFull">
                            <Popper class="popperDark" arrow hover content="High storage usage (> 80%)">
                                <fa icon="database"/>
                            </Popper>
                        </span> -->

                    </span>

                    <span class="filter-border">
                        <fa v-if="totalFilter === 0" icon="times" class="me-2" />
                        <span v-else class="badge bg-primary rounded-pill me-1">{{totalFilter}}</span>
                        <span class="filter-item" @click="resetSearch">Clear</span>
                    </span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-end">
                <span class="text-primary isLink" @click="$router.push({ path: '/backoffice/subscriber/report' })">
                    <fa icon="chart-line" class="me-2"/> Subscriber Report
                </span>
            </div>
        </div>
    </div>

    <div class="nextBox nextBox62 border shadow-sm mt-0">
        <div class="row">
            <div class="col-12 px-1" ref="scrollComponent">
                <div v-if="isLoading" class="mb-3 text-center">
                    <div class="spinner-border spinner-border-sm me-1"></div> Loading...
                </div>

                <div v-if="!isLoading" class="mb-3 text-end small fst-italic">100 of 10,000</div>
 
                <div class="mb-3" v-if="checkedUser && checkedUser.length > 0">
                    <span class="mx-2 text-grey isLink"><fa icon="trash" size="lg" style="padding-left: 9px" /></span>
                    <span class="float-end text-grey fw-bold">{{checkedUser.length}} selected</span>
                </div>

                <table class="table table-hover" border="0">
                    <tbody>
                        <tr v-for="(item, itemIdx) in arrItem" :key="item">
                            
                            <td width="50px" class="text-center">
                                <!-- Large screen -->
                                <div class="d-none d-md-block">
                                    <div v-if="checkedUser.includes(itemIdx)" style="font-size: 16px; margin-left: -5px; padding-top: 6px; padding-bottom: 6px">
                                        <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="itemIdx" />
                                    </div>
                                    <div v-else>
                                        <span class="hoverHide" :id="'avatar' + itemIdx">
                                            <Avatar :key="itemIdx" isUser="true" avatar="" displayName="" :isVerified="item.isVerified" width="30" height="30" />
                                        </span>
                                        <span class="hoverShow" style="z-index:1000; font-size: 15px; margin-top: -30px; margin-left: -10px; position:absolute;">
                                            <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="itemIdx" />
                                        </span>
                                    </div>
                                </div>
                                <!-- End Large screen -->

                                <!-- Small screen -->
                                <div class="d-block d-md-none">
                                    <span class="hoverHide" :id="'avatar' + itemIdx">
                                        <Avatar :key="itemIdx" isUser="true" avatar="" displayName="" :isVerified="item.isVerified" width="30" height="30" />
                                    </span>
                                </div>
                                <!-- End Small screen -->
                            </td>
                           
                            <td class="align-middle">
                                <div class="row">
                                    <div class="col-lg-4 fw-bold">
                                        <div class="isLink">
                                            <router-link :to="'/backoffice/subscriber/' + item.userId">
                                                <span>{{item.lastName}} {{item.firstName}}</span>
                                            </router-link>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 small text-grey">{{ item.userId }}</div>
                                    <div class="col-2 col-lg-1 small text-grey">
                                        <span v-if="item.status === 'active'">
                                            <Popper class="popperDark" arrow hover content="Active">
                                                <fa icon="circle-check" style="font-size: 17px" class="text-secondary"/>
                                            </Popper>
                                        </span>
                                        <span v-else>
                                            <Popper class="popperDark" arrow hover content="Inactive">
                                                <fa icon="circle-xmark" style="font-size: 17px; color: var(--accent-color)" />
                                            </Popper>
                                        </span>
                                        
                                    </div>
                                    <div class="col-5 col-lg-1 small text-grey">
                                        <span class="badge bg-secondary">{{item.package}}</span>
                                    </div>
                                    <div class="col-5 col-lg-2 small text-grey text-end">{{item.storage.limit}} ({{item.storage.usedPercentage}}%)</div>
                                    <div class="col-lg-1 text-end d-none d-md-block hoverShow">
                                        <span class="text-grey isLink me-2">
                                            <Popper class="popperDark" arrow hover content="Delete">
                                                <fa icon="trash" size="lg" />
                                            </Popper>
                                        </span>
                                    </div>
                                    <!-- Small screen -->
                                    <div class="col-12 text-end d-block d-md-none pt-2">
                                        <span class="text-grey isLink me-4">
                                            <fa icon="trash" size="lg" />
                                        </span>
                                        <span>
                                            <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="itemIdx" />
                                        </span>
                                    </div>
                                    <!-- End Small screen -->
                                </div>
                            </td>
                        </tr>

                        <tr v-if="isLoadingMore" class="mt-3 text-center">
                            <td colSpan="2">
                                <div class="spinner-border spinner-border-sm me-1"></div> Loading...
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>

</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import Avatar from '@/components/Avatar.vue'

export default {
    name: 'BOSubscriber',
    components: { Alert, TopNavigation, Popper, Avatar },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const scrollComponent = ref(null)
        const arrItem = ref([])
        const total = ref(0)
        const rcdFr = ref(0) // record index from
        const rcdDisplay = ref(15) // total record to display, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const clsAvatar = ref('d-block')
        const clsCheckbox = ref('d-none')
        const checkedUser = ref([])
        
        const totalFilter = ref(0)
        const filterName = ref('')
        const filterTeam = ref('')
        const filterStatus = ref('')
        const filterStorageFull = ref(false)
        const arrTeam = ref([])

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const handleScroll = (e) => {
            
            let element = scrollComponent.value
            if (element !== null) {
                console.info('---- handleScroll', element.getBoundingClientRect().bottom.toFixed(0), window.innerHeight)
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    isLoadingMore.value = true
                    loadMore()
                }
            }
        }

        watch([filterStatus, filterTeam, filterStorageFull], () => {
            rcdFr.value = 0
            arrItem.value = []

            // console.info('WATCH', 'filterName:', filterName.value, 'filterStatus:', filterStatus.value, 'filterFav:', filterFav.value, typeof filterFav.value, 'filterMode:', filterMode.value, 'filterSignMode:', filterSignMode.value, 'filterPrivacy:', filterPrivacy.value)
            totalFilter.value = 0

            if (filterName.value !== '' && filterName.value.length > 2) { 
                totalFilter.value++
            }

            if (filterTeam.value !== '') {
                totalFilter.value++
            }

            if (filterStatus.value !== '') {
                totalFilter.value++
            }

            if (filterStorageFull.value === true) {
                totalFilter.value++
            }

            getList(false)
        })

        const resetSearch = () => {

            totalFilter.value = 0

            filterName.value = ''
            filterStatus.value = ''
            filterTeam.value = ''
            filterStorageFull.value = false
            
            rcdFr.value = 0
            arrItem.value = []
        }

        const getTotal = () => {
            total.value = 8
        }

        const getList = (loadMore) => {

            isLoading.value = false
            isLoadingMore.value = false

            arrItem.value = [
                {
                    userId: 'alex.hang@yahoo.com',
                    firstName: 'Kar Meng',
                    lastName: 'Hang',
                    nickname: 'Alex.Hang',
                    avatar: '',
                    isVerified: false,
                    tags: ['colleague', 'development'],
                    status: 'active',
                    package: 'personal',
                    storage: {
                        used: '155MB',
                        usedPercentage: '50',
                        limit: '1GB',
                        
                    }
                },
                {
                    userId: 'mohamad1234@yahoo.com',
                    firstName: 'Mohamad bin Abdullah',
                    lastName: '',
                    nickname: 'Mohamad',
                    avatar: '',
                    isVerified: true,
                    tags: ['colleague', 'cs'],
                    status: 'active',
                    package: 'personal',
                    storage: {
                        used: '530MB',
                        usedPercentage: '53',
                        limit: '1GB'
                    }
                },
                {
                    userId: 'grace@gmail.com',
                    firstName: 'Shu Min',
                    lastName: 'Tan',
                    nickname: 'Grace',
                    avatar: '',
                    isVerified: false,
                    tags: [],
                    status: 'inactive',
                    package: 'Free',
                    storage: {
                        used: '35MB',
                        usedPercentage: '35',
                        limit: '100MB'
                    }
                },
                {
                    userId: 'kelly@gmail.com',
                    firstName: 'Hai Yan',
                    lastName: 'Kek',
                    nickname: 'Kelly Kek',
                    avatar: '',
                    isVerified: false,
                    tags: ['mcis'],
                    status: 'inactive',
                    package: 'Free',
                    storage: {
                        used: '88MB',
                        usedPercentage: '88',
                        limit: '100MB'
                    }
                },
                {
                    userId: 'jason.chan@yahoo.com',
                    firstName: 'Chun Kiat',
                    lastName: 'Lim',
                    nickname: 'Jason',
                    avatar: '',
                    isVerified: false,
                    tags: ['unifi'],
                    status: 'active',
                    package: 'personal',
                    storage: {
                        used: '96MB',
                        usedPercentage: '96',
                        limit: '1GB'
                    }
                },
                {
                    userId: 'joey1980@gmail.com',
                    firstName: 'Joey',
                    lastName: 'Tan',
                    nickname: 'Joey80',
                    avatar: '',
                    isVerified: false,
                    tags: ['mcis', 'management'],
                    status: 'active',
                    package: 'personal',
                    storage: {
                        used: '843MB',
                        usedPercentage: '84',
                        limit: '1GB'
                    }
                },
                {
                    userId: 'patrick@gmail.com',
                    firstName: 'Xin Zhan',
                    lastName: 'Teoh',
                    nickname: 'Patrick',
                    avatar: '',
                    isVerified: false,
                    tags: ['colleague', 'support'],
                    status: 'active',
                    package: 'Free',
                    storage: {
                        used: '96MB',
                        usedPercentage: '96',
                        limit: '100MB'
                    }
                },
                {
                    userId: 'yennee.lim@genusis.com',
                    firstName: 'Yen Nee',
                    lastName: 'Lim',
                    nickname: 'YNLim',
                    avatar: '',
                    isVerified: true,
                    tags: ['colleague', 'development'],
                    status: 'active',
                    package: 'personal',
                    storage: {
                        used: '95MB',
                        usedPercentage: '95',
                        limit: '1GB'
                    }
                },

            ]

        }

        const loadMore = () => {
            if (rcdFr.value < total.value) {
                rcdFr.value = rcdFr.value + rcdDisplay.value
                getList(true)

            } else {
                isLoadingMore.value = false
            }
        }

        const delfilterName = () => {
            arrItem.value = []
            filterName.value = ''
            getList(null)
        }

        onMounted(() => {
            window.addEventListener("scroll", handleScroll)
            rcdFr.value = 0
            
            getTotal()
            getList(false)

            arrTeam.value = [
                {
                    teamId: '1001',
                    teamName: 'admin',
                    isDefault: true,
                    total: 1
                },
                {
                    teamId: '1002',
                    teamName: 'developer',
                    isDefault: false,
                    total: 35
                },
                {
                    teamId: '1003',
                    teamName: 'customer service',
                    isDefault: false,
                    total: 2
                }
            ]
        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
        })

        return {
            alert, closeAlert, route, router, func, store, 
            total, clsAvatar, clsCheckbox, checkedUser, 
            arrItem, rcdFr, rcdDisplay, isLoading, isLoadingMore, 
            totalFilter, filterStatus, filterName, filterTeam, filterStorageFull, resetSearch, delfilterName, arrTeam,
        }
    }
}
</script>

<style>
.table-hover > tbody > tr .hoverShow {
    visibility: hidden;
}

.table-hover > tbody > tr:hover .hoverShow {
    visibility: visible;
}

.table-hover > tbody > tr .hoverHide {
    visibility: visible;
}

.table-hover > tbody > tr:hover .hoverHide {
    visibility: hidden;
}

.input-group-text {
    background-color: var(--bs-primary);
    color: var(--bgcolor-body-color);
}

.filter-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 14px;
    padding: 7px 15px;
    margin-bottom: 10px;
}

.filter-pill-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.filter-item-pill {
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
}

.filter-item-pill:hover {
    color: var(--bs-primary);
}

.filter-item-pill:active, .filter-item-pill.active {
    color: var(--bgcolor-body-color);
    background-color: #53BDEB;
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.filter-item-pill:disabled, .filter-item-pill.disabled {
    color: var(--toolbar-hr);
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.filter-item-storage {
    cursor: pointer;
}

.filter-item-storage:hover {
    color: var(--bs-primary);
}

.clearSearch {
  cursor: pointer; 
  color: #53BDEB;
  background-color: var(--bgcolor-body-color); 
  border-color: var(--accent-color)
}
</style>