<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Dashboard :: Signed" icon="gauge" />

    <div class="mainBoxBorderless mainBox55">
        <div class="row px-2">
            <div class="col-12 bg-statistic p-2 rounded">
                <table width="100%" border="0">
                    <tr>
                        <td class="text-center"><span class="lblTiny">This month</span><div class="fw-bold">{{totalMonth}}</div></td>
                        <td class="text-center"><span class="lblTiny">This year</span><div class="fw-bold">{{totalYear}}</div></td>
                        <td class="text-center"><span class="lblTiny">To date</span><div class="fw-bold">{{totalTodate}}</div></td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div class="nextBox nextBox55 border shadow-sm">
        <div class="row">
            <div class="col-7 px-1 pb-3">
                <span class="me-2 small">Sort by</span>
                <span class="isLink">
                    <Popper class="popperDark" arrow hover content="Ascending">
                        <fa icon="circle-chevron-up" size="lg" class="text-grey me-2" />
                    </Popper>
                </span>
                <span class="isLink">
                    <Popper class="popperDark" arrow hover content="Descending">
                        <fa icon="circle-chevron-down" size="lg" />
                    </Popper>
                </span>
                <!-- <table width="100%">
                    <tr>
                        <td class="pe-2">Sort by</td>
                        <td>
                            <select v-model="sortDate" class="form-select form-select-sm" style="width: 140px">
                                <option value="">Date: Asc</option>
                                <option value="">Date: Desc</option>
                            </select>
                        </td>
                    </tr>
                </table> -->
            </div>
            <div class="col-5 px-1">
                <div class="mb-3 text-end small fst-italic">100 of 10,000</div>
            </div>
            <div class="col-12 px-1" ref="scrollComponent">
                <table class="table table-hover" border="0">
                    <tbody>
                        <tr v-if="isLoading" class="mt-3 text-center">
                            <td colSpan="2">
                                <div class="spinner-border spinner-border-sm me-1"></div> Loading...
                            </td>
                        </tr>

                        <tr v-for="(item, itemIdx) in arrItem" :key="item">
                            <td width="50px">
                                <Avatar :key="itemIdx" isUser="false" avatar="" displayName="" isVerified="true" width="30" height="30" />
                            </td>
                            <td>
                                <div>
                                    <span class="fw-bold me-3">{{item.documentId}}</span>
                                    <span class="text-grey"><fa icon="at" class="me-1"/>{{ item.userId }}</span>
                                </div>
                                <div>{{ item.description }}</div>
                                
                                <div class="small text-grey">{{ func.getDateTimeDiff(func.convDateTimeFormat(item.dateCreate), new Date()) }}</div>
                            </td>
                        </tr>

                        <tr v-if="isLoadingMore" class="mt-3 text-center">
                            <td colSpan="2">
                                <div class="spinner-border spinner-border-sm me-1"></div> Loading...
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted, onUnmounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import Avatar from '@/components/Avatar.vue'

export default {
    name: 'Signed',
    components: { Alert, TopNavigation, Popper, Avatar },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const scrollComponent = ref(null)
        const arrItem = ref([])
        const total = ref(0)
        const rcdFr = ref(0) // record index from
        const rcdDisplay = ref(15) // total record to display, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const totalMonth = ref(0)
        const totalYear = ref(0)
        const totalTodate = ref(0)

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const handleScroll = (e) => {
            
            let element = scrollComponent.value
            if (element !== null) {
                console.info('---- handleScroll', element.getBoundingClientRect().bottom.toFixed(0), window.innerHeight)
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    isLoadingMore.value = true
                    loadMore()
                }
            }
        }

        const getTotal = () => {
            axios.post('/report/dash/signon/signed', {})
            .then((res) => {

                if (res.data.status === 1) {
                    total.value = res.data.data.length
                } 
            })
            .catch((error) => {
                console.log('getTotal | ERR', JSON.stringify(error))
            })


        }

        const getStat = () => {
            const date = new Date()

            let today = func.convDateTimeFormat(new Date(), 'fulldatetime')

            let to = new Date(date.getFullYear(), date.getMonth()+1, 0)
            let monthEnd = func.convDateTimeFormat(to, 'date') + ' 23:59:59'

            let yearStart = date.getFullYear() + '-01-01 00:00:00'
            let yearEnd = date.getFullYear() + '-12-31 23:59:59'

            // This month
            const p = {
                dateFrom: today,
                dateTo: monthEnd
            }

            axios.post( '/report/dash/signon/signed', p
            ).then((res) => {

                if (res.data.status === 1) {
                    totalMonth.value = res.data.data.length
                } 
            })
            .catch((error) => {
                console.log('getStatMonth ERR', JSON.stringify(error))
            })

            // This year
            const p2 = {
                dateFrom: yearStart,
                dateTo: yearEnd
            }

            axios.post( '/report/dash/signon/signed', p2
            ).then((res) => {

                if (res.data.status === 1) {
                    totalYear.value = res.data.data.length
                } 
            })
            .catch((error) => {
                console.log('getStatYear ERR', JSON.stringify(error))
            })

            // To date

            axios.post( '/report/dash/signon/signed', {}
            ).then((res) => {

                if (res.data.status === 1) {
                    totalTodate.value = res.data.data.length
                } 
            })
            .catch((error) => {
                console.log('getStatTodate ERR', JSON.stringify(error))
            })

        }

        const getList = (loadMore) => {
            const p = {
                orderBy: [
                    {
                        field: 'dateCreate',
                        order: 'desc'
                    }
                ],
                limit: rcdFr.value + ',' + rcdDisplay.value
            }

            console.info('getSig params', JSON.stringify(p))
            axios.post( '/report/dash/signon/signed', p
            ).then((res) => {

                if (loadMore) {
                    console.info('load more here')
                    isLoadingMore.value = false

                } else {
                    console.info('x load more here')
                    isLoading.value = false
                }

                if (res.data.status === 1) {
                   if (res.data.data.length > 0) {
                        arrItem.value.push(...res.data.data)
                    }
                } 
            })
            .catch((error) => {
                console.log('getList ERR', JSON.stringify(error))
            })
        }

        const loadMore = () => {
            if (rcdFr.value < total.value) {
                rcdFr.value = rcdFr.value + rcdDisplay.value
                getList(true)

            } else {
                isLoadingMore.value = false
            }
        }

        onMounted(() => {
            window.addEventListener("scroll", handleScroll)

            rcdFr.value = 0

            getTotal()
            getStat()
            getList(false)
        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
        })

        return {
            alert, closeAlert, router, func, store, scrollComponent, 
            total, totalMonth, totalYear, totalTodate,
            arrItem, rcdFr, rcdDisplay, isLoading, isLoadingMore,
        }
    }
}
</script>

<style>

</style>