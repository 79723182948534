<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="API Integration" icon="diagram-project" />

    <div class="mainBox mainBox55 shadow-sm border">
        <div class="row">
            <div class="col-12 text-center">Coming soon...</div>
        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import funcs from '@/functions/function'

export default {
    name: 'ApiIntegration',
    components: {
        TopNavigation, Alert
    },
    setup () {
        const alert = ref([])

        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        onMounted(() => {

            func.clearPrevLocalStorage()

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }
        })

        return { 
            route, router, alert, closeAlert
        }

    }
}
</script>

<style>
</style>