<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Add Workflow" icon="sitemap" />

    <div class="mainBoxBorderless mainBox90">
        <div class="row">
            <div class="col-lg-5">
                <div class="workflowBox border shadow-sm">

                    <div class="row">
                        <!-- <div class="col-12 mb-2 bg-warning">{{arrItem.length}} | {{arrItem}}</div> -->
                        <!-- <div class="col-12 mb-2 bg-info">arrNodeLevel | {{arrNodeLevel.length}} | {{arrNodeLevel}}</div> -->

                        <div class="col-lg-8 pb-2 fw-bold">
                            <Popper class="popperDark" arrow hover content="Back to Workflow">
                                <fa icon="angle-left" size="lg" class="isLink me-3" @click="$router.push({ path: '/workflow' })" />
                            </Popper>
                            WorkFlow Testing Number One
                        </div>
                        <div class="col-lg-4 pb-3 text-end">
                            <a href="#" data-bs-toggle="modal" data-bs-target="#mdlAddStep" @click="isSubLevel = false">
                                <fa icon="plus" class="me-2 text-primary" /><span class="text-primary">Add step</span>
                            </a>
                        </div>

                        <div class="col-12 mb-2">
                            <table class="table table-hover">
                                <tbody>
                                    <tr v-for="(item, itemIdx) in arrItem" :key="item">
                                        <td width="65px">STEP {{itemIdx+1}}</td>
                                        <td>
                                            <div v-for="(i, iIdx) in item" :key="i">
                                                <!-- One level only-->
                                                <table v-if="iIdx === 0" width="100%">
                                                    <tr>
                                                        <td>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div :class="i.isRole ? 'badge bg-grey' : ''">{{i.name}}</div>
                                                                </div>
                                                                <!-- Small screen -->
                                                                <div class="col-12 d-md-none text-end mt-1 mb-2">
                                                                    <span>
                                                                        <Popper class="popperDark" arrow hover content="Delete step">
                                                                            <fa icon="trash" class="text-secondary me-4 isLink" @click="delStep(itemIdx, iIdx, i.node)" />
                                                                        </Popper>
                                                                    </span> 

                                                                    <span class="text-grey me-4 isLink" @click="selectConn(i)" data-bs-toggle="modal" data-bs-target="#mdlAddConnector">
                                                                        <Popper class="popperDark" arrow hover content="Set connector">
                                                                            <fa icon="share-nodes" size="lg" />
                                                                        </Popper>
                                                                    </span>
                                                                    
                                                                    <span @click="isSubLevel = true; upperLevel = itemIdx" data-bs-toggle="modal" data-bs-target="#mdlAddStep">
                                                                        <Popper class="popperDark" arrow hover content="Add more role/signee in this level">
                                                                            <fa icon="user-plus" class="text-secondary me-2 isLink" />
                                                                        </Popper>
                                                                    </span>
                                                                </div>
                                                                <!-- Small screen end-->
                                                            </div>
                                                        </td>
                                                        <td class="text-end hoverShow">
                                                            <div class="d-none d-md-block">
                                                                <span v-if="iIdx === item.length-1" class="text-grey me-4 isLink" :class="iIdx !== 0 ? 'pb-2' : ''" @click="isSubLevel = true; upperLevel = itemIdx" data-bs-toggle="modal" data-bs-target="#mdlAddStep">
                                                                    <Popper class="popperDark" arrow hover content="Add more role/signee in this level">
                                                                        <fa icon="user-plus" size="lg" />
                                                                    </Popper>
                                                                </span>

                                                                <span v-if="arrItem && arrItem.length > 1" class="text-grey me-4 isLink" @click="selectConn(i)" data-bs-toggle="modal" data-bs-target="#mdlAddConnector">
                                                                    <Popper class="popperDark" arrow hover content="Set connector">
                                                                        <fa icon="share-nodes" size="lg" />
                                                                    </Popper>
                                                                </span>

                                                                <span class="text-grey isLink me-2" @click="delStep(itemIdx, iIdx, i.node)">
                                                                    <Popper class="popperDark" arrow hover content="Delete step">
                                                                        <fa icon="trash" size="lg" />
                                                                    </Popper>
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- Multiple level / OR -->
                                                <table v-if="iIdx !== 0" width="100%">
                                                    <tr>
                                                        <td colSpan="2" class="text-grey">OR</td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div :class="i.isRole ? 'badge bg-grey' : ''">{{i.name}}</div>
                                                            <div class="d-md-none text-end mt-1 mb-2">
                                                                <span>
                                                                    <Popper class="popperDark" arrow hover content="Delete step">
                                                                        <fa icon="trash" class="text-secondary me-4 isLink" @click="delStep(itemIdx, iIdx, i.node)" />
                                                                    </Popper>
                                                                </span> 

                                                                <span class="text-grey me-4 isLink" @click="selectConn(i)" data-bs-toggle="modal" data-bs-target="#mdlAddConnector">
                                                                    <Popper class="popperDark" arrow hover content="Set connector">
                                                                        <fa icon="share-nodes" size="lg" />
                                                                    </Popper>
                                                                </span>
                                                                
                                                                <span @click="isSubLevel = true; upperLevel = itemIdx" data-bs-toggle="modal" data-bs-target="#mdlAddStep">
                                                                    <Popper class="popperDark" arrow hover content="Add more role/signee in this level">
                                                                        <fa icon="user-plus" class="text-secondary me-2 isLink" />
                                                                    </Popper>
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td class="text-end hoverShow">
                                                            <div class="d-none d-md-block">
                                                                <span v-if="iIdx === item.length-1" class="text-grey me-4 isLink" :class="iIdx !== 0 ? 'pb-2' : ''" @click="isSubLevel = true; upperLevel = itemIdx" data-bs-toggle="modal" data-bs-target="#mdlAddStep">
                                                                    <Popper class="popperDark" arrow hover content="Add more role/signee in this level">
                                                                        <fa icon="user-plus" size="lg" />
                                                                    </Popper>
                                                                </span>

                                                                <span v-if="arrItem && arrItem.length > 1" class="text-grey me-4 isLink" @click="selectConn(i)" data-bs-toggle="modal" data-bs-target="#mdlAddConnector">
                                                                    <Popper class="popperDark" arrow hover content="Set connector">
                                                                        <fa icon="share-nodes" size="lg" />
                                                                    </Popper>
                                                                </span>

                                                                <span class="text-grey isLink me-2" @click="delStep(itemIdx, iIdx, i.node)">
                                                                    <Popper class="popperDark" arrow hover content="Delete step">
                                                                        <fa icon="trash" size="lg" />
                                                                    </Popper>
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </table>
                                                <!-- Name & feature End -->
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                           
                        </div>

                        <div class="col-12">
                            <div v-if="arrItem && arrItem.length >= 2" class="mb-4 p-4 pb-3 boxEscalation" style="border-radius: 10px">
                                <table>
                                    <tr>
                                        <td class="align-top pe-2">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" v-model="autoEscalation" value="true" checked>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="mb-2">Auto escalation</div>
                                            <div v-if="autoEscalation" class="text-grey mb-2">Action below will excecute if no action taken after 48 hours</div>
                                            <div v-if="autoEscalation">
                                                <table width="100%">
                                                    <tr>
                                                        <td width="30px" class="align-top">
                                                            <input type="radio" class="form-check-input" v-model="escalationAction" value="changeApprover">
                                                        </td>
                                                        <td>
                                                            <div class="mb-2">Approve by</div>
                                                            <div class="mb-2">
                                                                <select v-model="escalationSignee" class="form-select">
                                                                    <option value="null">Select signee</option>
                                                                    <option :value="s.signeeId" v-for="s in arrSignee" :key="s.signeeId">{{s.name}} ({{s.email}})</option>
                                                                </select>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td><input type="radio" class="form-check-input" v-model="escalationAction" value="reject"></td>
                                                        <td>Auto reject</td>
                                                    </tr>
                                                </table>

                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div v-if="arrItem && arrItem.length >= 2" class="col-12 text-center">
                            <button class="btn btn-secondary me-2"><fa icon="floppy-disk" size="lg" class="me-2" />Save</button>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-lg-7">
                <div class="workflowBox diagramBox" :style="'height: ' + diaHeight + 'px'" :class="arrItem && arrItem.length > 0 ? 'border shadow-sm' : ''">
                    <v-network-graph
                        :nodes="diaNodes"
                        :edges="diaEdges"
                        :layouts="diaLayouts"
                        :configs="diaConfigs" 
                        :paths="diaPaths" 
                        class="myGraph" />

                        
                </div>
            </div>
        </div>
    </div>

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlAddStep"
        tabindex="-1"
        aria-labelledby="AddStep"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Add Step</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="col-12 mb-3">
                            <ul class="nav nav-pills">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="pill" href="#role">Role</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="pill" href="#signee">Signee</a>
                                </li>
                            </ul>

                            <div class="tab-content mt-4">
                                <div class="tab-pane container active" id="role">
                                    <select v-model="role" class="form-select">
                                        <option value="null">Select role</option>
                                        <option :value="r" v-for="r in arrRole" :key="r">{{r}}</option>
                                    </select>
                                </div>

                                <div class="tab-pane container fade" id="signee">
                                    <select v-model="signee" class="form-select">
                                        <option value="null">Select signee</option>
                                        <option :value="s.signeeId" v-for="s in arrSignee" :key="s.signeeId">{{s.name}} ({{s.email}})</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="signee = null; role = null">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button v-if="role !== null || signee !== null" type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="totalNode++; addStep()">
                        <fa icon="plus" class="me-2" />Add
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlAddConnector"
        tabindex="-1"
        aria-labelledby="AddConnector"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> {{connNodeLabel}} :: Add connection</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body text-grey">
                    <div class="row">
                        <div class="col-1 align-top fw-bold">To</div>
                        <div class="col-11">
                            <div v-for="(item, itemIdx) in arrItem" :key="item" class="p-0">
                                <div v-for="i in item" :key="i" class="m-0 p-0" >
                                    <table v-if="i.node !== connNode" class="table table-hover m-0">
                                        <tbody>
                                            <tr>
                                                <td width="10%"><input class="form-check-input me-2" type="checkbox" v-model="connTarget" :value="i.node" ></td>
                                                <td width="20%">STEP {{itemIdx+1}}</td>
                                                <td width="70%">{{i.name}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="setEdges">
                        <fa icon="plus" class="me-2" />Add
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->
</template>

<script>
import { ref, inject, onMounted, watch, reactive } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import Popper from 'vue3-popper'

import { VNetworkGraph } from 'v-network-graph'
import * as vNG from 'v-network-graph'
import "v-network-graph/lib/style.css"

export default {
    name: 'AddWorkFlow',
    components: { TopNavigation, Alert, Popper, VNetworkGraph },
    setup () {
        const alert = ref([])

        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()

        const arrRole = ref([])
        const arrSignee = ref([])
        const arrItem = ref([])

        const isSubLevel = ref(false)
        const upperLevel = ref(0)
        const step = ref(0)
        const subStep = ref(0)
        const autoEscalation = ref(false)
        const escalationAction = ref('reject')
        const escalationSignee = ref(null)

        const originX = ref(0) // 137
        const originY = ref(-300)
        const totalNode = ref(0)
        const nodeXGap = ref(130) 
        const nodeYGap = ref(70)
        const arrNodeLevel = ref([]) // level of node
        const arrNode = ref([])
        const arrEdge = ref([])
        const diaHeight = ref(600)

        const connNode = ref(null)
        const connNodeLabel = ref(null)
        const connTarget = ref([])

        const diaLayouts = ref([])
        const diaPaths = ref([])
        const diaNodes = ref({})
        const diaEdges = ref({})
        
        const role = ref(null) // add step
        const signee = ref(null) // add step
        const selectedSignee = ref({})

        const diaConfigs = reactive(
            vNG.defineConfigs({
                view: {
                    panEnabled: false,
                    zoomEnabled: false,
                    doubleClickZoomEnabled: false,
                },
                node: {
                    draggable: false,
                    normal: { 
                        radius: 20,
                        strokeWidth: 2, 
                        color: '#53BDEB',
                        strokeColor: ''
                    },
                    label: {
                        color: '#6E777E',
                    },
                    /* hover: {
                        radius: 22,
                        color: '#53BDEB',
                    }, */
                    
                },
                edge: {
                    gap: 20,
                    type: "curve",
                    // margin: 6,
                    margin: 0,
                    normal: {
                        color: "#dee2e6",
                        // width: 2,
                        width: 1,
                    },
                    hover: {
                        width: 2,
                        color: '#dee2e6',
                    },
                    marker: {
                        target: {
                            type: "arrow",
                            width: 8,
                            height: 8,
                        }
                    }
                },
                path: {
                    visible: true,
                    normal: {
                        width: 10,
                        color: "#53BDEB80",
                        dasharray: "10 16",
                        animate: true,
                        animationSpeed: 40,
                    },
                }
            })
        )

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        watch([signee], () => {
            // console.info('watch select signee', signee.value)
            if (signee.value !== null) {
                arrSignee.value.forEach((item) => {
                    if (item.signeeId === signee.value) {
                        selectedSignee.value = item
                    }
                })
            }
        })

        const selectConn = (data) => {
            connNode.value = data.node
            connNodeLabel.value = data.name
            connTarget.value = []

            console.info('select', connNode.value)

            // connTarget.value 
            arrEdge.value.forEach((i, iIdx) => {
                if (i.source === connNode.value) {
                    connTarget.value.push(i.target)
                }
            })
        }

        watch([arrItem.value, arrNode.value], () => {
            console.info('----WATCH: arrItem', arrItem.value)
            genDiagram()
        })

        const genDiagram = async () => {
            console.info('genDiagram')

            diaNodes.value = {}

            arrNode.value.forEach((n, nIdx) => {
                diaNodes.value[n.node] = { name: n.label, icon: '&#xe320'}
            })
            sortNodeLayout()
        }

        const addStep = async () => {
            
            let lblName = ''

            if (isSubLevel.value) {
                // Add more role/signee in same level
                if (role.value !== null) {
                    lblName = role.value
                    arrItem.value[upperLevel.value].push({isRole: true, signeeId: role.value, name: role.value, email: '', node: 'node' + totalNode.value})

                } else if (signee.value !== null) {
                    lblName = selectedSignee.value.name
                    arrItem.value[upperLevel.value].push({isRole: false, signeeId: selectedSignee.value.signeeId, name: selectedSignee.value.name, email: selectedSignee.value.email, node: 'node' + totalNode.value})
                }

                arrNodeLevel.value[upperLevel.value].push('node' + totalNode.value)
                arrNode.value.push({ node: 'node' + totalNode.value, label: lblName})

            } else {
                arrItem.value.push([])

                if (role.value !== null) {
                    lblName = role.value
                    arrItem.value[step.value].push({isRole: true, signeeId: role.value, name: role.value, email: '', node: 'node' + totalNode.value})

                } else if (signee.value !== null) {
                    lblName = selectedSignee.value.name
                    arrItem.value[step.value].push({isRole: false, signeeId: selectedSignee.value.signeeId, name: selectedSignee.value.name, email: selectedSignee.value.email, node: 'node' + totalNode.value})
                }

                step.value = arrItem.value.length

                if (arrNodeLevel.value.length > 6) {
                    let addLevel = arrNodeLevel.value.length - 6

                    diaHeight.value = 600 + (addLevel * 120)
                    originY.value = -300 - (addLevel * 60)
                }

                let curLevel = arrNodeLevel.value.length
                arrNodeLevel.value[curLevel] = []
                arrNodeLevel.value[curLevel].push("node" + totalNode.value)

                arrNode.value.push({ node: 'node' + totalNode.value, label: lblName})
            }

            role.value = null
            signee.value = null

            genDiagram()
        }

        const sortNodeLayout = async () => {

            diaLayouts.value = {}
            diaLayouts.value["nodes"] = {}

            if (arrNodeLevel.value) {

                let x = originX.value
                let y = originY.value

                arrNodeLevel.value.forEach((item, itemLevel) => {

                    if (item.length === 1) {
                       
                        x = originX.value
                        y = y + nodeYGap.value
                        diaLayouts.value["nodes"][item] = { x: x, y: y}

                    } else {

                        y = y + nodeYGap.value

                        for(var i = 0; i < item.length; i++)
                        {
                            if (item.length === 2) {
                               if (i === 0) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 60), y: y}
                               }
                               if (i === 1) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 60), y: y}
                               }
                            }

                            if (item.length === 3) {
                               if (i === 0) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 120), y: y}
                               }
                               if (i === 1) {
                                    diaLayouts.value["nodes"][item[i]] = { x: originX.value, y: y}
                               }
                               if (i === 2) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 120), y: y}
                               }
                            }

                            if (item.length === 4) {
                               if (i === 0) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 180), y: y}
                               }
                               if (i === 1) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 60), y: y}
                               }
                               if (i === 2) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 60), y: y}
                               }
                               if (i === 3) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 180), y: y}
                               }
                            }

                            if (item.length === 5) {
                                if (i === 0) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 240), y: y}
                               }
                               if (i === 1) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 120), y: y}
                               }
                               if (i === 2) {
                                    diaLayouts.value["nodes"][item[i]] = { x: originX.value, y: y}
                               }
                               if (i === 3) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 120), y: y}
                               }
                               if (i === 4) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 240), y: y}
                               }
                            }

                            if (item.length === 6) {
                                if (i === 0) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 300), y: y}
                               }
                               if (i === 1) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 180), y: y}
                               }
                               if (i === 2) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value - 60), y: y}
                               }
                               if (i === 3) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 60), y: y}
                               }
                               if (i === 4) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 180), y: y}
                               }
                               if (i === 5) {
                                    diaLayouts.value["nodes"][item[i]] = { x: (originX.value + 300), y: y}
                               }
                            }
                        }
                    }
                    
                })
            } else {
                console.info('x node')
            }
        }

        const setEdges = async () => {
            console.info('setEdges target', JSON.stringify(connTarget.value))

            if (arrEdge.value) {
                let isNew = true

                // Delete old record
                arrEdge.value.forEach((i, iIdx) => {
                    if (i.source === connNode.value) {
                        arrEdge.value.splice(iIdx, 1)
                    }
                })

                // Update new record
                arrEdge.value.forEach((i, iIdx) => {
                    if (i.source === connNode.value) {
                        isNew = false
                        connTarget.value.forEach((t) => {
                            arrEdge.value[iIdx] = { source: connNode.value, target: t}
                        })
                    }
                })

                if(isNew) {
                    connTarget.value.forEach((t) => {
                        // arrEdge.value.push({ source: connNode.value, target: connTarget.value}) // working
                        arrEdge.value.push({ source: connNode.value, target: t}) // working
                    })
                    
                }
                
                // generate diaEdges
                arrEdge.value.forEach((i, iIdx) => {
                    let edgeNo = iIdx + 1
                    diaEdges.value["edge" + edgeNo] = { source: i.source, target: i.target }
                })
            }
        }

        const delStep = (idx1, idx2, node2) => {
            console.info('delStep', node2)

            if (arrItem.value[idx1].length === 1) {
                arrItem.value.splice(idx1, 1)
            } 
            else 
            {
                arrItem.value[idx1].splice(idx2, 1)
            }

            step.value = arrItem.value.length


            arrNode.value.forEach((i, iIdx) => {
                if (i.node === node2) {
                    arrNode.value.splice(iIdx, 1)
                }
            })

            arrNodeLevel.value.forEach((l, lIdx) => {
                l.forEach((sl, slIdx) => {
                    if (sl === node2) {

                        if (arrNodeLevel.value[lIdx].length === 1) {
                            arrNodeLevel.value.splice(lIdx, 1)

                        } else {
                            arrNodeLevel.value[lIdx].splice(slIdx, 1)
                        }
                    }
                })
               
            })

            genDiagram()
        }

        const getRole = () => {
            arrRole.value = [
                "Developer", "Support", "CS"
            ]
        }

        const getSignee = () => {
            arrSignee.value = [
                {
                    "signeeId": "6E7A09F8E24E11EC89D6FB54B2806B93",
                    "email": "arshad@genusis.com",
                    "name": "Arshad",
                    "role": "Support",
                    "isUser": false
                },
                {
                    "signeeId": "6A831A38E24E11EC89D6FB54B2806B94",
                    "email": "greeny.yeoh@genusis.com",
                    "name": "Greeny Yeoh Lay Ching",
                    "role": "CS",
                    "isUser": false
                },
                {
                    "signeeId": "DED17C76E24F11EC89D6FB54B2801234",
                    "email": "enerson.yap@genusis.com",
                    "name": "Enerson",
                    "role": "Director",
                    "avatar": "https://lh3.googleusercontent.com/a/AATXAJy75Od2bWhR_ZCFPKNSYe9HMOHENV6QEJcMGd1K=s96-c",
                    "isUser": true
                },
                {
                    "signeeId": "DED17C76E24F11EC89D6FB54B2806B98",
                    "email": "yennee.lim@genusis.com",
                    "name": "Lim Yen Nee",
                    "role": "Developer",
                    "avatar": "https://lh3.googleusercontent.com/a/AATXAJy75Od2bWhR_ZCFPKNSYe9HMOHENV6QEJcMGd1K=s96-c",
                    "isUser": true
                },
                {
                    "signeeId": "E3568F3EE24F11EC89D6FB54B2806B95",
                    "email": "yunghee.lee@genusis.com",
                    "name": "Lee Yung Hee",
                    "role": "Developer",
                    "isUser": true
                },
                
            ]
        }

        const genDiagram_ref = () => {

            /* diaPaths.value = {
                path1: {
                    edges: ["edge1", "edge3", "edge6"]
                }
            } */

            diaNodes.value = {
                node1: { name: "Node 1", icon: "&#xe320" },
                node2: { name: "Node 2", icon: "&#xe328" },
                node3: { name: "Node 3", icon: "&#xe328" },
                node4: { name: "Node 4", icon: "&#xe328" },
                node5: { name: "Node 5 Customer Service", icon: "&#xe328" },
                node6: { name: "Node 6", icon: "&#xe328" },
                node7: { name: "Node 7", icon: "&#xe328" },
                node8: { name: "Node 8", icon: "&#xe328" },
                node9: { name: "Node 9", icon: "&#xe328" },
                node10: { name: "Node 10", icon: "&#xe328" },
                node11: { name: "Node 11", icon: "&#xe328" },
            }

            diaEdges.value = {
                edge1: { source: "node1", target: "node2" },
                edge2: { source: "node2", target: "node3" },
                edge3: { source: "node2", target: "node4" },
                edge4: { source: "node2", target: "node5" },
                edge5: { source: "node3", target: "node6" },
                edge6: { source: "node4", target: "node6" },
                edge7: { source: "node5", target: "node7" },
                edge8: { source: "node6", target: "node8" },
                edge9: { source: "node7", target: "node8" },
                edge10: { source: "node8", target: "node9" },
                edge11: { source: "node9", target: "node10" },
                edge12: { source: "node10", target: "node11" },
            }


            diaLayouts.value = {
                nodes: {
                    node1: { x: 137, y: 0 },
                    node2: { x: 137, y: 70 },
                    node3: { x: 17, y: 140 },
                    node4: { x: 137, y: 140 },
                    node5: { x: 257, y: 140 },
                    node6: { x: 120, y: 210 },
                    node7: { x: 250, y: 210 },
                    node8: { x: 137, y: 280 },
                    node9: { x: 137, y: 350 },
                    node10: { x: 137, y: 420 },
                    node11: { x: 137, y: 490 },
                }
            }

            showDiagram.value = true
        }

        onMounted(() => {
            getRole()
            getSignee()

            // genDiagram()
            
        })

        return { 
            route, router, alert, closeAlert, 
            arrRole, arrSignee, arrItem, step, subStep, isSubLevel, upperLevel, autoEscalation, escalationAction, escalationSignee, 
            totalNode, 
            role, signee, selectedSignee, addStep, delStep, diaHeight, 
            genDiagram, diaConfigs, 
            diaLayouts, diaPaths, diaNodes, diaEdges, 
            arrNodeLevel, connNode, connNodeLabel, connTarget, setEdges, arrNode, arrEdge, selectConn,

            // diaLayouts, diaPaths, diaNodes, diaEdges, 
        }

    }
}
</script>

<style>
.workflowBox {
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
  margin-left: 2px;
  margin-right: 2px;
  
}

.diagramBox {
    /* background-color: var(--form-control-focus); */
}

@media (max-width: 575.98px) {
    .workflowBox {
        border-radius: 10px;
    }
}

.myGraph {
    height: 100%;
    padding: 0px;
    margin: 0px;
}

.table-hover > tbody > tr .hoverShow {
    visibility: hidden;
}

.table-hover > tbody > tr:hover .hoverShow {
    visibility: visible;
}

/* @media (max-width: 575.98px) {
    .table-hover > tbody > tr .hoverShow {
        width: 100%;
    }
    .table-hover > tbody > tr:hover .hoverShow {
        width: 100%;
    }
} */

.boxEscalation {
    border-radius: 10px;
    background-color: var(--chart-bg);
}

/* .btn-close {
  color: var(--accent-color);

}
.btn-close:hover {
  color: var(--accent-color);
} */
</style>