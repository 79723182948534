<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)" />
    <TopNavigation :title="docName" :docStatus="docStatus" :isDocSigned="isDocSigned" />

    <div class="mainBoxBorderless mainBox75">
        <div v-if="doc" class="row">
            <div class="col-12">
                <div class="mb-4">
                    <div class="docBox border shadow-sm">
                        <div v-if="privileges && (privileges.includes('creator') || privileges.includes('signee') || privileges.includes('viewer'))">
                            <div class="small text-center" style="width: 60px">Creator</div>
                            <Avatar :isUser="true" :avatar="doc.creator.avatar" :isVerified="doc.creator.isVerified" :displayName="doc.creator.displayName" width="60" height="60" class="ps-2"/>
                        </div>
                        <div v-else><span class="fw-bold me-2">Creator</span> {{doc.creator.displayName}}</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-9 d-grid">
                <div class="docBox border shadow-sm mb-4">
                    <div class="row">
                            <div class="col-12 mt-1">
                                <div class="progress"> 
                                    <div class="progress-bar progress-bar-striped" :style="'width: ' + progress + '%'">{{progress}}% </div>
                                </div>
                                <div class="text-center small mb-4">{{totalSigneeSigned}} / {{totalSignee}} signees signed</div>
                            </div>
                        </div>

                        <div v-for="(s, sIdx) in doc.signees" :key="s">
                            <div class="row">
                                <div class="col-12 col-lg-4 mb-2">
                                    <div v-if="s.isRole">
                                        <span v-if="doc.isSort && s.seq === 0" class="pe-3"></span>
                                        <span v-if="doc.isSort && s.seq !== 0" class="pe-2">{{s.seq}}</span>
                                        {{s.name}}
                                    </div>
                                    <table v-else>
                                        <tr>
                                            <td v-if="doc.isSort" class="pe-2">{{s.seq}}</td>
                                            <td><Avatar :isUser="s.isUser" :avatar="s.url" displayName="" :isVerified="s.isVerified" /></td>
                                            <td>
                                                <span v-if="docPrivacy === 'PUBLIC' || privileges.includes('creator') || privileges.includes('signee') || privileges.includes('viewer')">{{s.name}}</span>
                                                <span v-else>{{ func.maskText(s.name, 'half') }}</span>
                                                
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="col-12 col-lg-4 mb-2">
                                    <div v-if="docPrivacy === 'PUBLIC' || privileges.includes('creator') || privileges.includes('signee') || privileges.includes('viewer')">{{s.email}}</div>
                                    <div v-else>{{ func.maskText(s.email, 'half') }}</div>

                                    <div v-if="s.privileges"><i>({{s.privileges.join(", ")}})</i></div>
                                </div>
                                
                                <div class="col-12 col-lg-1 mb-2"><span class="badge bg-grey">{{s.role}}</span></div>

                                <div class="col-12 col-lg-3 mb-2 text-end">
                                    {{s.status}} 
                                    <div v-if="s.dateSigned !== '0' && (docPrivacy === 'PUBLIC' || privileges.includes('creator') || privileges.includes('signee') || privileges.includes('viewer'))">
                                        <span>{{ func.convDateTimeFormat(s.dateSigned, 'datetime')}}</span>
                                    </div>
                                </div>
                            
                                <div v-if="sIdx+1 !== doc.signees.length" class="col-12 col-lg-12 py-0"><hr /></div>
                            </div>
                        </div>
                </div>
            </div>

            <div class="col-lg-3 d-grid">
                <div class="docBox border shadow-sm mb-4">
                    <div class="row">
                            <div class="col-12 mb-3">
                                <div class="lblTiny">Date Created</div> 
                                <div>{{ func.convDateTimeFormat(doc.dateCreate, 'datetime') }}</div>
                            </div>
                            <div v-if="doc.dateComplete === '0'" class="col-12 mb-3">
                                <div class="lblTiny">Date Modified</div> 
                                <div>{{ func.convDateTimeFormat(doc.dateModify, 'datetime') }}</div>
                            </div>
                            <div v-if="doc.dateComplete !== '0'" class="col-12 mb-3">
                                <div class="lblTiny">Date Completed</div> 
                                <div>{{ func.convDateTimeFormat(doc.dateComplete, 'datetime') }}</div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="lblTiny">Filesize</div> 
                                <div>{{ func.convSize(doc.file.size) }}</div>
                            </div>
                            <div class="col-12 mb-3">
                                <div class="lblTiny">Total page</div> 
                                <div>{{ doc.file.totalPage }}</div>
                            </div>
                            <div class="col-12 mb-3" :class="privileges && privileges.includes('creator') ? 'col-lg-6' : 'col-lg-12'">
                                <div class="lblTiny">
                                    <Popper class="popperDark" arrow hover content="Appear to all signees within the same doc" placement="top">
                                        Public Remark
                                    </Popper>
                                </div> 
                                <div>{{ doc.remark === undefined ? '-' : doc.remark }}</div>
                            </div>
                            <div v-if="privileges && privileges.includes('creator')" class="col-12 mb-3">
                                <div class="lblTiny">
                                    <Popper class="popperDark" arrow hover content="Appear to myself" placement="top">
                                        Private Note
                                    </Popper>
                                </div> 
                                <div>{{ doc.note === undefined ? '-' : doc.note }}</div>
                            </div>
                            <div class="col-12">
                                <div class="lblTiny">Status</div> 
                                <div>{{ doc.status }}</div>
                            </div>

                        </div>
                </div>
            </div>

            <div class="col-12 text-center mt-1">
                <div>
                    <span v-if="route.query.redirect" class="me-1">
                        <button @click="$router.push({path: '/p/' + route.query.p, query: route.query})" class="btn btn-secondary">
                            <fa icon="angle-left" size="lg" class="me-2" />Back
                        </button>
                    </span>

                    <span v-if="docPrivacy === 'PUBLIC' || privileges.includes('creator') || privileges.includes('signee') || privileges.includes('viewer')">
                        <button class="btn btn-secondary me-2" data-bs-toggle="modal" data-bs-target="#mdlReject" ><fa icon="circle-minus" size="lg" class="me-2" />Reject</button>
                        <button class="btn btn-secondary me-2" data-bs-toggle="modal" data-bs-target="#mdlAttachment"><fa icon="paperclip" size="lg" class="me-2" />Attachment</button>

                        <button v-if="(projMode === 'KYC' || projMode === 'STRICT') && getUserIsVerified === 'false'" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlVerifyKyc">
                            <fa icon="file-pdf" size="lg" class="me-2" />View PDF
                        </button>
                        <button v-else class="btn btn-secondary" @click="$router.push({path: '/p/' + doc.projectId + '/' + doc.documentId, query: route.query})">
                            <fa icon="file-pdf" size="lg" class="me-2" />View PDF
                        </button>
                    </span>

                    <div class="bg-danger text-white mt-2">* Reject & Attachment buttons only show when using workflow</div>
                </div>
            </div>

        </div>
    </div>

    <!-- Modal: mdlVerifyKyc --> 
    <div
        class="modal fade"
        id="mdlVerifyKyc"
        tabindex="-1"
        aria-labelledby="VerfyKyc"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title"> KYC Verification</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">In order to access the PDF, you must complete the KYC verification by upload front and back of your NRIC/Passport.</div>
                        <div class="col-12">
                            <file-pond
                                name="file"
                                ref="pondKyc"
                                label-idle="Drag-n-drop images"
                                v-bind:allow-multiple="true"
                                accepted-file-types="image/jpg, image/jpeg, image/png, image/gif"
                                :server="kycConfig"
                                :files="myKyc"
                                storeAsFile="true"
                                @init="handleKycUploadInit"
                                @error="uplKycError"
                                @addfile="getKyc"
                                allowRevert="true"
                                allowRemove="false"
                            />
                            <div class="text-center small text-grey">Multiple images <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> 1MB/image limit <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG or GIF only</div>
                            <div class="text-center small text-grey"><fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Please ensure all 4 corners are visible</div>
                            <div class="text-center small text-grey"><fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Text is clerly readable without blockage or blurry</div>
                            <div class="text-center small text-grey"><fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> Images in original color, not black & white or changed in any way</div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="arrow-pointer" class="me-2" />Apply
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal: mdlReject --> 
     <div
        class="modal fade"
        id="mdlReject"
        tabindex="-1"
        aria-labelledby="mdlReject"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Reject</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-2">Rejection reason</div>
                    <div class="mb-2"><textarea class="form-control" rows="5" v-model="rejectionReason"></textarea></div>
                </div>
                <div v-if="rejectionReason !== ''" class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="arrow-pointer" class="me-2" />Apply
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal: mdlAttachment --> 
     <div
        class="modal fade"
        id="mdlAttachment"
        tabindex="-1"
        aria-labelledby="mdlAttachment"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Attachment</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">Comment/Remark</div>
                        <div class="col-12 mb-3">
                            <file-pond
                                name="attachment"
                                ref="pondAttachment"
                                label-idle="Drag-n-drop images"
                                v-bind:allow-multiple="true"
                                accepted-file-types="image/jpg, image/jpeg, image/png, image/gif, text/plain, application/pdf, application/msword"
                                :server="attachmentConfig"
                                :files="myAttachment"
                                storeAsFile="true"
                                @init="handleAttachmentUploadInit"
                                @error="uplAttachmentError"
                                @addfile="getAttachment"
                                allowRevert="true"
                                allowRemove="false"
                            />
                            <div class="text-center small text-grey">Multiple images <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> 1MB/image limit <fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" /> JPG, JPEG, PNG, GIF, TXT, DOC or PDF only</div>
                        </div>
                    </div>
                </div>
                <div v-if="rejectionReason !== ''" class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="arrow-pointer" class="me-2" />Apply
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

</template>

<script>
import { ref, inject, computed, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRoute, useRouter } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import Avatar from '@/components/Avatar.vue'
import { useStore } from '@/stores/store'

// KYC - IC/Passport upload
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

export default {
    name: 'ViewDocInfo',
    components: { TopNavigation, Alert, Popper, Avatar, FilePond },
    setup() {

        const axios = inject('axios')
        const alert = ref([])

        const func = funcs
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const { getUserIsVerified } = useStore()

        const doc = ref(null)
        const docName = ref(null)
        const docPrivacy = ref('CONFIDENTIAL')
        const totalSignee = ref(0)
        const totalSigneeSigned = ref(0)
        const privileges = ref([])
        const projMode = ref('')

        const rejectionReason = ref('')

        const kycConfig = ref({ 
            "url": "https://signon.my:3002/user/signature/signature", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            } 
        })

        const attachmentConfig = ref({ 
            "url": "https://signon.my:3002/user/signature/signature", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            } 
        })

        const getDoc = async () => {
            try {
                const res = await axios.get('/signon/signee/document/' + route.params.documentId)
                console.info('getDoc', JSON.stringify(res.data.data.signees))
                if (res.data.status === 1) {
                    
                    doc.value = res.data.data
                    docName.value = res.data.data.file.name
                    docPrivacy.value = res.data.data.privacy
                    // docPrivacy.value = 'CONFIDENTIAL'
                    // docPrivacy.value = 'PRIVATE'

                    privileges.value = res.data.data.signee.privileges

                    if (docName.value) {
                        docName.value = docName.value.replace('.pdf', '')
                    }
                    
                    if (doc.value) {
                        doc.value.signees.forEach((item, idx) => {
                            if (item.privileges && item.privileges.includes('signee')) {
                                totalSignee.value++
                                if (item.status === 'SIGNED') {
                                    totalSigneeSigned.value++
                                }
                            }
                        })
                    }

                    // temp hardcode
                    doc.privacy = 'PRIVATE'

                    await getProj()
                } 
            } catch (error) {
                
                console.log('getDoc | FAILURE', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            }
        }

        const getProj = async () => {
            try {
                const res = await axios.get('/signon/signee/' + doc.value.projectId)
                console.info('getProj', JSON.stringify(res.data.data))
                if (res.data.status === 1) {
                    projMode.value = res.data.data.mode
                }

            } catch (error) {
                console.log('getProj | FAILURE', JSON.stringify(error))
            }
        }

        const progress = computed(() => {
            return Math.ceil(totalSigneeSigned.value/totalSignee.value*100)
        })

        const uplKycError = (error) => {
            console.info('uplKycError', error)
        }

        const getKyc = (error, file) => {
            console.info('addfile: err', error)
            console.info('addfile: file', file)
            console.info('addfile: file.filename', file.filename)
            console.info('addfile: file.fileSize', file.fileSize)
            console.info('addfile: file.file', file.file)
            console.info('addfile: file.source', file.source)
            console.info('addfile: file.status', file.status)
            console.info('addfile: file.transferId', file.transferId)
        }

        const uplAttachmentError = (error) => {
            console.info('uplAttachmentError', error)
        }

        const getAttachment = (error, file) => {
            console.info('addfile: err', error)
            console.info('addfile: file', file)
            console.info('addfile: file.filename', file.filename)
            console.info('addfile: file.fileSize', file.fileSize)
            console.info('addfile: file.file', file.file)
            console.info('addfile: file.source', file.source)
            console.info('addfile: file.status', file.status)
            console.info('addfile: file.transferId', file.transferId)
        }

        onMounted(() => {
            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }
        })

        const iniLoad = () => {
            console.info('---- func: iniLoad')
            func.clearPrevLocalStorage()
            getDoc()
            
        }

        iniLoad()

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }


        return {
            axios, route, router, alert, closeAlert, func,
            doc, docName, docPrivacy, progress, totalSignee, totalSigneeSigned,
            privileges, getUserIsVerified, projMode,
            kycConfig, uplKycError, getKyc, rejectionReason, 
            attachmentConfig, uplAttachmentError, getAttachment, 
        }

    },
    data () {
        return {
            myKyc: [],
            myAttachment: [],
        }
    },
    methods: {
        handleKycUploadInit (){
            // console.info('FilePond has initialized')
            // example of instance method call on pond reference
            // let a = this.$refs.pondS.getFiles()
            // console.info('handleSignaturePondInit', a)
            this.$refs.pondKyc.getFiles()
        },
        handleAttachmentUploadInit (){
            // console.info('FilePond has initialized')
            // example of instance method call on pond reference
            // let a = this.$refs.pondS.getFiles()
            // console.info('handleSignaturePondInit', a)
            this.$refs.pondAttachment.getFiles()
        }
    }
}
</script>

<style>
body {
    overflow: scroll;
}

.docBox {
    border-radius: 10px;
    padding: 25px;
}

@media (max-width: 575.98px) {
  .docBox {
    width: 98%;
    margin-bottom: 25px;
    border-radius: 10px;
    padding: 25px;
  }
}
</style>