<template>

    <span v-if="status === 'WIP'" class="badge bg-success me-1 isLabel" :class="class">{{status}}</span>
    <span v-if="status === 'DONE'" class="badge bg-purple me-1 isLabel" :class="class">{{status}}</span>
    <span v-if="status === 'CANCELED'" class="badge bg-orange me-1 isLabel" :class="class">{{status}}</span>

</template>

<script>
import { ref, onMounted } from 'vue'
import Popper from 'vue3-popper'

export default {
    name: 'ProjStatus',
    components: { Popper },
    props: [ 'status', 'class' ],
    setup (props) {

        onMounted(() => {
            // console.info('Tag::onMounted', JSON.stringify(tags.value))
        })

        return {}
    }
}
</script>

<style>

</style>