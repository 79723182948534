<template>
  <div class="container-fluid" >
      <!-- <router-view :key="reload"/> -->
      <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
// import { computed, provide, reactive} from 'vue'
import { onMounted, onUpdated, inject, ref, computed } from 'vue'
import { initStore } from '@/stores/store'
// import { useRoute, useRouter } from 'vue-router'
// import config from '../vue.config'

export default {
  setup () {

    // const axios = inject('axios')

    initStore()

    const getMediaPreference = () => {
          const hasDarkPreference = window.matchMedia(
              "(prefers-color-scheme: dark)"
          ).matches

          if (hasDarkPreference) {
              return "dark-theme"
          } else {
              return "light-theme"
          }
      }

    onMounted(async() => {
        // initStore()

        if (localStorage.getItem('theme') !== null && localStorage.getItem('theme') !== undefined) {
            localStorage.setItem('theme', localStorage.getItem('theme'))

        } else {
            const initTheme = getMediaPreference()
            localStorage.setItem('theme', initTheme)
        }

        // const initTheme = localStorage.getItem('theme') || getMediaPreference()
        // localStorage.setItem('theme', initTheme)
        
        document.documentElement.className = localStorage.getItem('theme') // default theme

    })
    
    return { }

  }

}
</script>

<style>
</style>
