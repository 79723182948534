import 'bootstrap/dist/css/bootstrap.css'
import { createApp } from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'
import router from './router'

import './assets/global.css'
library.add(fas, fab, far)
// library.add(fas)

import axios from '@/functions/axios-instance'    

import { initStore } from '@/stores/store'
initStore()

import { useStore } from '@/stores/store'
const store = useStore()


/* createApp(App)
    .use(router)
    .component('fa', FontAwesomeIcon)
    .provide('axios', axios)
    .mount('#app')  */

createApp(App)
    .provide('axios', axios)
    .provide('store', store)
    .use(router)
    .component('fa', FontAwesomeIcon)
    .mount('#app') 

import 'bootstrap/dist/js/bootstrap.js'


// vuejs 3 + typescript + router + babel
// -------------------------------------
// npm i axios
// npm i bootstrap
// npm i popperjs@/core - Not working in v-for
// npm i --save @fortawesome/vue-fontawesome@prerelease
// npm i --save @fortawesome/fontawesome-svg-core
// npm i --save @fortawesome/free-solid-svg-icons
// npm i --save @fortawesome/free-brands-svg-icons
// npm i --save @fortawesome/free-regular-svg-icons
// sudo 
// npm uninstall @fortawesome/xxx one by one > npm install > npm reinstall back one by one when upgrade having issues

// npm i vue3-draggable-resizable
// npm i vue-pdf-embed
// npm i pdf-lib
// npm i downloadjs
// npm i vue3-date-time-picker 
// npm i vue3-popper

// npm i vue-filepond filepond --save
// npm i filepond-plugin-file-validate-type --save
// npm i filepond-plugin-image-preview --save
