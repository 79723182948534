<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Team" icon="users" />

    <div class="mainBoxBorderless mainBox65">
        <div class="row mb-3">

            <div class="col-12 col-lg-8">
                <div class="row">
                    <div class="col-lg-8 mb-4">
                        <div class="input-group">
                            <span class="input-group-text"><fa icon="search" /></span>
                            <input type="text" v-model="filterName" class="form-control" placeholder="Team" >
                            <span v-if="filterName && filterName.length > 0" class="input-group-text clearSearch" @click="filterName = ''">
                                <fa icon="xmark" />
                            </span>
                        </div>
                    </div>

                    <div class="col-lg-4 text-end text-primary isLink pt-2 pe-3 dropdown-menu-end">
                        <a href="#" data-bs-toggle="dropdown">
                            <Popper class="popperDark" arrow hover content="More actions">
                                <fa icon="ellipsis-vertical" class="px-2 isLink" style="font-size: 18px" />
                            </Popper>
                        </a>

                        <ul class="dropdown-menu dropdown-menu-secondary">
                            <li>
                                <div class="dropdown-item" data-bs-toggle="modal" data-bs-target="#mdlTeam" @click="teamId = null; teamName = ''">
                                    Add team
                                    <span class="float-end">
                                        <fa icon="users" />
                                        <span class="fw-bold" style="font-size: 14px">+</span>
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div class="dropdown-item" @click="$router.push({ path: '/team/member/add/team' })">
                                    Add member <span class="float-end"><fa icon="user-plus" class="ms-3"/></span>
                                </div>
                            </li>
                            <li>
                                <div class="dropdown-item" @click="$router.push({ path: '/team/member/list' })">
                                    View member <span class="float-end"><fa icon="user" class="ms-3"/></span>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="col-12 mt-1">
                        <div class="teamBox border shadow-sm">
                            <div class="row">
                                <div class="col-12 px-1" ref="scrollComponent">
                                    <div v-if="isLoading" class="mb-3 text-center">
                                        <div class="spinner-border spinner-border-sm me-1"></div> Loading...
                                    </div>
                                    <div v-if="!isLoading" class="mb-3 text-end small fst-italic">100 of 10,000</div>
                    
                                    <div class="mb-3" v-if="checkedTeam && checkedTeam.length > 0">
                                        <span class="mx-2 text-grey isLink"><fa icon="trash" size="lg" style="padding-left: 9px" /></span>
                                        <span class="float-end text-grey fw-bold">{{checkedTeam.length}} selected</span>
                                    </div>

                                    <table class="table table-hover" border="0">
                                        <tbody>
                                            <tr v-for="(item, itemIdx) in arrItem" :key="item">
                                                
                                                <td width="50px" class="text-center">
                                                    <div>
                                                        <input v-model="checkedTeam" type="checkbox" class="form-check-input" :value="itemIdx" />
                                                    </div>
                                                </td>
                                            
                                                <td class="align-middle">
                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <div>
                                                                <span class="fw-bold me-2">{{item.teamName}}</span>
                                                                <span v-if="item.isDefault" class="text-grey fst-italic small">Default</span>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-2 small text-grey text-start">
                                                            {{ item.total }}
                                                            <Popper class="popperDark" arrow hover content="Team member">    
                                                                <fa icon="user" class="text-end px-1"/>
                                                            </Popper>
                                                            <span class="ps-2 isLink" data-bs-toggle="modal" data-bs-target="#mdlTeamMbr" @click="getTeamMbr(item)">view</span>
                                                        </div>

                                                        <div class="col-lg-4 text-end d-none d-md-block hoverShow">
                                                            <span v-if="!item.isDefault"  class="text-grey isLink me-4">
                                                                <Popper class="popperDark" arrow hover content="Delete team">
                                                                    <fa icon="trash" size="lg" />
                                                                </Popper>
                                                            </span>

                                                            <span v-if="!item.isDefault" data-bs-toggle="modal" data-bs-target="#mdlTeam" @click="teamId = item.teamId; teamName = item.teamName" class="text-grey isLink me-4">
                                                                <Popper class="popperDark" arrow hover content="Edit team">
                                                                    <fa icon="pen" size="lg" />
                                                                </Popper>
                                                            </span>

                                                            <!-- <span data-bs-toggle="modal" data-bs-target="#mdlAddMbr" @click="teamId = item.teamId; teamName = item.teamName" class="text-grey isLink me-2"> -->
                                                            <span data-bs-toggle="modal" data-bs-target="#mdlAddMbr" @click="getMemberList(item)" class="text-grey isLink me-4">
                                                                <Popper class="popperDark" arrow hover content="Add member">
                                                                    <fa icon="user-plus" size="lg" />
                                                                </Popper>
                                                            </span>

                                                            <span @click="$router.push({ path: '/team/' + item.teamId + '/permission' })" class="text-grey isLink me-2">
                                                                <Popper class="popperDark" arrow hover content="Set team permission">
                                                                    <fa icon="user-shield" size="lg" />
                                                                </Popper>
                                                            </span>
                                                            
                                                        </div>
                                                        <!-- Small screen -->
                                                        <div class="col-12 text-end d-block d-md-none">
                                                            <span v-if="!item.isDefault" class="text-grey isLink me-4">
                                                                <fa icon="trash" size="lg" />
                                                            </span>

                                                            <span v-if="!item.isDefault" data-bs-toggle="modal" data-bs-target="#mdlTeam" @click="teamId = item.teamId; teamName = item.teamName" class="text-grey isLink me-4">
                                                                <fa icon="pen" size="lg" />
                                                            </span>

                                                            <span data-bs-toggle="modal" data-bs-target="#mdlAddMbr" @click="getMemberList(item)" class="text-grey isLink me-4">
                                                                <fa icon="user-plus" size="lg" />
                                                            </span>

                                                            <span @click="$router.push({ path: '/team/' + item.teamId + '/permission' })" class="text-grey isLink me-2">
                                                                <Popper class="popperDark" arrow hover content="Set team permission">
                                                                    <fa icon="user-shield" size="lg" />
                                                                </Popper>
                                                            </span>

                                                        </div>
                                                        <!-- End Small screen -->
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr v-if="isLoadingMore" class="mt-3 text-center">
                                                <td colSpan="2">
                                                    <div class="spinner-border spinner-border-sm me-1"></div> Loading...
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-4">
                <div class="teamBox border shadow-sm mb-4">
                            <div class="row">
                                <div class="col-12 pb-4">
                                    <div class="bg-danger text-white fst-italic small">* Show for Team package & 1 member ONLY</div>
                                    Seems like you want to create a new team for new members with lesser security privileges, we suggest to create team as below:
                                </div>
                            </div>

                            <div class="row suggestTeamBox">
                                <div class="col-12">Add <b>General</b> team with following permissions:</div>
                                <div class="col-12">
                                    <ul>
                                        <li>Dashboard</li>
                                        <li>Rack</li>
                                        <li>Contact</li>
                                        <li>Form</li>
                                    </ul>
                                </div>
                                <div class="col-12 text-end isLink">
                                    <Popper class="popperDark" arrow hover content="Add team"><fa icon="users"/></Popper>
                                    <span class="fw-bold" style="font-size: 14px">+</span>
                                </div>
                            </div>

                            <div class="row suggestTeamBox">
                                <div class="col-12">Add <b>Data Entry</b> team with following permissions:</div>
                                <div class="col-12">
                                    <ul>
                                        <li>Dashboard</li>
                                        <li>Rack</li>
                                        <li>Contact</li>
                                        <li>Form</li>
                                    </ul>
                                </div>
                                <div class="col-12 text-end isLink">
                                    <Popper class="popperDark" arrow hover content="Add team"><fa icon="users"/></Popper>
                                    <span class="fw-bold" style="font-size: 14px">+</span>
                                </div>
                            </div>
                            
                        <!-- </div>
                    </div> -->

                    <div class="row">
                        <div class="col-12">
                            You can change the team name and permissions later
                        </div>
                    </div>
                </div>

                <div class="teamBox border shadow-sm">
                    <div class="fw-bold mb-4">Team</div>
                    <ul>
                        <li class="pb-2">A team can has multiple members</li>
                        <li class="pb-2">A group of members with similar roles</li>
                        <li>E.g IT team, support team etc</li>
                    </ul>

                    <div class="p-1 mt-5">
                        <div class="fw-bold mb-4">Options</div>
                        <table class="table table-borderless">
                            <tr>
                                <td class="pb-2">
                                    <span>
                                        <fa icon="users"/>
                                        <span class="me-2 fw-bold" style="font-size: 14px">+</span>
                                    </span>
                                </td>
                                <td>Add team</td>
                            </tr>
                            <tr>
                                <td><fa icon="user-plus" size="lg" /></td>
                                <td>Add member</td>
                            </tr>
                            <tr>
                                <td><fa icon="user" size="lg" /></td>
                                <td>View member</td>
                            </tr>
                            <tr>
                                <td><fa icon="trash" size="lg" /></td>
                                <td>Delete team</td>
                            </tr>
                            <tr>
                                <td><fa icon="pen" size="lg" /></td>
                                <td>Edit team</td>
                            </tr>
                            <tr>
                                <td><fa icon="user-shield" size="lg" /></td>
                                <td>Set team permission</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
           
        </div>
       
    </div>


    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlTeam"
        tabindex="-1"
        aria-labelledby="AddEditTeam"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 v-if="teamId === null" class="modal-title"> Add team </h5>
                    <h5 v-else class="modal-title">Edit team :: {{teamName}}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="teamId === null" class="row">
                        <div class="col-12 pb-3">
                            <input v-model="teamName" class="form-control" placeholder="Team name">
                        </div>
                    </div>

                    <!-- New UI -->
                    <div v-else class="row">
                        <div class="col-12 pb-4">
                            <div class="small text-grey mb-2">Rename Team To</div>
                            <div class="input-group">
                                <input v-model="teamName" class="form-control" placeholder="Team name">
                                <button class="btn btn-secondary" type="button" data-bs-dismiss="modal" style="width: 120px" >
                                    <fa icon="rotate" class="me-2" />Update
                                </button>
                            </div>
                        </div>

                        <div class="col-12 pb-4">
                            <div class="small text-grey mb-2">Duplicate Team To</div>
                            <div class="input-group">
                                <select class="form-select" v-model="dupTeamId">
                                    <option value="">Select team</option>
                                    <option :value="t.teamId" v-for="t in arrItem" :key="t.teamId">{{ t.teamName }}</option>
                                </select>
                                <input v-model="dupTeamName" class="form-control" placeholder="New team">
                                <button class="btn btn-secondary" type="button" data-bs-dismiss="modal" style="width: 120px">
                                    <fa icon="clone" class="me-2" />Duplicate
                                </button>
                            </div>
                        </div>

                        <div class="col-12 pb-4">
                            <div class="small text-grey mb-2">Duplicate Team & Members To</div>
                            <div class="input-group">
                                <select class="form-select" v-model="dupTeamId">
                                    <option value="">Select team</option>
                                    <option :value="t.teamId" v-for="t in arrItem" :key="t.teamId">{{ t.teamName }}</option>
                                </select>
                                <input v-model="dupTeamName" class="form-control" placeholder="New team">
                                <button class="btn btn-secondary" type="button" data-bs-dismiss="modal" style="width: 120px">
                                    <fa icon="clone" class="me-2" />Duplicate
                                </button>
                            </div>
                        </div>

                    </div>
                    <!-- New UI End -->

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button v-if="teamId === null && teamName !== ''" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlAddMbr"
        tabindex="-1"
        aria-labelledby="AddMbr"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">Add member :: {{teamName}}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                        <div class="input-group">
                            <span class="input-group-text"><fa icon="search" /></span>
                            <select class="form-select">
                                <option value="">All</option>
                                <option value="">Member</option>
                                <option value="">Not Member</option>
                            </select>
                            <input type="text" v-model="filterAddMember" class="form-control" placeholder="Name / Email" >
                            <span v-if="filterAddMember && filterAddMember.length > 0" class="input-group-text clearSearch" @click="filterAddMember = ''">
                                <fa icon="xmark" />
                            </span>
                        </div>
                    </div>
                    <div v-if="checkedUser && checkedUser.length > 0" class="fw-bold text-grey text-end align-middle mb-1">
                        {{checkedUser.length}} selected
                    </div>

                    <div class="table-responsive" style="height: 300px">
                        <table class="table table-hover" border="0">
                            <tbody>
                                <tr v-for="(item, itemIdx) in arrUser" :key="item" >
                                    <td width="35px" class="align-middle">
                                        <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="itemIdx" />
                                    </td>
                                    <td width="50px" class="align-middle">
                                        <Avatar :key="itemIdx" isUser="true" avatar="" displayName="" :isVerified="item.isVerified" width="30" height="30" />
                                    </td>
                                    <td class="align-middle">
                                        <div class="fw-bold isLink" data-bs-dismiss="modal">
                                            <router-link :to="'/team/member/' + item.userId">
                                                <span>{{item.lastName}} {{item.firstName}}</span>
                                                <span class="text-grey"><fa icon="at" class="mx-1"/>{{ item.displayName }}</span>
                                            </router-link>
                                        </div>
                                        <div class="small text-grey">{{ item.userId }}</div>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="rotate" class="me-2" />Update
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlTeamMbr"
        tabindex="-1"
        aria-labelledby="TeamMbr"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title"><fa icon="users" size="lg" class="me-2" />{{teamName}}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-2">
                        <div class="input-group">
                            <span class="input-group-text"><fa icon="search" /></span>
                            <input type="text" v-model="filterViewMember" class="form-control" placeholder="Name / Email" >
                            <span v-if="filterViewMember && filterViewMember.length > 0" class="input-group-text clearSearch" @click="filterViewMember = ''">
                                <fa icon="xmark" />
                            </span>
                        </div>
                    </div>

                    <div class="table-responsive" style="height: 300px">
                        <table class="table table-hover" border="0">
                            <tbody v-if="arrTeamMbr">
                                <tr v-for="(t, tIdx) in arrTeamMbr" :key="t" data-bs-dismiss="modal">
                                    <td v-if="t" width="50px" class="align-middle">
                                        <Avatar :key="tIdx" isUser="true" avatar="" displayName="" :isVerified="t.isVerified" width="30" height="30" />
                                    </td>
                                    <td class="align-middle">
                                        <div class="fw-bold isLink">
                                            <router-link :to="'/team/member/' + t.userId">
                                                <span>{{t.lastName}} {{t.firstName}}</span>
                                                <span class="text-grey"><fa icon="at" class="mx-1"/>{{ t.displayName }}</span>
                                            </router-link>
                                        </div>

                                        <div class="small text-grey">{{ t.userId }}</div>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import Avatar from '@/components/Avatar.vue'

export default {
    name: 'TeamList',
    components: { Alert, TopNavigation, Popper, Avatar },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const scrollComponent = ref(null)
        const arrItem = ref([])
        const total = ref(0)
        const rcdFr = ref(0) // record index from
        const rcdDisplay = ref(15) // total record to display, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const filterName = ref('')
        const filterAddMember = ref('')
        const filterViewMember = ref('')

        const teamId = ref(null)
        const teamName = ref('')
        const arrUser = ref(null)
        const arrTeamMbr = ref([])

        const checkedTeam = ref([])
        const checkedUser = ref([])

        const dupTeamId = ref('')
        const dupTeamName = ref('')

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        watch([checkedTeam], () => {
            console.info('watch', JSON.stringify(checkedTeam.value))
        })

        const getTotal = () => {

            total.value = 8
        }

        const getList = (loadMore) => {

            isLoading.value = false
            isLoadingMore.value = false

            arrItem.value = [
                {
                    teamId: '1001',
                    teamName: 'Admin',
                    isDefault: true,
                    total: 1
                },
                {
                    teamId: '1002',
                    teamName: 'Developer',
                    isDefault: false,
                    total: 8
                },
                {
                    teamId: '1003',
                    teamName: 'Customer Service',
                    isDefault: false,
                    total: 2
                }

            ]
            
        }

        const loadMore = () => {
            if (rcdFr.value < total.value) {
                rcdFr.value = rcdFr.value + rcdDisplay.value
                getList(true)

            } else {
                isLoadingMore.value = false
            }
        }

        const getMemberList = (data) => {

            teamId.value = data.teamId
            teamName.value = data.teamName 

            arrUser.value = [
                {
                    userId: 'alex.hang@yahoo.com',
                    firstName: 'Kar Meng',
                    lastName: 'Hang',
                    displayName: 'Alex.Hang',
                    avatar: '',
                    isVerified: false,
                    tags: ['colleague', 'development']
                },
                {
                    userId: 'mohamad1234@yahoo.com',
                    firstName: 'Mohamad bin Abdullah',
                    lastName: '',
                    displayName: 'Mohamad',
                    avatar: '',
                    isVerified: true,
                    tags: ['colleague', 'cs']
                },
                {
                    userId: 'grace@gmail.com',
                    firstName: 'Shu Min',
                    lastName: 'Tan',
                    displayName: 'Grace',
                    avatar: '',
                    isVerified: false,
                    tags: []
                },
                {
                    userId: 'kelly@gmail.com',
                    firstName: 'Hai Yan',
                    lastName: 'Kek',
                    displayName: 'Kelly Kek',
                    avatar: '',
                    isVerified: false,
                    tags: ['mcis']
                },
                {
                    userId: 'jason.chan@yahoo.com',
                    firstName: 'Chun Kiat',
                    lastName: 'Lim',
                    displayName: 'Jason',
                    avatar: '',
                    isVerified: false,
                    tags: ['unifi']
                },
                {
                    userId: 'joey1980@gmail.com',
                    firstName: 'Mei Lee',
                    lastName: 'Tan',
                    displayName: 'Joey80',
                    avatar: '',
                    isVerified: false,
                    tags: ['mcis', 'management']
                },
                {
                    userId: 'patrick@gmail.com',
                    firstName: 'Xin Zhan',
                    lastName: 'Teoh',
                    displayName: 'Patrick',
                    avatar: '',
                    isVerified: false,
                    tags: ['colleague', 'support']
                },
                {
                    userId: 'yennee.lim@genusis.com',
                    firstName: 'Yen Nee',
                    lastName: 'Lim',
                    displayName: 'YNLim',
                    avatar: '',
                    isVerified: true,
                    tags: ['colleague', 'development']
                },

            ]

        }

        const getTeamMbr = (data) => {

            arrTeamMbr.value = []
            teamId.value = data.teamId
            teamName.value = data.teamName 

            const tmpUser = [
                {
                    userId: 'alex.hang@yahoo.com',
                    firstName: 'Kar Meng',
                    lastName: 'Hang',
                    displayName: 'Alex.Hang',
                    avatar: '',
                    isVerified: false,
                    tags: ['colleague', 'development']
                },
                {
                    userId: 'mohamad1234@yahoo.com',
                    firstName: 'Mohamad bin Abdullah',
                    lastName: '',
                    displayName: 'Mohamad',
                    avatar: '',
                    isVerified: true,
                    tags: ['colleague', 'cs']
                },
                {
                    userId: 'grace@gmail.com',
                    firstName: 'Shu Min',
                    lastName: 'Tan',
                    displayName: 'Grace',
                    avatar: '',
                    isVerified: false,
                    tags: []
                },
                {
                    userId: 'kelly@gmail.com',
                    firstName: 'Hai Yan',
                    lastName: 'Kek',
                    displayName: 'Kelly Kek',
                    avatar: '',
                    isVerified: false,
                    tags: ['mcis']
                },
                {
                    userId: 'jason.chan@yahoo.com',
                    firstName: 'Chun Kiat',
                    lastName: 'Lim',
                    displayName: 'Jason',
                    avatar: '',
                    isVerified: false,
                    tags: ['unifi']
                },
                {
                    userId: 'joey1980@gmail.com',
                    firstName: 'Mei Lee',
                    lastName: 'Tan',
                    displayName: 'Joey80',
                    avatar: '',
                    isVerified: false,
                    tags: ['mcis', 'management']
                },
                {
                    userId: 'patrick@gmail.com',
                    firstName: 'Xin Zhan',
                    lastName: 'Teoh',
                    displayName: 'Patrick',
                    avatar: '',
                    isVerified: false,
                    tags: ['colleague', 'support']
                },
                {
                    userId: 'yennee.lim@genusis.com',
                    firstName: 'Yen Nee',
                    lastName: 'Lim',
                    displayName: 'YNLim',
                    avatar: '',
                    isVerified: true,
                    tags: ['colleague', 'development']
                },

            ]

            for (var i=0; i<data.total; i++) {
                arrTeamMbr.value.push(tmpUser[i])
            }

        }

        const handleScroll = (e) => {
            
            let element = scrollComponent.value
            if (element !== null) {
                console.info('---- handleScroll', element.getBoundingClientRect().bottom.toFixed(0), window.innerHeight)
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    isLoadingMore.value = true
                    loadMore()
                }
            }
        }

        onMounted(() => {
            window.addEventListener("scroll", handleScroll)

            rcdFr.value = 0
            
            getTotal()
            getList(false)
        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
        })


        return {
            alert, closeAlert, route, router, func, store, scrollComponent, 
            total, checkedTeam, arrItem, rcdFr, rcdDisplay, isLoading, isLoadingMore, 
            teamId, teamName, getMemberList, arrUser, checkedUser, arrTeamMbr, getTeamMbr, 
            filterName, filterAddMember, filterViewMember, dupTeamId, dupTeamName
        }
    }
}
</script>

<style>
.teamBox {
    border-radius: 10px;
    padding: 25px;
}

@media (max-width: 575.98px) {
  .teamBox {
    width: 98%;
    margin-bottom: 25px;
    border-radius: 10px;
    padding: 25px;
  }
}

.table-hover > tbody > tr .hoverShow {
    visibility: hidden;
}

.table-hover > tbody > tr:hover .hoverShow {
    visibility: visible;
}

.table-hover > tbody > tr .hoverHide {
    visibility: visible;
}

.table-hover > tbody > tr:hover .hoverHide {
    visibility: hidden;
}

.input-group-text {
    background-color: var(--bs-primary);
    color: var(--bgcolor-body-color);
}

.clearSearch {
  cursor: pointer; 
  color: #53BDEB;
  background-color: var(--bgcolor-body-color); 
  border-color: var(--accent-color)
}

.suggestTeamBox {
    background-color: var(--accent-color);
    padding: 20px 15px;
    border-radius: 10px;
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 25px;
}
</style>