<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="1" :disabledAll="route.params.projectId === undefined ? true :false" 
        :disabledSignee="disabledSignee" :disabledSignatory="disabledSignatory" :disabledReview="disabledReview" />

    <div class="mainBox mainBox55 border shadow-sm">

        <div v-if="route.params.projectId === undefined" class="row">
            <div class="col-12">
                <input v-model="newProjectName" type="text"  class="form-control" placeholder="Folder name">
            </div>
           
            <div class="col-12 mt-3">Create a folder with a single document or multiple documents, then invite signees (the participants) to sign on the respective document with their digital identity verified.</div>
        </div>
        <div v-else class="row">
            <div class="col-12 mb-2">
                <span v-if="project.mode === 'SIMPLE'" class="me-1">
                    <Popper class="popperDark" arrow hover content="SIMPLE">
                    <fa icon="square" size="lg" class="text-grey"/>
                    </Popper>
                </span>
                <span v-if="project.mode === 'KYC'" class="me-1">
                    <Popper class="popperDark" arrow hover content="KYC">
                    <fa icon="cube" size="lg" class="text-grey"/>
                    </Popper>
                </span>
                <span v-if="project.mode === 'STRICT'" class="me-1">
                    <Popper class="popperDark" arrow hover content="STRICT">
                    <fa icon="cubes" size="lg" class="text-grey"/>
                    </Popper>
                </span>

                <span v-if="project.signMode !== null" class="isTooltipsLink me-1">
                    <span v-if="project.signMode === 'PROJECT'" >
                        <Popper class="popperDark" arrow hover content="Full Access">
                            <fa icon="eye" size="lg" class="text-grey"/>
                        </Popper>
                    </span>
                    <span v-else>
                        <Popper class="popperDark" arrow hover content="Restrict">
                            <fa icon="eye-slash" size="lg" class="text-grey"/>
                        </Popper>
                    </span>
                </span>

                <span class="fw-bold me-1">{{ project.title }}</span> 
                <Popper class="popperDark" arrow hover content="Total Document">
                    <span class="badge bg-grey me-1 isTooltipsLabel">{{ project.total }}</span>
                </Popper>

                <span v-if="project.status === 'WIP'" class="badge bg-success">{{ project.status }}</span>
                <span v-if="project.status === 'DONE'" class="badge bg-purple">{{ project.status }}</span>
                <span v-if="project.status === 'CANCELED'" class="badge bg-orange">{{ project.status }}</span>

                <ProjPrivacy :value="project.privacy" class="ms-1" />
                
            </div>

            <div v-if="project.documents && project.documents.length > 0" class="col-12">
                <table class="table table-hover table-borderless border-sm">
                    <tbody>
                        <tr v-for="(doc, docIdx) in project.documents" :key="doc.id">
                            <td>
                                <div class="row">
                                    <div class="col-12 col-lg-7">
                                        <DocStatus :status="doc.status" class="" />
                                        <span :class="doc.status === 'REMOVED' ? 'text-grey' : ''">{{ doc.name.replace('.pdf', '') }}</span>
                                    </div>

                                    <div class="col-4 col-lg-1">
                                        {{ func.convSize(doc.size) }}
                                    </div>

                                    <div class="col-6 col-lg-3 text-end isTooltipsLabel small">
                                        <Popper class="popperDark" arrow hover :content="func.convDateTimeFormat(doc.dateCreate, 'datetime')">
                                            {{ func.getDateTimeDiff(func.convDateTimeFormat(doc.dateCreate), new Date()) }}
                                        </Popper>
                                    </div>

                                    <div v-if="doc.status !== 'REMOVED'" class="col-2 col-lg-1 text-end py-1">
                                        <div class="customButton">
                                            <div class="dropdown dropstart text-end d-lg-none">
                                                <button class="btn btn-link" data-bs-toggle="dropdown"><fa icon="ellipsis-h" /></button>
                                                <ul class="dropdown-menu">
                                                    <li><a class="dropdown-item" href="#" @click="delDoc(doc.documentId, doc.name, docIdx)">Remove</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="customLink text-end">
                                            <a href="#" @click="delDoc(doc.documentId, doc.name, docIdx)">
                                                <fa icon="trash" size="lg" />
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <hr v-if="project.documents && project.documents.length > 0" class="mb-4 mt-1" />
                <hr v-else class="my-4"/>
            </div>
        </div>

        <div class="row mb-2">
            <div class="col-lg-6 pb-2">
                <div v-if="totalUpload === 0" class="row">
                    <div class="col-12">Begin a new signing request by uploading pdf document. You can always review your work before you decide to send out the invitation to the signees.</div>
                </div>
                <div v-else v-for="(fi, idx) in dropzoneFileAll" :key="fi" class="row">
                    <div class="col-6 fw-bold" v-if="fi.file.name">{{fi.file.name.replace('.pdf', '')}}</div>
                    <div class="col-6 text-end" v-if="fi.file.name">{{ (fi.file.size/(1024*1024)).toFixed(2) }} MB</div>
                    <div class="col-9 mt-2 mb-3">
                        <div class="progress">
                            <div v-if="fi.response.status === 1" class="progress-bar progress-bar-striped" :style="{width: fi.progress + '%'}">{{fi.progress}} % </div>
                            <div v-else class="progress-bar bg-danger progress-bar-striped" :style="{width: fi.progress + '%'}">{{fi.progress}} % </div>
                        </div>
                    </div>
                    <div v-if="fi.response.status !== 0" class="col-3 mt-1 mb-3 text-end">
                        <fa v-if="fi.response.status === 1" icon="trash" @click="delDoc(fi.response.data.documentId, fi.file.name, idx)" class="text-secondary me-2 isLink" size="lg"/>
                        <fa v-if="fi.response.status === 1" icon="check-circle" class="text-primary" size="lg"/>
                        <span v-else>
                            <fa icon="exclamation-circle" data-bs-toggle="tooltip" title="Upload failed" class="text-danger" size="lg"/>
                        </span>
                        
                    </div>
                </div>
            </div>
            
            <div class="col-lg-6">
                <DropZone @drop.prevent="droppedFile" @change="selectedFile" />
            </div>
        </div>

        <!-- <div v-if="isUploaded" class="row"> -->
        <div v-if="(route.params.projectId === undefined && isUploaded) || (route.params.projectId && !isDocSent)" class="row">
            <div class="col-12"><hr class="my-3"/></div>
        </div>

        <div v-if="(route.params.projectId === undefined && isUploaded) || (route.params.projectId && !isDocSent)" class="row">
            <div class="col-12">
                <table width="100%" border="0">
                    <tr>
                        <td width="25px" class="align-top isLabel"><fa icon="square" size="lg"/></td>
                        <td width="70px" class="align-top"><b>SIMPLE</b></td>
                        <td width="25px" class="align-top"><input type="radio" class="form-check-input" v-model="projectMode" value="SIMPLE"></td>
                        <td class="pb-3">only require signees with valid email to sign.</td>
                    </tr>
                    <tr>
                        <td class="align-top isLabel"><fa icon="cube" size="lg"/></td>
                        <td class="align-top"><b>KYC</b></td>
                        <td class="align-top"><input type="radio" class="form-check-input" v-model="projectMode" value="KYC"></td>
                        <td class="pb-3">
                            <div>signees are required to be validated via KYC (Know Your Customer) with personal identification.</div>
                            <div class="btn-group" v-if="(project.status === 'WIP' || project.status === 'CANCELED') && projectMode === 'KYC'">
                                <button type="button" :class="classAll" @click="signMode = 'PROJECT'" data-bs-toggle="tooltip" title="Full Access - All signees can access to the document listed" >
                                    <fa icon="eye" />
                                </button>
                                <button type="button" :class="classRestrict" @click="signMode = 'DOCUMENT'" data-bs-toggle="tooltip" title="Restrict - Only selected signee can see the respective document">
                                    <fa icon="eye-slash" />
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="align-top isLabel"><fa icon="cubes" size="lg" /></td>
                        <td class="align-top"><b>STRICT</b></td>
                        <td class="align-top"><input type="radio" class="form-check-input" v-model="projectMode" value="STRICT" ></td>
                        <td>
                            <div>signees require KYC with additional short video footage as a proof of signing.</div>
                            <div class="btn-group" v-if=" (project.status === 'WIP' || project.status === 'CANCELED') && projectMode === 'STRICT'">
                                <button type="button" :class="classAll" @click="signMode = 'PROJECT'" data-bs-toggle="tooltip" title="Full Access - All signees can access to the document listed" >
                                    <fa icon="eye" /> 
                                </button>
                                <button type="button" :class="classRestrict" @click="signMode = 'DOCUMENT'" data-bs-toggle="tooltip" title="Restrict - Only selected signee can see the respective document">
                                    <fa icon="eye-slash" /> 
                                </button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>

        <div v-if="(route.params.projectId === undefined && isUploaded) || (route.params.projectId && !isDocSent)" class="row py-0">
            <div class="col-12"><hr class="mb-4"/></div>
        </div>

        <div v-if="(route.params.projectId === undefined && isUploaded) || (route.params.projectId && !isDocSent)" class="row text-center">
            <div class="col-12">
                <button class="btn btn-secondary" @click="updProject">
                    <fa icon="floppy-disk" size="lg" class="me-2" />Save
                </button>
            </div>
        </div>

    </div>        
</template>

<script>

import { ref, inject, computed, onMounted, watch } from 'vue'
import Alert from '@/components/Alert.vue'
import TopNavigation from '@/components/TopNavigation.vue'
import DropZone from '@/components/DropZone.vue'
import { useStore } from '@/stores/store'
import { useRoute, useRouter } from 'vue-router'
import { Tooltip } from 'bootstrap/dist/js/bootstrap.js'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import DocStatus from '@/components/DocStatus.vue'
import ProjPrivacy from '@/components/ProjPrivacy.vue'

export default {
    name: 'UploadDoc',
    components: {
        Alert, TopNavigation, DropZone, Tooltip, Popper, DocStatus, ProjPrivacy
    },
    setup() {
        const axios = inject('axios')
        const alert = ref([])

        const uploadPercentage = ref(0)
        const dropzoneFileAll = ref([])
        const num = ref(-1)
        const isUploaded = ref(false) // at least 1 file uploaded successfully
        const totalUpload = ref(0) // total files has been uploaded
        const isDocSent = ref(false) // true - as long as 1 of docs not DRAFT 
        
        const project = ref([])
        const projectId = ref('{projectId}')
        const newProjectName = ref(null)
        const projectMode = ref('SIMPLE')
        const projectName = ref(null)
        const totalProjectSignees = ref(0)

        const signMode = ref('PROJECT')
        const classAll = ref('btn btn-sm btn-secondary')
        const classRestrict = ref('btn btn-sm btn-outline-secondary')

        const disabledSignee = ref(true)
        const disabledSignatory = ref(true)
        const disabledReview = ref(true)

        // const p = ref({})

        const route = useRoute()
        const router = useRouter()
        const store = useStore()

        const func = funcs

        // sessionId.value = store.getSessionId.value

        /* if (store.getProjectId.value) {
            projectId.value = store.getProjectId.value
        } */

        // auth.value = JSON.parse(sessionStorage.getItem('auth'))
        // sessionStorage.setItem('yn000', auth.value.sessionId)

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const droppedFile = async (e) => {
            const files = ref([])
            files.value = e.dataTransfer.files

            for (var i = 0; i < files.value.length; i++) {
                num.value ++
                isUploaded.value = true
                totalUpload.value = dropzoneFileAll.value.length

                dropzoneFileAll.value[num.value] = {
                    file: files.value[i],
                    progress: 0,
                    response: { status: 0}
                }

                totalUpload.value = dropzoneFileAll.value.length

                // send axios
                // 'Authorization': 'Bearer '+ sessionId.value
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        // progress for all files instaed of individual file
                        uploadPercentage.value = 0
                        uploadPercentage.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        dropzoneFileAll.value[num.value].progress = uploadPercentage.value 
                    }
                }

                let formData = new FormData()
                formData.append('file', files.value[i])
                console.info('formData ' + i, files.value[i])

                // use await send one by one, return individual progress
                // not use await send parallel, return general progress for all files
                await axios.post( '/signon/upload/' + projectId.value, formData, config)
                .then((res) => {
                    if (res.data.status === 1) {
                        projectId.value = computed({
                            get: () => store.getProjectId.value,
                            set: store.updProjectId(res.data.data.projectId),
                        })

                        // must get again or rerturn value in object with double quote
                        projectId.value = res.data.data.projectId 

                        //here 
                        if (route.params.projectId === undefined && newProjectName.value === null && i === 0) {
                            newProjectName.value = files.value[i].name
                        }

                        getProject(projectId.value)
                        setTimeout(() => setPermission(), 1000)
                    } 
                    dropzoneFileAll.value[num.value].response = res.data
                })
                .catch((error) => {
                    console.log('FAILURE', error)
                    dropzoneFileAll.value[num.value].response = { status: error[1], message: error[0], error: error[2] }
                })
            } // for
        }

        const selectedFile = async (e) => {
            const files = ref({})
            files.value = document.querySelector('.dropzoneFile').files

            for (var i = 0; i < files.value.length; i++) 
            {
                num.value ++
                isUploaded.value = true

                dropzoneFileAll.value.push({
                    file: files.value[i],
                    progress: 0,
                    response: { status: 0}
                })

                totalUpload.value = dropzoneFileAll.value.length
                
                // 'Authorization': 'Bearer ' + sessionId.value
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    withCredentials: 'include',
                    onUploadProgress: (progressEvent) => {
                        // progress for all files instaed of individual file
                        uploadPercentage.value = 0
                        uploadPercentage.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        dropzoneFileAll.value[num.value].progress = uploadPercentage.value 
                    }
                }

                let formData = new FormData()
                formData.append('file', files.value[i])

                // use await send one by one, return individual progress
                // not use await send parallel, return general progress for all files
                // axios.defaults.headers.Cookie = 'yntest=1230000;'
                await axios.post( '/signon/upload/' + projectId.value, formData, config)
                .then((res) => {
                    if (res.data.status === 1) {
                        // console.info('axios --- success ', i, res.data.data)
                        // console.info('projectId', res.data.data.projectId)
                        projectId.value = computed({
                            get: () => store.getProjectId.value,
                            set: store.updProjectId(res.data.data.projectId),
                        })

                        // must get again or rerturn value in object with double quote
                        projectId.value = res.data.data.projectId 
                        // console.info('file', files.value[i])
                      
                        if (route.params.projectId === undefined && newProjectName.value === null && i === 0) {
                            newProjectName.value = files.value[i].name
                        }

                        getProject(projectId.value)
                        setTimeout(() => setPermission(), 1000)
                    }
                    dropzoneFileAll.value[num.value].response = res.data
                })
                .catch((error) => {
                    console.info('ERROR', error)
                    dropzoneFileAll.value[num.value].response = { status: error[1], message: error[0], error: error[2] }
                })
            } // for
        }

        watch([signMode], () => {
            if (signMode.value === 'PROJECT') {
                classAll.value = 'btn btn-sm btn-secondary'
                classRestrict.value = 'btn btn-sm btn-outline-secondary'
            } else {
                classAll.value = 'btn btn-sm btn-outline-secondary'
                classRestrict.value = 'btn btn-sm btn-secondary'
            }
        })

        const delDoc = (documentId, fileName, idx) => {
            axios.delete('/signon/' + projectId.value + '/' + documentId)
            .then((res) => {
                if (res.data.status === 1) {
                    dropzoneFileAll.value.splice(idx, 1)
                    totalUpload.value --

                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Document: " + fileName + " has been deleted successfully"
                    })
                    
                    setTimeout(() => getProject(projectId.value), 1000)
                }
            })
            .catch((error) => {

                console.log('delDoc | ERR', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            })
            
        }

        const updProject = () => {

            let proceed = true
            let p = {}

            if (route.params.projectId === undefined) {
                p = {
                   title: newProjectName.value, 
                   mode: projectMode.value,
                   signMode: signMode.value
                }

                if (newProjectName.value === null || newProjectName.value === '') {
                    proceed = false
                } 
            } else {
                p = {
                    mode: projectMode.value,
                    signMode: signMode.value
                }
            }

            if (proceed) {
                setTimeout(() => {
                    axios.put( '/signon/' + projectId.value, p
                    ).then((res) => {
                        // console.info('updProject | success', res.data)
                        if (res.data.status === 1) {
                            projectName.value = computed({
                                get: () => store.getProjectName.value,
                                set: store.updProjectName(newProjectName.value),
                            })
                            projectName.value = store.getProjectName.value 

                            setTimeout(() => {
                                router.push('/addSignee')
                            }, 1000)
                        } 
                    })
                    .catch((error) => {

                        console.log('updProject | ERR', JSON.stringify(error))
                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.convError(error)
                        })
                    })
                }, 500)
            } else {

                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: 'Please enter folder name.'
                })
            }
        }

        const getProject = async (id) => {
            await axios.get( '/signon/' + id)
            .then((res) => {
                // console.info('getProj | RES', JSON.stringify(res.data.data))
                if (res.data.status === 1) {
                    project.value = res.data.data
                    getProjectSignees(id)
                    chkIfDocSent()
                } 
            })
            .catch((error) => {
                console.log('getProj | FAILURE', JSON.stringify(error))
                // Cater for New sign doc - uploaded then delete immediately, can't find the project
                disabledSignee.value = true
                disabledSignatory.value = true
                disabledReview.value = true
            })
        }

        const getProjectSignees = async (id) => {
            await axios.get( '/signon/' + id + '/signee/list')
            .then((res) => {
                // console.info('getProjectSignees | RES', JSON.stringify(res.data.data))
                if (res.data.status === 1) {
                    totalProjectSignees.value = res.data.data.length 
                } 
            })
            .catch((error) => {
                console.log('getProjectSignees | FAILURE', JSON.stringify(error))
                // console.log('getProj | FAILURE', error.message)
            })
        }

        const chkIfDocSent = () =>  {

            if (project.value.documents.length > 0) {
                project.value.documents.forEach((item, idx) => {
                    if (item.status !== 'DRAFT') {
                        isDocSent.value = true
                    }
                })
            }
        }

        const setPermission = () => {
            // console.info('totalProjectSignees', totalProjectSignees.value)
            if (project.value.documents.length === 0) {
                disabledSignee.value = true
                disabledSignatory.value = true
                disabledReview.value = true

            } else {
                if (totalProjectSignees.value === 0) {
                    disabledSignee.value = false
                    disabledSignatory.value = true
                    disabledReview.value = true

                } else {
                    disabledSignee.value = false
                    disabledSignatory.value = false
                    disabledReview.value = false
                }
            }
        }


        onMounted( async () => {
            // initial tooltip
            Array.from(document.querySelectorAll('button[data-bs-toggle="tooltip"]'))
            .forEach(tooltipNode => new Tooltip(tooltipNode))

            Array.from(document.querySelectorAll('span[data-bs-toggle="tooltip"]'))
            .forEach(tooltipNode => new Tooltip(tooltipNode))

            func.clearPrevLocalStorage()

            // First time login
            if (route.query.fl === true || route.query.fl === 'true') {
                alert.value.push({
                    class: "success",
                    title: "SUCCESS",
                    message: "Welcome to SignOn."
                })
            }

            // Not first time login
            if (route.query.fl === false || route.query.fl === 'false') {
                if (route.query.l) { 
                    console.info('detect query l', route.query.l)
                    alert.value.push({
                        class: "success",
                        title: "SUCCESS",
                        message: "Welcome back. Last login " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.l), new Date()) + ", last active " + func.getDateTimeDiff(func.convDateTimeFormat(route.query.a), new Date()) + "."
                    })
                }
            }

            if (route.params.projectId === undefined || route.params.projectId === '{projectId}') {
                disabledSignee.value = true
                disabledSignatory.value = true
                disabledReview.value = true

            } else {
                // Project exists
                projectId.value = route.params.projectId
                getProject(projectId.value)

                // Set projectId 
                projectId.value = computed({
                    get: () => store.getProjectId.value,
                    set: store.updProjectId(projectId.value),
                })
                projectId.value = store.getProjectId.value

                // Set projectName
                setTimeout(() => {
                    computed({
                        get: () => store.getProjectName.value,
                        set: store.updProjectName(project.value.title),
                    })
                    setPermission()

                }, 1000)
            }
        })

        return {
            route, alert, closeAlert, store, func, 
            uploadPercentage, dropzoneFileAll, isUploaded, totalUpload, num, isDocSent, 
            signMode, classRestrict, classAll,
            project, projectId, newProjectName, projectMode, projectName, 
            droppedFile, selectedFile, delDoc, updProject,
            disabledSignee, disabledSignatory, disabledReview, getProjectSignees
        }
    }
}
</script>
// manually dropzone + axios

// localhost https certificate:
// note: localhost own cert not working - shown valid certificate but error: ERR_CERT_CMMON_NAME_INVALID
// solution 1/2 - create cert using mkcert, install choco via shellpower
// Note: mkcert not working in Firefox
// https://web.dev/how-to-use-local-https/
// https://www.groovypost.com/howto/edit-hosts-file-windows-10/
// solution 2/2 - add vue.config.js same level as package.json
// set port: 443, ssl key and cert

/* PENDING:
updProject - axios shd accept signeeMode
delDoc - error to delete in axios 
*/


<style>
/*  .table table-hover table-borderless table-sm .customButton {
  visibility: hidden;
}

 .table table-hover table-borderless table-sm:hover .tagClose{
  visibility: visible;
} */

.table-hover > tbody > tr .customLink {
    visibility: hidden;
}

.table-hover > tbody > tr:hover .customLink {
    visibility: visible;
}

.customLink > a {
    padding: 8px;
}

.customLink > a:hover {
    background-color: #DADADB;
    padding: 8px;
    /* padding-bottom: 7px;
    padding-right: 6px; */
    border-radius: 20px;
    color: #6E777E;
}

</style>