<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Add Member" icon="user-plus" />

    <div class="mainBox mainBox55 border shadow-sm">
        <div class="row">
            <div class="col-12 pb-3">
                <input v-model="email" class="form-control" placeholder="Email">
            </div>
            <div class="col-12 col-lg-6 pb-3">
                <input v-model="firstName" class="form-control" placeholder="First name">
            </div>

            <div class="col-12 col-lg-6 pb-3">
                <input v-model="lastName" class="form-control" placeholder="Last name">
            </div>

            <div class="col-12 col-lg-6 pb-3">
                <select v-model="title" class="form-select">
                    <option value="null">Select title</option>
                    <option :value="t.value" v-for="t in titleOpt" :key="t.value">{{ t.label }}</option>
                </select>
            </div>

            <div class="col-12 col-lg-6 pb-3">
                <input v-model="displayName" class="form-control" placeholder="Display name">
            </div>

            <div class="col-12 col-lg-6 pb-3">
                <select v-model="gender" class="form-select">
                    <option value="null">Select gender</option>
                    <option :value="g.value" v-for="g in genderOpt" :key="g.value">{{g.label}}</option>
                </select>
            </div>

            <div class="col-12 col-lg-6 pb-3">
                <select v-model="gmt" class="form-select">
                    <option value="null">Select GMT</option>
                    <option :value="g.value" v-for="g in gmtOpt" :key="g.value">{{g.label}}</option>
                </select>
            </div>

            <div class="col-12 col-lg-6 pb-3">
                <select class="form-select" v-model="teamId">
                    <option value="null">Select team</option>
                    <option :value="t.teamId" v-for="t in teamOpt" :key="t.teamId">{{ t.teamName }}</option>
                </select>
            </div>

            <div class="col-12 pt-3 text-center">
                <button v-if="route.params.frPage === 'member'" class="btn btn-secondary me-2" @click="$router.push({ path: '/team/member/list' })">
                    <fa icon="angle-left" size="lg" class="me-2" />Back
                </button>
                <button v-else class="btn btn-secondary me-2" @click="$router.push({ path: '/team/list' })">
                    <fa icon="angle-left" size="lg" class="me-2" />Back
                </button>

                <button class="btn btn-secondary">
                    <fa icon="floppy-disk" size="lg" class="me-2" />Save
                </button>
            </div>

        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import Avatar from '@/components/Avatar.vue'
import Tag from '@/components/ProjTag.vue'

// Image Uploader for avatar
import vueFilePond from "vue-filepond"
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css"
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type"
import FilePondPluginImagePreview from "filepond-plugin-image-preview"

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

export default {
    name: 'AddMember',
    components: { Alert, TopNavigation, Popper, Avatar, FilePond, Tag },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const showEditMode = ref(false)
        const user = ref({})
        const userTag = ref(null)
        const userTagRef = ref(null)
        const showDropdown = ref(false)

        const firstName = ref(null)
        const lastName = ref(null)
        const displayName = ref(null)
        const title = ref(null)
        const gender = ref(null)
        const gmt = ref(null)
        const plan = ref(null)
        const teamId = ref(null)
        const gmtOpt = ref([])
        const titleOpt = ref([])
        const teamOpt = ref([])
        
        const genderOpt = [
            { label: 'Female', value: 'F'},
            { label: 'Male', value: 'M'}
        ]

        const avatarConfig = ref({ 
            "url": "https://signon.my:3002/user/avatar/upload", 
            "process": { 
                "method": "POST", 
                "withCredentials": true, 
                "timeout": 20000 
            }
        }) 

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const uplAvatarError = (error) => {
            console.info('uplAvatarError', error)
        }

        const onprocessAvatar = (err, file) => {
            // console.info('processfile: err', err, 'file:' , file)
            if (err === null) {
                getProfile()
            }
        }

        const getGmtOpt = () => {
            let curDate = new Date() + ''
            let tmp = curDate.split('GMT')
            let tmp1 = tmp[1].split(' ')
            let tmpGmt = tmp1[0].replace(' ', '')
            gmt.value = tmpGmt.substr(0, 3) + ':' + tmpGmt.substr(3, 2)

            axios.get('/reference/gmt')
                .then((res) => {
                    if (res.data.status === 1) {
                        gmtOpt.value = res.data.data
                    }
                })
                .catch((error) => {
                    console.log('getGmt | ERR', JSON.stringify(error))
                })
        }

        const getTitleOpt = () => {
            // Title list
            axios.get('/reference/title')
            .then((res) => {
                if (res.data.status === 1) {
                    titleOpt.value = res.data.data
                }
            })
            .catch((error) => {

                console.log('delDoc | ERR', JSON.stringify(error))
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: func.convError(error)
                })
            })
        }

        const getTeamOpt = () => {
            teamOpt.value = [
                {
                    teamId: '1001',
                    teamName: 'Admin',
                    isDefault: true,
                    total: 1
                },
                {
                    teamId: '1002',
                    teamName: 'Developer',
                    isDefault: false,
                    total: 8
                },
                {
                    teamId: '1003',
                    teamName: 'Customer Service',
                    isDefault: false,
                    total: 2
                }

            ]
        }

        const delTag = (val, idx) => {
            updTag('del', val, idx)
        }

        const updTag = async (opt, val, idx=0) => {
            const tmpTag = user.value.tags

            if (opt === 'add') {
                tmpTag.push(val)

                // mp -  assume added successfully
                if (!user.value.tags.includes(val))
                {
                    user.value.tags.push(val)
                }
                    userTag.value = null 

            } else {
                const tmpTag = tags.value
                tmpTag.splice(idx, 1)
            }

            /* const  p = {
                tags: tmpTag
            }

            axios.put( '/signon/' + route.params.projectId, p
                ).then((res) => {
                    if (res.data.status === 1) {
                        userTag.value = null
                        // projTagOptRef.value.classList.remove('show') For tag dropdown menu purpose

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: opt === 'add' ? "Tag is added." : "Tag is removed."
                        })
                    } 
                })
                .catch((error) => {

                    console.log('updTag | FAILURE', JSON.stringify(error))
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: func.convError(error)
                    })
                }) */
        }

        const enterUserTag = () => {

            if (userTag.value.length > 0) {
                console.info('enterTags', userTag.value)

                if (user.value.tags.includes(userTag.value)) {
                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'Tag already exists.'
                    })
                } else {
                    updTag('add', userTag.value)
                }
            }

        }
        
        onMounted(() => {
            getGmtOpt()
            getTitleOpt()
            getTeamOpt()

            /* let objMdl1 = document.querySelector('#mdlAvatar')

            objMdl1.addEventListener("hide.bs.modal", function (event) {
                console.info('trigger modal close')
                avatarUrl.value = computed({
                    get: () => store.getAvatar.value,
                    set: store.updAvatar(avatar.value.url),
                })
                avatarUrl.value = store.getAvatar.value 
            }) */
        })

        return {
            alert, closeAlert, route, router, func, store, showEditMode, 
            firstName, lastName, displayName, title, gender, gmt, plan, teamId, gmtOpt, titleOpt, genderOpt, teamOpt,
            user, avatarConfig, uplAvatarError, onprocessAvatar, delTag, userTag, userTagRef, enterUserTag, showDropdown,
        }
    },
    data () {
        return {
            myAvatars: [],
        }
    },
    handleAvatarPondInit (){
        this.$refs.pondA.getFiles()
    },
}
</script>

<style>
.customLink {
    z-index: 10000; 
    padding-top: 70px; 
    opacity: 0.6;
    visibility: visible;
    background-color: white;
    width: 122px;
}
.editLinkBox {
    width: 50px;
    /* border:1px solid red; */
    text-align: center;
    padding: 5px;
}

.editLink {
    font-size: 18px;
    padding: 8px; 
}

.editLink:hover {
    background-color: #DADADB;
    padding: 8px 10px;
    border-radius: 20px;
}

.nextBoxContainer {
    width: 55%;
    max-width: 835px;
    margin: 25px auto 25px auto;
    border-radius: 10px;
}

.smallBox {
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 25px;
}

@media (max-width: 575.98px) {
  .nextBoxContainer {
    width: 98%;
  }
}
</style>