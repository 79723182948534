<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Configuration" icon="screwdriver-wrench" />

    <div class="mainBox mainBox55 border shadow-sm">
        <ul class="nav nav-pills">
            <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="pill" href="#general">General</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#maintenance">System Maintenance</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#smtp">SMTP</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-bs-toggle="pill" href="#package">Package</a>
            </li>
        </ul>


        <div class="tab-content mt-4">
            <div class="tab-pane container active" id="general">
                <div class="row">
                    
                </div>
            </div>

            <div class="tab-pane container fade" id="maintenance">
                <div class="row">
                    <div class="col-lg-2 mb-3">Maintenance</div>
                    <div class="col-lg-6 mb-3">
                        <div class="form-check form-switch mb-3">
                            <input class="form-check-input" type="checkbox" v-model="showMaintenance" value="true" checked>
                            <span v-if="showMaintenance" class="ms-1">Enable</span>
                            <span v-else class="ms-1">Disable</span>
                        </div>
                        <div>
                            <textarea class="form-control" rows="5" v-model="maintenanceMsg" placeholder="Maintenance Message" :readonly="!showMaintenance"></textarea>
                        </div>
                    </div>
                    <div class="col-lg-4 mb-3 text-grey small">
                        All users (except SU) will be force logout within 180 seconds upon  Maintenance Mode activation, with the Maintenance Message display to users
                    </div>
                    <div class="col-12 text-center mt-3">
                        <button class="btn btn-secondary" @click="updProfile">
                            <fa icon="rotate" size="lg" class="me-2" />Update
                        </button>
                    </div>
                </div>
            </div>

            <div class="tab-pane container fade" id="smtp">
                <div class="row">
                    <div class="col-lg-2 mb-1">Host</div>
                    <div class="col-lg-10 mb-3"><input type="text" v-model="host" class="form-control" /></div>

                    <div class="col-lg-2 mb-1">Port</div>
                    <div class="col-lg-10 mb-3"><input type="text" v-model="port" class="form-control" /></div>

                    <div class="col-lg-2 mb-1">Username</div>
                    <div class="col-lg-10 mb-3"><input type="text" v-model="username" class="form-control" /></div>

                    <div class="col-lg-2 mb-1">Password</div>
                    <div class="col-lg-10 mb-3"><input type="password" v-model="password" class="form-control" /></div>

                    <div class="col-lg-2 mb-1">SMTP Auth</div>
                    <div class="col-lg-10 mb-3">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" v-model="smtpAuth" value="true" checked> 
                            <span v-if="smtpAuth" class="ms-1">Enable</span>
                            <span v-else class="ms-1">Disable</span>
                        </div>
                    </div>

                    <div class="col-lg-2 mb-1">SMTP Secure</div>
                    <div class="col-lg-10 mb-3">
                        <select v-model="smtpSecure" class="form-select">
                            <option :value="s.value" v-for="s in smtpSecureOpt" :key="s.value">{{s.label}}</option>
                        </select>
                    </div>

                    <div class="col-12 text-center mt-3">
                        <button class="btn btn-secondary" @click="updProfile">
                            <fa icon="rotate" size="lg" class="me-2" />Update
                        </button>
                    </div>
                </div>
            </div>

            <div class="tab-pane container fade" id="package">
                <div class="row">
                    <div class="col-12 text-end mb-4">
                        <span data-bs-toggle="modal" data-bs-target="#mdlPackage" @click="getPackage(null)" class="me-2 text-primary isLink">
                            <fa icon="boxes-stacked" />
                            <span class="me-2 fw-bold" style="font-size: 14px">+</span>Add package
                        </span>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 d-grid" v-for="item in arrPackage" :key="item">
                        <div class="packageBox border shadow-sm">
                            <div class="fw-bold text-center mb-1">
                                <span class="isLink" @click="getPackage(item)" data-bs-toggle="modal" data-bs-target="#mdlPackage">{{item.name}}</span>
                            </div>

                            <div v-if="item.price > 0" class="text-center">
                                <span class="fw-bold text-uppercase">{{item.currency}}</span>
                                <span class="fs-1 ps-1">{{item.price}}</span>
                                <span> / {{item.period}}</span>
                            </div>
                            <div v-else class="text-center">
                                <span class="fs-1">FOC</span>
                            </div>

                            <div class="text-center"><hr /></div>

                            <div v-if="item.maxUser && (item.maxUser === 1)" class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">Single</span> user
                            </div>
                            <div v-else class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">{{item.maxUser}}</span> users
                            </div>

                            <div v-if="item.maxDoc === 0" class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">Unlimited</span> documents
                            </div>
                            <div v-else class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">{{item.maxDoc}}</span> documents per month
                            </div>

                            <div v-if="item.pricePerSignee !== null" class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/>
                                <span v-if="item.pricePerSignee === 0">    
                                    <span class="fw-bold">Unlimited</span> signees
                                </span>
                                <span v-if="item.pricePerSignee !== 0">    
                                    <span class="fw-bold"><span class="text-uppercase">{{item.currency}}</span>{{item.pricePerSignee}}</span> per signee
                                </span>
                            </div>

                            <div v-if="item.storage && item.storage.total > 0" class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/><span class="fw-bold">{{item.storage.total}}{{item.storage.unit}}</span> storage rack
                            </div>

                            <div v-if="item.invitation" class="mb-2">
                                <fa icon="check" size="lg" class="me-3"/>Send <span class="fw-bold">invitations</span>
                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- Modal --> 
    <div
        class="modal fade"
        id="mdlPackage"
        tabindex="-1"
        aria-labelledby="EditPackage"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 v-if="packageId === null" class="modal-title" id="exampleModalLabel"> Add Package</h5>
                    <h5 v-else class="modal-title" id="exampleModalLabel"> Edit Package</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">

                        <div class="col-12 mb-3">
                            <input type="text" v-model="packageName" class="form-control" placeholder="Package name" />
                        </div>

                        <div class="col-12 mb-3">
                            <table width="100%">
                                <tr>
                                    <td class="pe-2">
                                        <select v-model="packageCurrency" class="form-select">
                                            <option :value="cur.value" v-for="cur in arrCur" :key="cur.value">{{cur.label}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="text" v-model="packagePrice" class="form-control" placeholder="Package price" />
                                    </td>
                                    <td class="px-2">/</td>
                                    <td>
                                        <select v-model="packagePeriod" class="form-select">
                                            <option :value="p.value" v-for="p in arrPeriod" :key="p.value">{{p.label}}</option>
                                        </select>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div class="col-12 mb-5">
                            <input class="form-check-input me-3" type="checkbox" v-model="packagePublish" checked> Publish
                        </div>

                        <div class="col-12 mb-2 fw-bold text-grey">
                            Features:
                        </div>

                        <div class="col-12 mb-3">
                            <table width="100%">
                                <tr>
                                    <td width="130px" class="pe-2">
                                        <input type="text" v-model="packageMaxUser" class="form-control" placeholder="0 for unlimited" />
                                    </td>
                                    <td>Max users</td>
                                </tr>
                            </table>
                        </div>

                        <div class="col-12 mb-3">
                            <table width="100%">
                                <tr>
                                    <td width="130px" class="pe-2">
                                        <input type="text" v-model="packageMaxDoc" class="form-control" placeholder="0 for unlimited" />
                                    </td>
                                    <td>Max document / month</td>
                                </tr>
                            </table>
                        </div>

                        <div class="col-12 mb-3">
                            <table width="100%">
                                <tr>
                                    <td width="130px" class="pe-2">
                                        <input type="text" v-model="packageSigneePrice" class="form-control" placeholder="0 for free" />
                                    </td>
                                    <td>Price / signee</td>
                                </tr>
                            </table>
                        </div>

                        <div class="col-12 mb-3">
                            <table width="100%">
                                <tr>
                                    <td width="130px" class="pe-2">
                                        <input type="text" v-model="packageStorage" class="form-control" placeholder="0 for unlimited" />
                                    </td>
                                    <td width="80px" class="pe-2">
                                        <select v-model="packageStorageUnit" class="form-select">
                                            <option :value="u.value" v-for="u in arrUnit" :key="u.value">{{u.label}}</option>
                                        </select>
                                    </td>
                                    <td>Max Storage</td>
                                </tr>
                            </table>
                        </div>

                        <div class="col-12 mb-3">
                            <table width="100%">
                                <tr>
                                    <td width="30px">
                                        <input class="form-check-input" type="checkbox" v-model="packageInvitation" checked>
                                    </td>
                                    <td>Send invitation</td>
                                </tr>
                            </table>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button v-if="packageId === null && packageName !== ''" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="floppy-disk" class="me-2" />Save
                    </button>
                    <button v-if="packageId !== null && packageName !== ''" type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="rotate" class="me-2" />Update
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End -->

</template>

<script>
import { ref, inject, onMounted } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
import Avatar from '@/components/Avatar.vue'

export default {
    name: 'BOConfiguration',
    components: { Alert, TopNavigation, Popper, Avatar },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const showMaintenance = ref(false)
        const maintenanceMsg = ref(null)
        const smtpAuth = ref(true)
        const smtpSecure = ref('SSL')
        const smtpSecureOpt = [
            { label: 'SSL', value: 'SSL'},
            { label: 'TLS', value: 'TLS'}
        ]

        const arrPackage =ref([])
        const arrCur = ref(null)
        const arrPeriod = ref(null)
        const arrUnit = ref(null)

        const packageId = ref(null)
        const packageName = ref('')
        const packagePrice = ref(0)
        const packageCurrency = ref('myr')
        const packagePeriod = ref('year')
        const packagePublish = ref(false)
        const packageMaxUser = ref(null)
        const packageMaxDoc = ref(null)
        const packageSigneePrice = ref(null)
        const packageInvitation = ref(false)
        const packageStorage = ref(null)
        const packageStorageUnit = ref('mb')

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const getPackage = (data) => {

            if (data === null) {
                packageId.value = null
                packageName.value = ''
                packagePrice.value = 0
                packageCurrency.value = 'myr'
                packagePeriod.value = 'year'
                packagePublish.value = false

                packageMaxUser.value = null
                packageMaxDoc.value = null
                packageSigneePrice.value = null
                packageInvitation.value = false
                packageStorage.value = null
                packageStorageUnit.value = 'mb'

            } else {
                packageId.value = data.id
                packageName.value = data.name
                packagePrice.value = data.price
                packageCurrency.value = data.currency
                packagePeriod.value = data.period
                packagePublish.value = data.publish

                packageMaxUser.value = data.maxUser
                packageMaxDoc.value = data.maxDoc
                packageSigneePrice.value = data.pricePerSignee
                packageInvitation.value = data.invitation
                packageStorage.value = data.storage.total
                packageStorageUnit.value = data.storage.unit
            }
        }

        onMounted(() => {
            arrCur.value = [
                { label: 'MYR', value: 'myr'},
                { label: 'USD', value: 'usd'}
            ]

            arrPeriod.value = [ 
                { label: 'month', value: 'month'},
                { label: 'year', value: 'year'}
            ]

            arrUnit.value = [ 
                { label: 'MB', value: 'mb'},
                { label: 'GB', value: 'gb'}
            ]

            arrPackage.value = [
                {
                    id: '1001',
                    name: 'Free',
                    price: 0,
                    currency: 'myr',
                    period: 'year',
                    publish: true,
                    maxUser: 1,
                    maxDoc: 3,
                    invitation: false,
                    pricePerSignee: null,
                    storage: {
                        unit: 'MB',
                        total: 100
                    }
                },
                {
                    id: '1002',
                    name: 'Personal',
                    price: 200,
                    currency: 'myr',
                    period: 'year',
                    publish: true,
                    maxUser: 1,
                    maxDoc: 0,
                    invitation: false,
                    pricePerSignee: 1,
                    storage: {
                        unit: 'GB',
                        total: 1
                    }
                },
                {
                    id: '1003',
                    name: 'Team',
                    price: 360,
                    currency: 'myr',
                    period: 'year',
                    publish: false,
                    maxUser: 200,
                    maxDoc: 0,
                    invitation: true,
                    pricePerSignee: 0,
                    storage: {
                        unit: 'GB',
                        total: 0
                    }
                },
            ]
        })

        return {
            alert, closeAlert, route, router, func, store, 
            showMaintenance, maintenanceMsg, smtpAuth, smtpSecure, smtpSecureOpt,
            getPackage, packageId, packageName, packagePrice, packageCurrency, packagePeriod, packagePublish, 
            packageMaxUser, packageMaxDoc, packageSigneePrice, packageStorage, packageStorageUnit, packageInvitation, 
            arrPackage, arrCur, arrPeriod, arrUnit
        }
    }

}
</script>

<style>
.packageBox {
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 25px;
}

@media (max-width: 575.98px) {
  .packageBox {
    width: 98%;
    margin-bottom: 25px;
    border-radius: 10px;
    padding: 25px;
  }
}
</style>